<script>
// core components
import CardChangePassword from "components/Cards/User/CardChangePassword.svelte";
</script>

<div>
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardChangePassword />
    </div>
  </div>
</div>

<style windi:inject>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>