<script>
import axios from "axios";
import { authToken } from "../../store/auth";
import { onMount } from "svelte";
import { Confirm } from "svelte-confirm";
import { navigateTo } from "svelte-router-spa";
import { showSuccessMessage, showErrorMessage } from "../../utils/toast";
import CardProductTemplateCreate from "./CardProductTemplateCreate.svelte";
import { fade, scale } from "svelte/transition";
import UpdateProductTemplate from "../../views/merchant/UpdateProductTemplate.svelte";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

export let brand_id;
let product_template_id;
let profileInformation;
let recentlyAddedTemplates = [];

let validationError = null;

let allProductTemplate = [];
let showProductTemplate = false;
let isShowCreateModal = false;
let isShowProfileModal = false;
let isShowUpdateModal = false;
let isShowRecentTemplates = false;
let noProductTemplate = "loading product template ...";
let skip = 0;
const LIMIT = 9;
let total = 0;
let next = 0;
let templateLoading = true;

function getProductTemplateListApi(skip) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template?skip=${skip}&limit=${LIMIT}`, {
      headers: headers,
    })
    .then((res) => {
      total = res.data.paging.total;
      next = res.data.paging.next;
      allProductTemplate = [...allProductTemplate, ...res.data.data];
      showProductTemplate = true;
      if (skip === 0) {
        showSuccessMessage("Available Product Templates");
      }
      templateLoading = false;
    })
    .catch((err) => {
      console.error("ALL_Product_Template_LISTING_ERROR", err);
      showProductTemplate = false;
      noProductTemplate = "No Product Template Found";
      parseAndShowErrorMessage(err);
    });
}
const handleChange = (e) => {
  skip = next;
  templateLoading = true;
  if (next > 0) {
    getProductTemplateListApi(skip);
  }
};

onMount(() => {
  getProductTemplateListApi(skip);
});

function addRecentlyAddedTemplates(e) {
  isShowRecentTemplates = true;
  recentlyAddedTemplates = [...recentlyAddedTemplates, e.detail];
}

function deleteProductTemplateAPI(ProductTemplateIndex, brand_id, product_template_id, googleRecaptchaToken) {
  let headers = {
    "X-Auth-Token": $authToken,
  };

  axios
    .delete(`${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template/${product_template_id}`, {
      headers: headers,
      data: {
        captcha_token: googleRecaptchaToken,
      },
    })
    .then((res) => {
      allProductTemplate.splice(ProductTemplateIndex, 1);
      showProductTemplate = true;

      showSuccessMessage("Successfully deleted product template");
      getProductTemplateListApi(skip);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function deleteProductTemplate(ProductTemplateIndex, brand_id, product_template_id) {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "QuickList",
      })
      .then(function (token) {
        if (token) {
          deleteProductTemplateAPI(ProductTemplateIndex, brand_id, product_template_id, token);
        }
      });
  });
}

function visitAddProductTemplate() {
  isShowCreateModal = !isShowCreateModal;
}

function updateModal(id, information) {
  isShowUpdateModal = !isShowUpdateModal;
  product_template_id = id;
  profileInformation = information;
}

function deleteRecentlyProductTemplate(productIndex) {
  recentlyAddedTemplates.splice(productIndex, 1);
  recentlyAddedTemplates = [...recentlyAddedTemplates];
}

function postToCreateProductTemplateApi() {
  axios
    .post(`${morrDashboard.env.API_URL}/admin/brand/${brand_id}/product/template`, recentlyAddedTemplates, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully Saved product templates");
      isShowRecentTemplates = !isShowRecentTemplates;
      recentlyAddedTemplates = [];
      getProductTemplateListApi(skip);
      navigateTo(`/merchants/brands/product/template/list/${brand_id}`);
    })
    .catch((err) => {
      validationError = err.response ? err.response.data.detail : err;
      showErrorMessage(validationError);
      console.error("Product_Template_CREATE_ERROR", err);
    });
}
</script>

{#if isShowCreateModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardProductTemplateCreate on:data="{addRecentlyAddedTemplates}" brand_id="{brand_id}" bind:isShowCreateModal />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

{#if isShowUpdateModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <UpdateProductTemplate brand_id="{brand_id}" product_template_id="{product_template_id}" bind:isShowUpdateModal />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class=" flex-col min-w-0 break-words w-full mb-6 p-6 shadow-lg rounded-lg bg-white border-0">
  <div class="bg-white mb-4 py-6 px-4  border-b border-gray-200">
    <div class="header text-center flex justify-between">
      <h6 class="text-gray-600 text-xl font-bold">PRODUCT TEMPLATES</h6>
      <div>
        <button class="m-2 z-8 bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow-lg hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{visitAddProductTemplate}"> Add Product Template </button>
      </div>
    </div>
  </div>

  {#if isShowRecentTemplates}
    <div class="recentProducts">
      <div class="recent-header flex justify-between p-2">
        <h6 class="text-gray-800 text-xl font-bold">Recently Added products</h6>
        <div>
          <button class="z-10 bg-blue-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{() => postToCreateProductTemplateApi()}"> Save Templates </button>
          <button
            class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            on:click="{() => {
              isShowRecentTemplates = !isShowRecentTemplates;
            }}">
            Close
          </button>
        </div>
      </div>
      <div class="card-main">
        {#each recentlyAddedTemplates as row, index}
          <div in:scale out:fade>
            <div class="card  p-4">
              <div class="image">
                <img class="inset-0 w-full object-fit" src="{row.image_url}" alt="Product_Image" />
              </div>
              <div class="p-4">
                <h2 class="mt-2 mb-2  font-bold">
                  {row.name}
                </h2>
                <p class="text-sm">
                  {row.description}
                </p>
                <div class="mt-3 flex items-center">
                  <span class="text-sm font-semibold">RM</span>&nbsp;<span class="font-bold text-xl">{row.price}</span>
                </div>
              </div>
              <div class="p-4 flex items-center justify-evenly text-sm text-gray-600">
                <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                  <button on:click="{() => confirmThis(deleteRecentlyProductTemplate, index)}">
                    <i class="fas fa-trash text-red-500"></i>
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold">{row.name}</span>?
                  </span>
                  <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                </Confirm>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  {#if showProductTemplate}
    <div class="card-main">
      {#each allProductTemplate as row, index}
        <div class="card  p-4">
          <div class="image">
            <img class="inset-0 w-full object-fit" src="{row.image_url}" alt="Product_Image" />
          </div>
          <div class="p-4">
            <h2 class="mt-2 mb-2  font-bold">
              {row.name}
            </h2>
            <p class="text-sm">
              {row.description}
            </p>
            <div class="mt-3 flex items-center">
              <span class="text-sm font-semibold">RM</span>&nbsp;<span class="font-bold text-xl">{row.price}</span>
            </div>
          </div>
          <div class="p-4 flex items-center justify-evenly border-t border-b text-sm text-gray-600">
            <span class="ml-2">
              <button class="active:border-0" on:click="{() => updateModal(row.id, row)}"> Update </button>
            </span>
            <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
              <button on:click="{() => confirmThis(deleteProductTemplate, index, row.brand_id, row.id)}">
                <i class="fas fa-trash text-red-500"></i>
              </button>

              <span slot="title">
                Are you sure, you want to delete <span class="font-semibold">{row.name}</span>?
              </span>
              <span slot="description" class="text-sm"> You won't be able to revert this. </span>
            </Confirm>
          </div>
        </div>
      {/each}
    </div>
    {#if next > 0}
      <div class="flex flex-col justify-center items-center">
        <button class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150" on:click="{handleChange}">
          {#if templateLoading}Loading....
          {:else}Load More...
          {/if}</button>
      </div>
    {/if}
    {#if next === total || next == -1}
      <div class="flex flex-col justify-center items-center error block w-full overflow-x-auto m-4">
        <div>No More Product Templates....</div>
      </div>
    {/if}
  {:else}
    <div class="visible block w-full overflow-x-auto m-4">
      <div><h2>{noProductTemplate}</h2></div>
    </div>
  {/if}
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
.card {
  background-color: rgb(231, 221, 207);
  margin: 7px;
  border-radius: 20px;
}
.recentProducts {
  box-shadow: 1px 1px 1px 1px gray;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
.card-main {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  align-items: center;
  justify-content: center;
}
.image img {
  object-fit: contain;
}
.image {
  width: 100%;
}
@media (max-width: 1100px) {
  .card-main {
    display: grid;
    grid-template-columns: 42% 42%;
    margin: 7px auto;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 988px) {
  .card-main {
    display: grid;
    grid-template-columns: auto;
    margin: 7px;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .card-main {
    display: grid;
    grid-template-columns: 43% 43%;
    margin: 7px auto;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 515px) {
  .card-main {
    display: grid;
    grid-template-columns: auto;
    margin: 7px auto;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 830px) {
  .header {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 940px) {
  .recent-header {
    display: flex;
    flex-direction: column;
  }
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-blue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.active\:border-0:active {
  border-width: 0px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.visible {
  visibility: visible;
}
.break-words {
  overflow-wrap: break-word;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-8 {
  z-index: 8;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
