<script>
import axios from "axios";
import { authToken } from "../../../store/auth";
import { form, bindClass } from "svelte-forms";
import { showSuccessMessage, showErrorMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import Toggle from "svelte-toggle";
import Select from "svelte-select";
import { onMount } from "svelte";

export let merchant_data;
export let isStore;

let deliveryPartners = [];

let storeFormFields = {
  delivery_fee_per_km: 0,
  store_custom_tax_rate: 0,
  is_custom_tax_rate_enabled: false,
  is_delivering: false,
  is_operating: false,
  is_picking: false,
  is_dine_in: false,
  schedule: {
    start: "00:00",
    end: "00:00",
  },
  delivery_partner: "Bungkusit",
  is_third_party_delivery_partner: false,
};

let storeCreateForm = form(
  () => ({
    delivery_fee_per_km: {
      value: storeFormFields.delivery_fee_per_km,
      validators: ["required"],
    },
    store_custom_tax_rate: {
      value: storeFormFields.store_custom_tax_rate,
      validators: ["required"],
    },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function buildFormPayload() {
  return {
    delivery_fee_per_km: storeFormFields.delivery_fee_per_km,
    store_custom_tax_rate: storeFormFields.store_custom_tax_rate,
    is_custom_tax_rate_enabled: storeFormFields.is_custom_tax_rate_enabled,
    is_operating: storeFormFields.is_operating,
    is_delivering: storeFormFields.is_delivering,
    is_picking_up: storeFormFields.is_picking,
    is_third_party_delivery_partner: storeFormFields.is_third_party_delivery_partner,
    delivery_partner: storeFormFields.delivery_partner,
    is_dine_in: storeFormFields.is_dine_in,
    schedule: storeFormFields.schedule,
  };
}

function updateMerchantData() {
  const newMerchantData = {
    delivery_fee_per_km: storeFormFields.delivery_fee_per_km,
    store_custom_tax_rate: storeFormFields.store_custom_tax_rate,
    is_custom_tax_rate_enabled: storeFormFields.is_custom_tax_rate_enabled,
    is_delivering: storeFormFields.is_delivering,
    is_operating: storeFormFields.is_operating,
    is_picking_up: storeFormFields.is_picking,
    delivery_partner: storeFormFields.delivery_partner,
    is_third_party_delivery_partner: storeFormFields.is_third_party_delivery_partner,
    is_dine_in: storeFormFields.is_dine_in,
    schedule: storeFormFields.schedule,
  };

  merchant_data.store = newMerchantData;
}

function postToCreateStore(payload) {
  axios
    .post(`${morrDashboard.env.API_URL}/admin/merchants/${merchant_data.merchant_id}/store`, payload, {
      headers: {
        "x-auth-token": $authToken,
      },
    })
    .then((response) => {
      const successMessage = "Store Created";
      showSuccessMessage(successMessage);
      isStore = true;
      updateMerchantData();
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function getDeliveryPartnersApi() {
  axios
    .get(`${morrDashboard.env.API_URL}/admin/delivery-partners/`, {
      headers: {
        "x-auth-token": $authToken,
      },
    })
    .then((res) => {
      deliveryPartners = res.data.data;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function handleDeliveryPartners(e) {
  storeFormFields.delivery_partner = e.detail.label;
}

function handleDeliveryPartnersReset() {
  storeFormFields.delivery_partner = "Bungkusit";
}

function createStore() {
  const payload = buildFormPayload();
  postToCreateStore(payload);
}

onMount(() => {
  getDeliveryPartnersApi();
});
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0" style="background-color: rgb(245,241,234);">
  <div class="rounded-t bg-white mb-0 px-6 py-6" style="background-color:rgb(232,173,145)">
    <h6 class="text-center text-gray-800 text-xl font-bold uppercase">
      {merchant_data.name}'s Store
    </h6>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <div class="flex flex-col w-full">
        <div class="w-full px-3 md:w-full sm:w-full lg:w-full">
          <h6 class="text-gray-500 text-sm mt-3 mb-4 font-bold uppercase">Delivery</h6>
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold m-2" for="store-delivery-fee"> Delivery fee per km </label>
              <input type="number" id="store-delivery-fee" class="px-3 py-3 placeholder-gray-800 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Delivery fee per km" min="0" step="0.2" bind:value="{storeFormFields.delivery_fee_per_km}" use:bindClass="{{ form: storeCreateForm, name: 'delivery_fee_per_km' }}" />
            </div>

            <div class="relative w-full mb-3 flex">
              <label class="block uppercase text-gray-700 text-xs font-bold m-2 mr-3" for="third-party-delivery">Third Party Delivery </label>
              <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_third_party_delivery_partner}" style="border: 2px solid #222; height:1.3rem;" />
            </div>
            {#if storeFormFields.is_third_party_delivery_partner}
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold m-2" for="delivery-partner"> Delivery Partner </label>
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); vertical-align:middle;margin:10px" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{deliveryPartners}" isMulti="{false}" placeholder="Select Delivery Partner" noOptionsMessage="No Delivery Partners Available" on:select="{handleDeliveryPartners}" on:clear="{handleDeliveryPartnersReset}" />
              </div>
            {/if}
          </div>

          <h6 class="text-gray-500 text-sm mt-3 mb-4 font-bold uppercase">Taxation</h6>
          <div class="w-full px-4">
            <div class="relative w-full mb-2 mt-2 flex">
              <label class="block uppercase text-gray-700 text-xs font-bold m-2 mr-3" for="store-is-custom-tax-enabled">Enable Custom Tax(%) </label>

              <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_custom_tax_rate_enabled}" style="border: 2px solid #222; height:1.3rem;" />
            </div>
            {#if storeFormFields.is_custom_tax_rate_enabled}
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold m-2" for="store-custom-tax-rate"> Store Custom Tax (%) </label>
                <input type="number" id="store-custom-tax-rate" class="px-3 py-3 placeholder-gray-800 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Store custom tax rate" min="0" step="0.2" bind:value="{storeFormFields.store_custom_tax_rate}" use:bindClass="{{ form: storeCreateForm, name: 'store_custom_tax_rate' }}" />
              </div>
            {/if}
          </div>

          <div class="w-full mb-5">
            <div class="relative w-full mb-3">
              <h6 class="text-gray-500 text-sm mt-3 mb-4 font-bold uppercase">Schedule</h6>
            </div>

            <div class="flex flex-col item-center justify-center lg:flex-row">
              <div class="w-full lg: w-full px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-schedule-start">Start </label>

                  <input type="time" id="store-schedule-start" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Start time" bind:value="{storeFormFields.schedule.start}" />
                </div>
              </div>

              <div class="w-full lg: w-full px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-schedule-end">End </label>

                  <input type="time" id="store-schedule-end" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="End time" bind:value="{storeFormFields.schedule.end}" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-3 md:w-full sm:w-full lg:w-6/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Store Status</h6>
          <div class="w-full">
            <div class="w-full  px-4 ">
              <div class="relative w-full m-3 flex ">
                <label class="block uppercase text-gray-700 text-lg font-bold m-2 mr-3" for="store-is-delivering"> 1. Delivering </label>

                <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_delivering}" style="border: 2px solid #222; height:1.4rem;" />
              </div>
            </div>

            <div class="w-full px-4">
              <div class="relative w-full m-3 flex ">
                <label class="block uppercase text-gray-700 text-lg font-bold m-2 mr-3" for="store-is-operating">2. Operating </label>
                <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_operating}" style="border: 2px solid #222; height:1.4rem;" />
              </div>
            </div>

            <div class="w-full px-4">
              <div class="relative w-full m-3 flex">
                <label class="block uppercase text-gray-700 text-lg font-bold m-2 mr-11" for="store-is-picking">3. Picking </label>

                <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_picking}" style="border: 2px solid #222; height:1.4rem;" />
              </div>
            </div>
            <div class="w-full px-4">
              <div class="relative w-full m-3 flex">
                <label class="block uppercase text-gray-700 text-lg font-bold m-2 mr-11" for="store-is-picking">4. Dine In </label>

                <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{storeFormFields.is_dine_in}" style="border: 2px solid #222; height:1.4rem;" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />
      <div class="flex flex-wrap items-center">
        <div class="sm:w-6/12 md:w-1/3 lg:2/12 px-4 mt-4 mx-auto">
          <button class="text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded-full shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" style="background-color: rgb(232,173,144);" type="submit" disabled="{!$storeCreateForm.valid}" on:click|preventDefault="{() => createStore()}"> Create Store </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
[min~="0"] {
  min-0: 0px;
}
@media (min-width: 640px) {
  .sm\:w-full {
    width: 100%;
  }
  .sm\:w-6\/12 {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .md\:w-full {
    width: 100%;
  }
  .md\:w-1\/3 {
    width: 33.333333%;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:\ py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
}
</style>