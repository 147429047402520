<script>
import { link } from "svelte-routing";
// library for creating dropdown menu appear on click
import { createPopper } from "@popperjs/core";

// core components

let dropdownPopoverShow = false;

let btnDropdownRef;
let popoverDropdownRef;

const toggleDropdown = (event) => {
  event.preventDefault();
  if (dropdownPopoverShow) {
    dropdownPopoverShow = false;
  } else {
    dropdownPopoverShow = true;
    createPopper(btnDropdownRef, popoverDropdownRef, {
      placement: "bottom-start",
    });
  }
};
</script>

<div>
  <a class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/#morr-home" bind:this="{btnDropdownRef}" on:click="{toggleDropdown}"> Demo Pages </a>
  <div bind:this="{popoverDropdownRef}" class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}">
    <span class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"> Admin Layout </span>
    <a use:link href="/admin/dashboard" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Dashboard </a>
    <a use:link href="/admin/settings" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Settings </a>
    <a use:link href="/admin/tables" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Tables </a>
    <a use:link href="/admin/maps" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Maps </a>
    <div class="h-0 mx-4 my-2 border border-solid border-gray-200"></div>
    <span class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"> Auth Layout </span>
    <a use:link href="/auth/login" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Login </a>
    <a use:link href="/auth/register" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Register </a>
    <div class="h-0 mx-4 my-2 border border-solid border-gray-200"></div>
    <span class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"> No Layout </span>
    <a use:link href="/landing" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Lading </a>
    <a use:link href="/profile" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"> Profile </a>
  </div>
</div>

<style windi:inject>
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border {
  border-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.float-left {
  float: left;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.h-0 {
  height: 0px;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.list-none {
  list-style-type: none;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.min-w-48 {
  min-width: 12rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pb-0 {
  padding-bottom: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
@media (min-width: 1024px) {
  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
}
</style>