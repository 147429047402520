<script>
import PaginationRowCount from "../PaginationRowCount.svelte";
import PaginationButtons from "../PaginationButtons.svelte";
import { options } from "../stores/options.js";
</script>

{#if $options.pagination && ($options.blocks.paginationRowCount || $options.blocks.paginationButtons)}
  <section class="dt-pagination" class:css="{$options.css}">
    {#if $options.blocks.paginationRowCount}
      <PaginationRowCount />
    {:else}
      <div></div>
    {/if}
    {#if $options.blocks.paginationButtons}
      <PaginationButtons />
    {/if}
  </section>
{/if}

<style windi:inject>
section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-top: 8px;
}
.css {
  background: #fff;
}
</style>
