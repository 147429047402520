<script>
export let size = "100%";
export let strokeWidth = 2;
let customClass = "";
export { customClass as class };

if (size !== "100%") {
  size = size.slice(-1) === "x" ? size.slice(0, size.length - 1) + "em" : parseInt(size) + "px";
}
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="{size}" height="{size}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="{strokeWidth}" stroke-linecap="round" stroke-linejoin="round" class="feather feather-watch {customClass}"><circle cx="12" cy="12" r="7"></circle><polyline points="12 9 12 12 13.5 13.5"></polyline><path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"></path></svg>

<style windi:inject>
[fill~="none"] {
  fill: none;
}
</style>