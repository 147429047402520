<script>
// library for creating dropdown menu appear on click
import { createPopper } from "@popperjs/core";
import { Navigate, routeIsActive } from "svelte-router-spa";
import { onMount } from "svelte";
import axios from "axios";
import { authToken } from "../../store/auth";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
// core components

const image = "/assets/img/user-profile.png";

let dropdownPopoverShow = false;

let btnDropdownRef;
let popoverDropdownRef;
export let role;

let headers = {
  "X-Auth-Token": $authToken,
};

const toggleDropdown = (event) => {
  event.preventDefault();
  if (dropdownPopoverShow) {
    dropdownPopoverShow = false;
  } else {
    dropdownPopoverShow = true;
    createPopper(btnDropdownRef, popoverDropdownRef, {
      placement: "bottom-start",
    });
  }
};

function getUserDetails() {
  axios
    .get(`${morrDashboard.env.API_URL}/users/`, {
      headers: headers,
    })
    .then((res) => {
      let userDetails = res.data.data;
      if (userDetails.is_admin) {
        role = "Admin";
      } else if (userDetails.is_manager) {
        role = "Manager";
      } else {
        role = "User";
      }
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  getUserDetails();
});
</script>

<div>
  <a class="text-gray-600 block" href="/#morr-home" bind:this="{btnDropdownRef}" on:click="{toggleDropdown}">
    <div class="items-center flex">
      <span class="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
        <img alt="..." class="w-full p-3 rounded-full align-middle border-none shadow-lg" src="{image}" />
      </span>
      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-600 bg-blueGray-200 uppercase last:mr-0 mr-1">
        {role}
      </span>
    </div>
  </a>
  <div bind:this="{popoverDropdownRef}" class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}">
    <a href="/accounts/profile" on:click="{toggleDropdown}" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800" style="z-index: 2;">
      <i class="fas fa-user mr-2 text-sm" class:active="{routeIsActive('/accounts/profile')}"></i>
      Profile
    </a>
    <a href="/accounts/change-password" on:click="{toggleDropdown}" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800" style="z-index: 2;">
      <i class="fas fa-lock mr-2 text-sm" class:active="{routeIsActive('/accounts/change-password')}"></i>
      Change Password
    </a>
    <a href="/accounts/logout" on:click="{toggleDropdown}" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
      <i class="fas fa-power-off mr-2 text-sm" class:active="{routeIsActive('/accounts/logout')}"></i>
      Logout
    </a>
  </div>
</div>

<style windi:inject>
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.border-none {
  border-style: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.hidden {
  display: none;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.float-left {
  float: left;
}
.font-semibold {
  font-weight: 600;
}
.font-normal {
  font-weight: 400;
}
.h-12 {
  height: 3rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.list-none {
  list-style-type: none;
}
.last\:mr-0:last-child {
  margin-right: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.min-w-48 {
  min-width: 12rem;
}
.p-3 {
  padding: 0.75rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.w-12 {
  width: 3rem;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
</style>