<script>
import ProductVariationsCrud from "./ProductVariationsCrud.svelte";
import { Confirm } from "svelte-confirm";
export let variationInfoList = [];
export let deletedVariationIds = [];
function addMoreVariation() {
  let emptyVariation = {
    title: "",
    price: "",
    variation_by: "",
    quantity: "",
    is_available: true,
    is_default: false,
  };
  variationInfoList = [...variationInfoList, emptyVariation];
}

function removeVariation(variation, event) {
  event.preventDefault();
  if (variation.variation_id) {
    deletedVariationIds.push(variation.variation_id);
  }
  variationInfoList.splice(variationInfoList.indexOf(variation), 1);
  variationInfoList = [...variationInfoList];
}
</script>

<div class="flex">
  <h6 class="text-gray-500 text-sm mt-3 mx-3 mb-3 font-bold uppercase">Variations (Atleast one)</h6>
  <button on:click|preventDefault="{addMoreVariation}">
    <i class="fa fa-plus"></i>
  </button>
</div>
{#if variationInfoList}
  {#each variationInfoList as variation, index}
    <div class="flex">
      <ProductVariationsCrud bind:variationInfo="{variation}" />
      <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
        <button
          class="flex-shrink align-text-bottom"
          on:click="{(event) => {
            event.preventDefault();
            confirmThis(removeVariation, variation, event);
          }}">
          <i class="fas fa-trash text-red-500"></i>
        </button>

        <span slot="title">
          Are you sure, you want to delete <span class="font-semibold">{variation.title}</span>?
        </span>
      </Confirm>
    </div>
  {/each}
{/if}

<style windi:inject>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
</style>