<script>
import { onMount } from "svelte";

export let invoiceDetails;
export let orderDetail;
let totalPrice = 0;
let tax = 0;
let status = 0;

function totalProductPrice(invoiceDetails) {
  let productCount = invoiceDetails.products.length;
  for (let i = 0; i < productCount; i++) {
    totalPrice += invoiceDetails.products[i].total_price;
  }
}

function taxAmount() {
  let taxP = orderDetail.tax;
  tax = (taxP * totalPrice) / 100;
  return tax;
}

console.log("details invoice", invoiceDetails, orderDetail);
totalProductPrice(invoiceDetails);
status = orderDetail.status;
</script>

{#if invoiceDetails}
  <div class="w-full xl:ml-10 mr-auto px-2">
    <div class="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
      <div class="md:flex">
        <div class="w-full p-3">
          <div class="border rounded-lg border-solid py-5 border-1 border-slate-200">
            <div class="">
              <h1 class="text-gray-700 font-bold text-center uppercase">Invoice</h1>
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200"></span>
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">Price</div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-semibold">
                    {totalPrice}
                  </span>
                </div>
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">
                  Tax: ({invoiceDetails.order_details.tax_percent || 0}%)
                </div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-semibold">
                    {invoiceDetails.order_details.tax_value || 0}
                  </span>
                </div>
              </div>
              {#if status === 1}
                <div class="flex justify-around px-4">
                  <div class="title text-left w-6/12 font-normal">Delivery fee</div>
                  <div class="value text-right  w-6/12">
                    <span class="mt-2 text-slate-400 font-bolder">RM</span>
                    <span class="text-gray-500 text-lg font-semibold">
                      {orderDetail.delivery_charge ? orderDetail.delivery_charge : 0}
                    </span>
                  </div>
                </div>
              {/if}
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200"></span>
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-normal">Total</div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-gray-500 text-lg font-bold">
                    {orderDetail ? orderDetail.total_price : 0}
                  </span>
                </div>
              </div>
              <div class="flex w-full mt-3 mb-3">
                <span class="border-1 border-solid w-full border-slate-200"></span>
              </div>
              <div class="flex justify-around px-4">
                <div class="title text-left w-6/12 font-semibold uppercase">Amount Paid</div>
                <div class="value text-right  w-6/12">
                  <span class="mt-2 text-slate-400 font-bolder">RM</span>
                  <span class="text-slate-500 text-3xl font-bold">
                    {orderDetail.amount_paid ? orderDetail.amount_paid : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style windi:inject>
.rounded-lg {
  border-radius: 0.5rem;
}
.border-solid {
  border-style: solid;
}
.border {
  border-width: 1px;
}
.border-1 {
  border-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.max-w-md {
  max-width: 28rem;
}
.overflow-hidden {
  overflow: hidden;
}
.p-3 {
  padding: 0.75rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
@media (min-width: 768px) {
  .md\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .md\:max-w-xl {
    max-width: 36rem;
  }
}
@media (min-width: 1280px) {
  .xl\:ml-10 {
    margin-left: 2.5rem;
  }
}
</style>