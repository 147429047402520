<script>
import { Route } from "svelte-router-spa";

// components for this layout
import MerchantNavbar from "components/Navbars/MerchantNavbar.svelte";
import MerchantSidebar from "components/Sidebar/MerchantSidebar.svelte";
import FooterAdmin from "components/Footers/FooterAdmin.svelte";

export let currentRoute;
const params = {};
</script>

<div>
  <MerchantSidebar location="{currentRoute}" />
  <div class="relative md:ml-64" style="background-color:#f5f1ea">
    <MerchantNavbar />
    <div class="px-4 mt-0 pt-24 md:px-10 mx-auto w-full -m-24">
      <Route currentRoute="{currentRoute}" params="{params}" />
      <FooterAdmin />
    </div>
  </div>
</div>

<style windi:inject>
.-m-24 {
  margin: -6rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-0 {
  margin-top: 0px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-24 {
  padding-top: 6rem;
}
.relative {
  position: relative;
}
.w-full {
  width: 100%;
}
@media (min-width: 768px) {
  .md\:ml-64 {
    margin-left: 16rem;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
</style>