<script>
import { authToken } from "../../../store/auth";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import Select from "svelte-select";
import { onMount } from "svelte";
import { createEventDispatcher } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import Tags from "svelte-tags-input";
import { defaultMerchantLabels, merchantCategories } from "../../../constants/merchants";

let dispatch = createEventDispatcher();

export let showComponent;
export let showPrevComponent;
export let merchantInfo;

let validationError;
let successMessage;
let brandItems = [];
let brandName;
let brand_id;
let selectedBrand;
let exported_merchant_id;

let selectedMerchantCategory;

let numberOfSchedules = merchantInfo?.schedule ? merchantInfo.schedule.length : 1;

let merchantFormFields = {
  merchant: {
    name: merchantInfo.name ? merchantInfo.name : "",
    schedules: merchantInfo.schedule
      ? merchantInfo.schedule
      : [
          {
            start: "00:00",
            end: "00:00",
          },
        ],
    deliveryRange: merchantInfo.delivery_range ? merchantInfo.delivery_range : 0,

    category: merchantInfo.category,
    labels: merchantInfo.labels || [],
    googleReviewsUrl: merchantInfo.google_reviews_url ? merchantInfo.google_reviews_url : "",
  },
};

function addMoreSchedule() {
  merchantFormFields.merchant.schedules.push({
    start: "00:00",
    end: "00:00",
  });
  numberOfSchedules = merchantFormFields.merchant.schedules.length;
}

function removeSchedule(event, index) {
  index = parseInt(event.srcElement.dataset.index);
  event.preventDefault();
  merchantFormFields.merchant.schedules.splice(index, 1);
  setTimeout(function () {
    numberOfSchedules = merchantFormFields.merchant.schedules.length;
  }, 500);
}

function showBrandListApi() {
  axios
    .get(`${morrDashboard.env.API_URL}/admin/merchants/brand/list`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((res) => {
      let brandList = res.data.data;
      let brandItemsList = [];
      brandList.forEach((brand) => {
        brandItemsList.push({
          label: brand.name,
          value: brand.id,
        });
      });
      brandItems = brandItemsList;
    })
    .catch((err) => {
      validationError = err.response ? err.response.data.detail : err;
      showErrorMessage(validationError);
    });
}

function getSelectedMerchantCategory() {
  let filteredCategories = merchantCategories.filter((category) => {
    if (category.value === merchantInfo.category) {
      return category;
    }
  });
  console.log("Updating selected category", filteredCategories);
  if (filteredCategories.length > 0) {
    selectedMerchantCategory = filteredCategories[0];
  }

  return;
}

function handleMerchantCategorySelect(event) {
  merchantFormFields.merchant.category = event.detail || [];
}

onMount(() => {
  showBrandListApi();
  if (merchantInfo?.brand_id) {
    selectedBrand = {
      value: merchantInfo?.brand_id,
      label: merchantInfo?.brand?.name,
    };
  }

  getSelectedMerchantCategory();
});

function performCreateMerchant() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "MerchantCreate",
      })
      .then(function (token) {
        if (token) {
          postToCreateMerchantApi(token);
        }
      });
  });
}

function postToCreateMerchantApi(token) {
  if (merchantFormFields.merchant.name && brandName) {
    let payload = {
      brand: {
        id: brand_id,
        name: brandName,
      },
      merchant: {
        name: merchantFormFields.merchant.name,
        schedule: merchantFormFields.merchant.schedules,
        delivery_range: merchantFormFields.merchant.deliveryRange,
        category: merchantFormFields.merchant.category.value,
        labels: merchantFormFields.merchant.labels,
        google_reviews_url: merchantFormFields.merchant.googleReviewsUrl,
      },
    };
    axios
      .post(`${morrDashboard.env.API_URL}/admin/merchants/onboarding`, payload, {
        headers: {
          "X-Auth-Token": $authToken,
          "x-google-token": token,
        },
      })
      .then((response) => {
        exported_merchant_id = response.data.data.merchant.merchant_id;
        showSuccessMessage("Merchant Information Saved.");
        dispatch("GetMerchantID", exported_merchant_id);
        showComponent = "Address";
      })
      .catch((err) => {
        parseAndShowErrorMessage(err);
      });
  } else {
    showErrorMessage("Brand details are required");
  }
}

function postToUpdateMerchantInfoApi(payload) {
  axios
    .put(`${morrDashboard.env.API_URL}/admin/merchants/update/${merchantInfo.merchant_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      successMessage = "Successfully updated.";
      showSuccessMessage(successMessage);
      showPrevComponent = "";
      showComponent = "Address";
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      console.error("MERCHANT_UPDATE_ERROR", err);
    });
}

function updateMerchant() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "MerchantUpdate",
      })
      .then(function (token) {
        if (token) {
          let payload = {
            updated_merchant: {
              name: merchantFormFields.merchant.name,
              schedule: merchantFormFields.merchant.schedules,
              brand_id: brand_id,
              delivery_range: merchantFormFields.merchant.deliveryRange,
              category: merchantFormFields.merchant.category.value,
              labels: merchantFormFields.merchant.labels,
              google_reviews_url: merchantFormFields.merchant.googleReviewsUrl,
            },
            captcha: {
              captcha_token: token,
            },
          };
          postToUpdateMerchantInfoApi(payload);
        }
      });
  });
}

function handleBrandSelect(event) {
  selectedBrand = event.detail;
  brand_id = selectedBrand.value;
  brandName = selectedBrand.label;
}

function handleBrandReset(event) {
  brand_id = null;
  brandName = "";
}

function handleSave() {
  performCreateMerchant();
}

function handleUpdate() {
  updateMerchant();
}
</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Merchant Information</h6>

  <div class="flex flex-wrap">
    <div class="w-full lg:w-8/12 px-4">
      <div class="relative w-full mb-3">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-name"> Name </label>
        <input id="merchant-name" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Merchant Name" bind:value="{merchantFormFields.merchant.name}" />
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div class="relative w-full mb-3">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="delivery-range"> Delivery Range (Kms) </label>
        <input id="delivery-range" type="number" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Delivery Range" bind:value="{merchantFormFields.merchant.deliveryRange}" />
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div class="relative w-full mb-3">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="brand"> Brand </label>
        {#if brandItems.length}
          <Select containerStyles="min-height: 3rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle;" inputAttributes="{{ class: 'h-14 text-sm' }}" inputStyles="font-size: 1.125rem;" items="{brandItems}" value="{selectedBrand}" isMulti="{false}" placeholder="Please select one Brand outlet" noOptionsMessage="No brands available" on:select="{handleBrandSelect}" on:clear="{handleBrandReset}" />
        {:else}
          <div class="">
            <div class="relative w-full mb-3">
              <input id="BrandName" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Enter Brand Name" bind:value="{brandName}" />
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative w-full mb-3">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="category"> Category </label>
        <Select containerStyles="min-height: 3rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle" inputAttributes="{{ class: 'h-14 text-sm' }}" inputStyles="font-size: 1.125rem;" items="{merchantCategories}" bind:value="{selectedMerchantCategory}" isMulti="{false}" placeholder="Please select a category" on:select="{handleMerchantCategorySelect}" />
      </div>
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative w-full mb-3">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="labels"> Labels </label>
        <Tags
          tags="{merchantFormFields.merchant.labels}"
          on:tags="{(event) => {
            console.log('Adding tag', event);
            merchantFormFields.merchant.labels = event.detail.tags;
          }}"
          addKeys="{[9]}"
          maxTags="{10}"
          allowPaste="{true}"
          allowDrop="{true}"
          splitWith="{'/'}"
          onlyUnique="{true}"
          removeKeys="{[27]}"
          placeholder="{'Start typing for label suggestions'}"
          autoComplete="{defaultMerchantLabels}"
          name="{'labels'}"
          id="{'merchant-labels'}"
          allowBlur="{true}"
          disable="{false}"
          minChars="{1}"
          labelText=""
          labelShow="{false}" />
      </div>
    </div>
  </div>
  <div class="w-full px-4">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="google-reviews-url"> Google Reviews </label>
      <input id="google-reviews-url" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter google reviews url" bind:value="{merchantFormFields.merchant.googleReviewsUrl}" />
    </div>
  </div>

  <hr class="mt-6 border-b-1 border-gray-400" />

  <div class="rounded-t mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Schedules</h6>
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click|preventDefault="{addMoreSchedule}"> Add Schedule </button>
    </div>
  </div>

  {#each Array(numberOfSchedules) as _, index}
    <div class="flex flex-wrap">
      <div class="flex-grow px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-schedule-start`}"> Start </label>
          <input id="{`${index}-schedule-start`}" type="time" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Start time" bind:value="{merchantFormFields.merchant.schedules[index].start}" />
        </div>
      </div>
      <div class="flex-grow px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-schedule-end`}"> End </label>
          <input id="{`${index}-schedule-end`}" type="time" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="End of schedule" bind:value="{merchantFormFields.merchant.schedules[index].end}" />
        </div>
      </div>

      <div class="w-8">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-delete`}"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
        <button class="flex-shrink align-text-bottom">
          <i class="fas fa-trash text-red-500" data-index="{index}" on:click="{(event) => removeSchedule(event, index)}"></i>
        </button>
      </div>
    </div>
  {/each}
  <div class="next-btn">
    {#if showPrevComponent}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handleUpdate}"> Update & Next </button>
    {:else}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handleSave}"> Save & Next </button>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.svelte-tags-input-layout input) {
  outline: none !important;
  border: none;
  height: 2.3rem !important;
  font-size: 1rem;
}
:global(.svelte-tags-input-layout label) {
  font-size: 1.5rem !important;
  font-family: inherit !important;
}
:global(.svelte-tags-input-layout span) {
  background: #7cc9ff !important;
}
:global(.selectContainer input) {
  font-size: 1rem !important;
}
.title {
  justify-content: center;
  text-align: center;
  font-size: larger;
}
.next-btn {
  justify-content: center;
  text-align: center;
  margin: 20px auto;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.h-14 {
  height: 3.5rem;
}
.h-8 {
  height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.w-full {
  width: 100%;
}
.w-8 {
  width: 2rem;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:w-8\/12 {
    width: 66.666667%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
}
</style>
