<script>
// core components
import CardMerchantProfile from "components/Cards/CardMerchantProfile.svelte";
import { authToken } from "../../store/auth";
import { onMount } from "svelte";
import { toast } from "@zerodevx/svelte-toast";
import { Circle } from "svelte-loading-spinners";
import axios from "axios";
import { navigateTo } from "svelte-router-spa";

export let currentRoute;
const params = {};

let baseApiURl = morrDashboard.env.API_URL;

let merchantInfo;
let merchant_id;
let transactionStats = {};

function getMerchantProfile() {
  axios
    .get(`${baseApiURl}/merchants/${merchant_id}`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      merchantInfo = response.data.data;
    })
    .catch((err) => {
      console.log("ERROR OCCURED", err);
      toast.push("Unable to fetch merchant profile", {
        theme: {
          "--toastBackground": "#F56565",
          "--toastProgressBackground": "#C53030",
        },
      });
    });
}

function loadTransactionStats(merchant_id) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${baseApiURl}/admin/payments/transactions/stats/${merchant_id}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("TRANSACTION_STATS", res);
      transactionStats = res.data.data;
    })
    .catch((err) => {
      console.error("TRANSACTION_STATS_ERROR", err);
    });
}

onMount(() => {
  merchant_id = currentRoute.namedParams.merchant_id;
  getMerchantProfile();
  loadTransactionStats(merchant_id);
});

function visitEditMerchantPage() {
  navigateTo(`/merchants/edit/${merchant_id}`);
}
function getMerchantCover(merchantInfo) {
  return merchantInfo.images?.cover?.length > 0 ? merchantInfo.images.cover[0] : "https://kaysharbor.com/wp-content/uploads/2017/04/12-design-principles-of-a-modern-eCommerce-store-776x415.png";
}
</script>

<main class="merchant-profile-page">
  {#if merchantInfo && transactionStats}
    <section class="relative block h-500-px">
      <button class="absolute top-2 right-2 z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" style="z-index: 1;" on:click="{visitEditMerchantPage}"> Edit Merchant </button>
      <div class="absolute top-0 w-full h-full bg-center bg-cover" style="{`background-image: url("${getMerchantCover(merchantInfo)}");`}">
        <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
      </div>
      <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" style="transform: translateZ(0);">
        <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
          <polygon class="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>

    <section class="information relative py-16 -mt-48">
      <div class="flex flex-wrap">
        <div class="w-full">
          <CardMerchantProfile merchant="{merchantInfo}" transactionStats="{transactionStats}" />
        </div>
      </div>
    </section>
  {:else}
    <div class="w-full flex flex-wrap content-center justify-center text-center">
      <Circle size="60" color="#2d3748" unit="px" duration="1s" />
    </div>
  {/if}
</main>

<style windi:inject>
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-center {
  background-position: center;
}
.bg-cover {
  background-size: cover;
}
.rounded {
  border-radius: 0.25rem;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.font-bold {
  font-weight: 700;
}
.h-full {
  height: 100%;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.-mt-48 {
  margin-top: -12rem;
}
.opacity-50 {
  opacity: 0.5;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-hidden {
  overflow: hidden;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.pointer-events-none {
  pointer-events: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.top-0 {
  top: 0px;
}
.top-auto {
  top: auto;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.fill-current {
  fill: currentColor;
}
.text-center {
  text-align: center;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
[duration~="1s"] {
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>