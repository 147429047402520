<script>
import { authToken } from "../../../store/auth";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import { createEventDispatcher } from "svelte";

import CardMobileNumberMultiple from "../MobileNumber/CardMobileNumberMultiple.svelte";

export let showComponent;
export let exported_merchant_id;
// export let merchantInfo;
export let showPrevComponent;

let validationError;
let deletedContacts = [];

let dispatch = createEventDispatcher();

export let contactNumbers = [];

function performAddContact() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "MerchantContact",
      })
      .then(function (token) {
        if (token) {
          postToCreateContactApi(token);
        }
      });
  });
}

function addMoreContacts() {
  let emptyContact = {
    country_code: "60",
    phone_number: "",
    is_primary: false,
    is_whatsapp_contact: false,
  };

  contactNumbers.push(emptyContact);
  contactNumbers = contactNumbers;
}

function postToCreateContactApi(token) {
  let payload = contactNumbers;
  axios
    .post(`${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/contacts`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Contact Saved");
      dispatch("GetMerchantID", exported_merchant_id);
      showPrevComponent = "";
      showComponent = "Discount";
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function handleSave() {
  performAddContact();
}

function handlePrev() {
  showPrevComponent = "Address";
}
</script>

<div class="main">
  <div class="rounded-t mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Contact Information</h6>
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click|preventDefault="{addMoreContacts}"> Add New Contact </button>
    </div>
  </div>

  {#if contactNumbers}
    <CardMobileNumberMultiple bind:contactInfoList="{contactNumbers}" bind:deletedContactsList="{deletedContacts}" />
  {/if}

  <div class="next-btn">
    <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handlePrev}"> Prev </button>
    {#if showPrevComponent}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handleSave}"> Update & Next </button>
    {:else}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handleSave}"> Save & Next </button>
    {/if}
  </div>
</div>

<style windi:inject>
.title {
  justify-content: center;
  text-align: center;
  font-size: larger;
}
.next-btn {
  justify-content: center;
  text-align: center;
  margin: 20px auto;
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.h-8 {
  height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
