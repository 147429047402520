<script>
import { authToken } from "../../../store/auth";
import axios from "axios";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import { onMount } from "svelte";
import Navigate from "svelte-router-spa/src/components/navigate.svelte";

export let exported_merchant_id;
let validationError;

function performOnboardingComplete() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "OnboardingComplete",
      })
      .then(function (token) {
        if (token) {
          postToOnboardingComplete(token);
        }
      });
  });
}

function postToOnboardingComplete(token) {
  axios
    .post(
      `${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/complete`,
      {},
      {
        headers: {
          "X-Auth-Token": $authToken,
          "x-google-token": token,
        },
      }
    )
    .then((response) => {
      showSuccessMessage("Successfully Added Merchant");
    })
    .catch((err) => {
      validationError = err.response ? err.response.data.detail : err;
      showErrorMessage(validationError);
    });
}

onMount(() => {
  performOnboardingComplete();
});
</script>

<div class="final">
  <div class="msgs">
    <h1 class="successmsg">Onboarding successful, we will get back to you shortly.</h1>
    <Navigate to="{`/merchants/profile/${exported_merchant_id}`}"><button class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"> View Profile</button></Navigate>
  </div>
</div>

<style windi:inject>
.final {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 10px;
}
.successmsg {
  color: rgb(97, 219, 97);
  font-size: 30px;
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.font-bold {
  font-weight: 700;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
