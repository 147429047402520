<script>
import { onMount } from "svelte";
import { form, bindClass } from "svelte-forms";
import { authToken } from "../../../store/auth";
import axios from "axios";
import "intl-tel-input/build/css/intlTelInput.css";

import passwordValidator from "password-validator";
import { showSuccessMessage, showErrorMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

const passwordValidatorSchema = new passwordValidator();

passwordValidatorSchema.is().min(8).is().max(50).has().not().spaces();

let passwordInputType = "password";

let headers = {
  "X-Auth-Token": $authToken,
};

let userProfile = {
  password: "",
  confirmPassword: "",
  oldPassword: "",
};

function confirmPasswordMisMatch(value) {
  if (!userProfile.password) {
    return {
      name: "confirmPasswordMisMatch",
      valid: true,
    };
  }

  return {
    name: "confirmPasswordMisMatch",
    valid: value === userProfile.password,
  };
}

const weakPasswordValidation = function (value) {
  if (!value) {
    return {
      name: "weakPasswordValidation",
      valid: true,
    };
  }

  return {
    name: "weakPasswordValidation",
    valid: passwordValidatorSchema.validate(value),
  };
};

const isPasswordChanged = function (value) {
  return {
    name: "isPasswordChanged",
    valid: !(userProfile.password.length > 0 && value.length < 1),
  };
};

const userProfileForm = form(
  () => ({
    email: { value: userProfile.email, validators: ["required", "email"] },
    first_name: { value: userProfile.first_name, validators: ["required"] },
    last_name: { value: userProfile.last_name, validators: ["required"] },
    password: {
      value: userProfile.password,
      validators: [weakPasswordValidation],
    },
    confirmPassword: {
      value: userProfile.confirmPassword,
      validators: [confirmPasswordMisMatch],
    },
    oldPassword: {
      value: userProfile.oldPassword,
      validators: [isPasswordChanged],
    },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function requestUpdateUserProfileApi(googleRecaptchaToken) {
  let payload = {
    data: {
      old_password: userProfile.oldPassword,
      new_password: userProfile.password,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };

  axios
    .patch(`${morrDashboard.env.API_URL}/users/profile/update`, payload, {
      headers: headers,
    })
    .then((res) => {
      showSuccessMessage("Password changed successfully");
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}
function updateProfile() {
  console.log("Prepare to update profile");
  userProfileForm.validate();

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "SignUp",
      })
      .then(function (token) {
        if (token) {
          requestUpdateUserProfileApi(token);
        }
      });
  });
}

function getUserDetails() {
  axios
    .get(`${morrDashboard.env.API_URL}/users/`, {
      headers: headers,
    })
    .then((res) => {
      let userDetails = res.data.data;

      for (var key in userDetails) {
        if (userDetails.hasOwnProperty(key) && userProfile.hasOwnProperty(key)) {
          userProfile[key] = userDetails[key];
        }
      }
    })
    .catch((err) => {
      console.error("USER_PROFILE", err);
    });
}

onMount(() => {
  getUserDetails();
});

function showPassword() {
  passwordInputType = "text";
}

function hidePassword() {
  passwordInputType = "password";
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">My account</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Security Information</h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-current-password"> Current Password </label>
            <input id="grid-current-password" type="password" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{userProfile.oldPassword}" use:bindClass="{{ form: userProfileForm }}" />
            {#if $userProfileForm && $userProfileForm.fields.oldPassword?.errors?.includes("isPasswordChanged")}
              <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">The current password is required</p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-new-password"> New Password </label>
            <input id="grid-new-password" type="password" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{userProfile.password}" use:bindClass="{{ form: userProfileForm }}" />
            {#if $userProfileForm && $userProfileForm.fields.password?.errors?.includes("weakPasswordValidation")}
              <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">The password is weak, please chose a strong password</p>
            {/if}
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="grid-confirm-password"> Confirm New Password </label>
            <input id="grid-confirm-password" type="password" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{userProfile.confirmPassword}" use:bindClass="{{ form: userProfileForm }}" />
            {#if $userProfileForm && $userProfileForm.fields.confirmPassword?.errors?.includes("confirmPasswordMisMatch")}
              <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">The confirm password does not match</p>
            {/if}
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />

      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" type="submit" on:click|preventDefault="{() => updateProfile()}" disabled="{!$userProfileForm.valid}"> Change Password </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
}
</style>