<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import { showSuccessMessage } from "../../../utils/toast";
import { recaptchaWrapper } from "../../../utils/google-captcha";
import PromoBannerCrudModal from "./PromoBannerCrudModal.svelte";
import { Confirm } from "svelte-confirm";
import Toggle from "svelte-toggle";

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
};
let baseApiURL = morrDashboard.env.API_V2_URL;

let data;
let currentUpdateIndex = null;
let showModal = false;
let color = "light";
let showPromoBanners = true;
let promoBannersLoadingStatus = "Loading..";
let promoBannerId;
let promoBanners = [];
let operationType;
let promoBannerCrudCallback = () => {};

function getPromoBannersApi() {
  axios
    .get(`${baseApiURL}/admin/homepage/promo-banners/`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      promoBanners = response.data.data;
      console.log("promoBanners listing", promoBanners);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function createPromoBanner(payload) {
  recaptchaWrapper("promoBanner", createPromoBannerApi, [payload]);
}

function createPromoBannerApi(token, payload) {
  axios
    .post(`${baseApiURL}/admin/homepage/promo-banners/`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully added promo banner");
      showModal = !showModal;
      data = response.data.data;
      getPromoBannersApi();
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updatePromoBanner(payload) {
  recaptchaWrapper("promoBanner", updatePromoBannerApi, [payload]);
}

function updatePromoBannerApi(token, payload) {
  axios
    .patch(`${baseApiURL}/admin/homepage/promo-banners/${promoBannerId}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully update promo-banner");
      data = response.data.data;
      showModal = !showModal;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function visitUpdate(id, promoBanner, index) {
  promoBannerId = id;
  data = promoBanner;
  currentUpdateIndex = index;
  showModal = !showModal;
  operationType = "Update";
  promoBannerCrudCallback = updatePromoBanner;
}

$: if (data) {
  // After Updating a promo-banner, update local copy as well without api call, but we need to call promo-banners api because if we update and delete it will not shows up the update list
  if (currentUpdateIndex != null && data != promoBanners[currentUpdateIndex]) {
    promoBanners[currentUpdateIndex] = data;
  }
}

function updateStatusOfPromoBanner(event, promoBanner, index) {
  recaptchaWrapper("promoBanner", updateStatusOfPromoBannerApi, [event, promoBanner, index]);
}

function updateStatusOfPromoBannerApi(token, event, promoBanner, index) {
  if (event.type != "click" || event.target.id.indexOf("toggle") === -1) return;
  let activeStatus = promoBanner.is_listed ? false : true;
  let payload = {
    title: promoBanner.title,
    description: promoBanner.description,
    thumbnail: promoBanner.thumbnail,
    order: promoBanner.order,
    link_type: promoBanner.link_type,
    link_destination: promoBanner.link_destination,
    is_listed: activeStatus,
  };
  axios
    .patch(`${baseApiURL}/admin/homepage/promo-banners/${promoBanner.promo_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully update status");
      currentUpdateIndex = index;
      data = response.data.data;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function deletePromoBanner(promoBannerId, index) {
  recaptchaWrapper("promoBanner", deletePromoBannerApi, [promoBannerId, index]);
}

function deletePromoBannerApi(token, promoBannerId, index) {
  axios
    .delete(`${baseApiURL}/admin/homepage/promo-banners/${promoBannerId}`, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully Deleted.");
      promoBanners.splice(index, 1);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  getPromoBannersApi();
});
</script>

{#if showModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <PromoBannerCrudModal bind:showModal bind:operationType bind:promoBannerData="{data}" callback="{promoBannerCrudCallback}" />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Promo Banners</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click="{() => {
          showModal = !showModal;
          operationType = 'Create';
          data = null;
          promoBannerId = null;
          promoBannerCrudCallback = createPromoBanner;
        }}">
        Add New
      </button>
    </div>
  </div>

  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showPromoBanners}
      <Datatable settings="{settings}" data="{promoBanners}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="title"> Title </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="order"> Order </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="is-listed"> Is Listed </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="description"> Description </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="link-type"> Link Type </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="link-destination"> Link Destination </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="link-type"> Active Tab </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="edit">
            <i class="fas fa-pen text-yellow-500" title="Edit"></i>
          </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="delete">
            <i class="fas fa-trash text-red-500" title="Delete"></i>
          </th>
        </thead>
        <tbody>
          {#each $rows as promoBanner, index}
            <tr>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <span class="flex flex-col items-center">
                  <img src="{promoBanner.thumbnail ? promoBanner.thumbnail : '../assets/img/res-default.ico'}" class="h-20 w-26 bg-white border inline m-2" alt="{promoBanner.title}" />
                  <span class="font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">
                    {promoBanner.title}
                  </span>
                </span>
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">{promoBanner.order} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold">
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled="{promoBanner.is_listed}"
                  on:click="{(e) => {
                    updateStatusOfPromoBanner(e, promoBanner, index);
                  }}" />
              </td>
              <td class="border-t-0 w-1/4 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{promoBanner.description} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {promoBanner.link_type ? promoBanner.link_type : "N/A"}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {promoBanner.link_destination ? promoBanner.link_destination : "N/A"}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {promoBanner.active_tab ? promoBanner.active_tab : "N/A"}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <i class="fas fa-pen text-yellow-500" title="Edit" on:click="{() => visitUpdate(promoBanner.promo_id, promoBanner, index)}"></i>
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                  <button on:click="{() => confirmThis(deletePromoBanner, promoBanner.promo_id, index)}">
                    <i class="fas fa-trash text-red-500"></i>
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold">{promoBanner.title}</span>?
                  </span>
                  <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{promoBannersLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
tr:nth-child(2n) {
  background-color: rgb(243, 243, 243);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-screen {
  height: 100vh;
}
.h-20 {
  height: 5rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-1\/4 {
  width: 25%;
}
.w-26 {
  width: 6.5rem;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
