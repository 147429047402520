<script>
import axios from "axios";
import { authToken } from "../../store/auth";
import Select from "svelte-select";
import { onMount } from "svelte";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

let merchantItems = [];
let brandItems = [];
let merchantList = [];
let brandList = [];
let selectedMerchant = [];
let selectedBrand = [];
let revokeSelectedBrand = [];
let revokeSelectedMerchant = [];
let merchant_id;
let brand_id;
let revoke_brand_id;
let revoke_merchant_id;
let validationError;
let successMessage;

function grantMerchantToBrandAccessApi() {
  validationError = null;
  if (!brand_id) {
    showErrorMessage("Please Select Brand!");
  } else if (!merchant_id) {
    showErrorMessage("Please Select Merchant!");
  } else {
    let payload = {
      brand_id: brand_id,
      merchant_id: merchant_id,
    };
    let headers = {
      "X-Auth-Token": $authToken,
    };
    axios
      .post(`${morrDashboard.env.API_URL}/admin/brand/${brand_id}/grant/access/merchant/${merchant_id}`, payload, {
        headers: headers,
      })
      .then((res) => {
        successMessage = `Access granted: ${selectedMerchant.label} to ${selectedBrand.label}`;
        showSuccessMessage(successMessage);
      })
      .catch((err) => {
        console.log(err);
        parseAndShowErrorMessage(err);
      });
  }
}

function revokeMerchantToBrandAccessApi() {
  validationError = null;
  if (!revoke_brand_id) {
    showErrorMessage("Please Select Brand!");
  } else if (!revoke_merchant_id) {
    showErrorMessage("Please Select Merchant!");
  } else {
    let headers = {
      "X-Auth-Token": $authToken,
    };
    axios
      .delete(`${morrDashboard.env.API_URL}/admin/brand/${revoke_brand_id}/revoke/access/merchant/${revoke_merchant_id}`, {
        headers: headers,
      })
      .then((res) => {
        successMessage = `Access revoked: ${revokeSelectedMerchant.label} from ${revokeSelectedBrand.label}`;
        showSuccessMessage(successMessage);
      })
      .catch((err) => {
        parseAndShowErrorMessage(err);
      });
  }
}

function showMerchantListApi() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/merchants/merchant/list`, {
      headers: headers,
    })
    .then((res) => {
      merchantList = res.data.data;
      let merchantItemsList = [];
      merchantList.forEach((merchant) => {
        merchantItemsList.push({
          label: merchant.name,
          value: merchant.merchant_id,
        });
      });
      merchantItems = merchantItemsList;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function showBrandListApi() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/merchants/brand/list`, {
      headers: headers,
    })
    .then((res) => {
      brandList = res.data.data;
      let brandItemsList = [];
      brandList.forEach((brand) => {
        brandItemsList.push({
          label: brand.name,
          value: brand.brand_id,
        });
      });
      brandItems = brandItemsList;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  showBrandListApi();
  showMerchantListApi();
});

function handleMerchantSelect(event) {
  selectedMerchant = event.detail || [];
  merchant_id = selectedMerchant.value;
}

function handleMerchantReset(event) {
  merchant_id = null;
}

function handleBrandSelect(event) {
  selectedBrand = event.detail || [];
  brand_id = selectedBrand.value;
}

function handleBrandReset(event) {
  brand_id = null;
}

function handleRevokeBrandSelect(event) {
  revokeSelectedBrand = event.detail || [];
  revoke_brand_id = revokeSelectedBrand.value;
}

function handleRevokeMerchantReset(event) {
  revoke_merchant_id = null;
}

function handleRevokeMerchantSelect(event) {
  revokeSelectedMerchant = event.detail || [];
  revoke_merchant_id = revokeSelectedMerchant.value;
}

function handleRevokeBrandReset(event) {
  revoke_brand_id = null;
}

function handleGrantAccess() {
  grantMerchantToBrandAccessApi();
}

function handleRevokeAccess() {
  revokeMerchantToBrandAccessApi();
}
</script>

<div class="flex flex-col justify-center m-2">
  <div class="relative flex flex-col justify-center m-4 min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">Add Merchant Access</h6>
      </div>
    </div>
    <div class="flex flex-col m-4 px-4 lg:px-10 py-10 pt-0">
      <div class="w-full flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="w-full mb-3">
            <div class="flex flex-col bg-gradient-dark px-0 xl:flex-row">
              <div class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full">
                <div class="w-full">
                  <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{brandItems}" isMulti="{false}" placeholder="Please select one Brand outlet" noOptionsMessage="No brands available" on:select="{handleBrandSelect}" on:clear="{handleBrandReset}" />
                </div>
              </div>

              <div class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full">
                <div class="w-full">
                  <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{merchantItems}" isMulti="{false}" placeholder="Please select one merchant outlet" noOptionsMessage="No merchants available" on:select="{handleMerchantSelect}" on:clear="{handleMerchantReset}" />
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <div class="w-6/12 mx-auto">
                <button class=" bg-gray-900 text-white active:bg-gray-100 text-xs font-bold uppercase py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" type="submit" on:click="{handleGrantAccess}"> Grant </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="mt-6 border-b-1 border-gray-400" />

  <div class="relative flex flex-col justify-center m-4 min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-gray-800 text-xl font-bold">Revoke Merchant Access</h6>
      </div>
    </div>
    <div class="flex flex-col m-4 px-4 lg:px-10 py-10 pt-0">
      <div class="w-full flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="w-full">
            <div class="flex flex-col bg-gradient-dark xl:flex-row">
              <div class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full">
                <div class="w-full">
                  <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:10px" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{brandItems}" isMulti="{false}" placeholder="Please select one Brand outlet" noOptionsMessage="No brands available" on:select="{handleRevokeBrandSelect}" on:clear="{handleRevokeBrandReset}" />
                </div>
              </div>

              <div class="search-box m-4 mx-auto my-auto w-full sm:w-full md:w-full lg:w-full xl:w-full">
                <div class="w-full">
                  <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle; margin:20px" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{merchantItems}" isMulti="{false}" placeholder="Please select one merchant outlet" noOptionsMessage="No merchants available" on:select="{handleRevokeMerchantSelect}" on:clear="{handleRevokeMerchantReset}" />
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <div class="w-6/12 mx-auto">
                <button class="bg-gray-900 text-white active:bg-gray-100 text-xs font-bold uppercase  py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full ease-linear transition-all duration-150" type="submit" on:click="{handleRevokeAccess}"> Revoke </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-4 {
  margin: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 640px) {
  .sm\:w-full {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .md\:w-full {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-full {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .xl\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .xl\:w-full {
    width: 100%;
  }
}
</style>
