<script>
import algolia from "../../lib/Algolia";
export let merchantsToShow;
export let allMerchants;
export let searchOn;
let query = "";
async function search(e) {
  if (query == "") {
    merchantsToShow = allMerchants;
    return;
  }
  console.log(`ALGOLIA SEARCH :: QUERY ${query}`);
  merchantsToShow = (await algolia.search(query)) || [];

  merchantsToShow.map((merchant) => {
    merchant[searchOn + "_id"] = merchant.objectID;
  });
}
</script>

<div class="bg-white flex flex-row mb-2 mx-6 w-1/5 min-w-1/2 md:min-w-0 border-1 rounded">
  <div class="text-center w-full rounded">
    <input type="text" name="search" on:change="{search}" bind:value="{query}" class="outline-none p-2 w-full rounded" placeholder="Search" />
  </div>
  <div class="p-2 rounded py-2">
    <i class="fas fa-search"></i>
  </div>
</div>

<style windi:inject>
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.border-1 {
  border-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.min-w-1\/2 {
  min-width: 50%;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-2 {
  padding: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.text-center {
  text-align: center;
}
.w-1\/5 {
  width: 20%;
}
.w-full {
  width: 100%;
}
@media (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0px;
  }
}
</style>