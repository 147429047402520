<script>
import CardMerchantUpdate from "components/Cards/CardMerchantUpdate.svelte";
import CardContactUpdate from "components/Cards/CardContactUpdate.svelte";
import AddressCardWrapper from "components/Cards/Address/AddressCardWrapper.svelte";
import { Circle } from "svelte-loading-spinners";
import { onMount } from "svelte";

import { authToken } from "../../store/auth";

import axios from "axios";

export let currentRoute;

let merchant_id = null;
let merchant_data = null;

let baseApiURl = morrDashboard.env.API_URL;
let apiCallStatus = "Loading";

function getMerchantProfile() {
  axios
    .get(`${baseApiURl}/merchants/${merchant_id}`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      merchant_data = response.data.data;
    })
    .catch((err) => {
      apiCallStatus = "Something went wrong.";
      console.log("ERROR OCCURED");
      console.log(err);
    });
}

onMount(() => {
  merchant_id = currentRoute.namedParams.merchant_id;
  getMerchantProfile();
});
</script>

{#if merchant_data !== null}
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardMerchantUpdate data="{merchant_data}" merchant_id="{merchant_id}" />
      <AddressCardWrapper address="{merchant_data?.address || {}}" merchant_id="{merchant_id}" />
      <CardContactUpdate data="{merchant_data}" merchant_id="{merchant_id}" />
    </div>
  </div>
{:else}
  <div class="w-full flex flex-wrap content-center justify-center">
    <div class="px-4">
      {#if apiCallStatus === "Loading"}
        <Circle size="60" color="#2d3748" unit="px" duration="1s" />
      {/if}
      {apiCallStatus}
    </div>
  </div>
{/if}

<style windi:inject>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.w-full {
  width: 100%;
}
[duration~="1s"] {
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}
</style>