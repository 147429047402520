<script>
import { onMount } from "svelte";
import { authToken } from "../../../store/auth";
import axios from "axios";

import { form, bindClass } from "svelte-forms";
import Select from "svelte-select";
import { showSuccessMessage, showErrorMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

export let callback;
export let showModal;
export let store_id;
export let operationType;
export let addonData;

let baseApiURL = morrDashboard.env.API_V2_URL;
let groupItems = [];
let groupList = [];
let selectedGroupDetail = null;
let addonFormField = {
  addon: {
    name: "",
    price: "",
    group_ids: [],
  },
};

const addonForm = form(
  () => ({
    name: { value: addonFormField.addon.name, validators: ["required"] },
    price: { value: addonFormField.addon.price, validators: ["required"] },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);
function getGroups() {
  axios
    .get(`${baseApiURL}/admin/store/${store_id}/addons/groups/`, {
      headers: {
        "X-auth-token": $authToken,
      },
    })
    .then((response) => {
      groupItems = response.data.data;
      console.log(groupItems);
      groupItems.forEach((group) => {
        if (group.store_id === store_id) {
          groupList.push({ label: group.name, value: group.group_id });
        }
      });
      groupList = groupList; //Due to svelte render
    })
    .catch((err) => {
      console.log(err);
      // parseAndShowErrorMessage(err);
    });
}

function buildFormPayload() {
  return {
    name: addonFormField.addon.name,
    price: addonFormField.addon.price,
    group_ids: [...new Set(addonFormField.addon.group_ids)],
  };
}
function callbackToCreateAddon() {
  const payload = buildFormPayload();
  if (!payload.name) {
    showErrorMessage("Name can't be empty");
  } else if (!payload.price) {
    showErrorMessage("Price can't be empty");
  } else {
    console.log(payload);
    callback(payload);
  }
}
function handleGroups(e) {
  selectedGroupDetail = e.detail || [];
  selectedGroupDetail.forEach((selectedGroup) => {
    addonFormField.addon.group_ids.push(selectedGroup.value);
  });
}

function resetGroups(e) {
  addonFormField.addon.group_ids = [];
}

onMount(() => {
  if (addonData) {
    let group_ids = [];
    if (addonData.groups.length > 0) {
      selectedGroupDetail = [];
    }
    addonData.groups.forEach((group) => {
      selectedGroupDetail.push({ label: group.name, value: group.group_id });
      group_ids.push(group.group_id);
    });
    addonFormField.addon = {
      name: addonData.name,
      price: addonData.price,
      group_ids: group_ids,
    };
  }
  getGroups();
});
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0">
  <div class="rounded-t mb-0 px-6 py-2">
    <h6 class="text-gray-800 text-lg font-bold">Addon Information</h6>
  </div>
  <div class="flex-auto px-2 lg:px-10 py-4 pt-0 my-2">
    <form>
      <div class="flex flex-col">
        <div class="flex">
          <div class="w-full px-2">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="addon-name"> Name </label>
              <input id="addon-name" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter addon" bind:value="{addonFormField.addon.name}" use:bindClass="{{ form: addonForm, name: 'name' }}" />
            </div>
          </div>

          <div class="w-full px-2">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="addon-price"> Price </label>
              <input id="addon-price" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter price" bind:value="{addonFormField.addon.price}" use:bindClass="{{ form: addonForm, name: 'price' }}" />
            </div>
          </div>
        </div>
        {#if groupList}
          <div class="px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="groups"> Groups </label>
              <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{selectedGroupDetail}" items="{groupList}" isMulti="{true}" placeholder="Please select group" noOptionsMessage="No groups available" on:select="{handleGroups}" on:clear="{resetGroups}" />
            </div>
          </div>
        {/if}
        <div class="flex flex-row">
          <div class="w-12/12 px-4 mt-4 mx-auto">
            <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click|preventDefault="{callbackToCreateAddon}">
              {operationType === "Create" ? "Create" : "Update"}
            </button>
            <button
              class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8"
              type="button"
              on:click|preventDefault="{() => {
                showModal = false;
              }}">
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.h-8 {
  height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-12\/12 {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
</style>