<script>
import { Router } from "svelte-router-spa";
import { routes } from "./routes";
import { authToken, isLoggedIn, loadRouter, refreshToken, userMobileCountryCode, userMobileCountryCodeVerified } from "./store/auth";

import { serializeError } from "serialize-error";
import axios from "axios";
import { onMount } from "svelte";
import axiosRetry from "axios-retry";
import { SvelteToast } from "@zerodevx/svelte-toast";
import { navigateTo } from "svelte-router-spa";

axios.defaults.withCredentials = true;

let invalidTokens = new Set();
let invalidAuthStatus = [401, 403, 422];
let retryingWithRefreshToken = false;
let stopRetries = false;

axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    if (retryCount === 1 && !stopRetries) {
      retryingWithRefreshToken = false;
    } else if (retryCount >= 3) {
      navigateTo("/auth/login");
      stopRetries = true;
    }
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    if (error.config?.url.indexOf("perms") > 0) {
      console.log("Perms api issue");
      // navigateTo('/auth/login');
      // authToken.update(() => null);
      // isLoggedIn.update(() => false);
      return;
    }
    if (stopRetries || !$isLoggedIn) {
      navigateTo("/auth/login");
      authToken.update(() => null);
      isLoggedIn.update(() => false);
      return false;
    }
    const _error = error.response ? error.response.data : error;
    const statusCode = error.response ? error.response.status : null;
    let result = invalidAuthStatus.indexOf(statusCode) !== -1 && _error?.detail === "Invalid credentials or expired token.";
    if (result) {
      invalidTokens.add(error.config.headers["X-Auth-Token"]);
      globalApiErrorHandler(error);
    }
    error.config.headers["X-Auth-Token"] = $authToken;
    return result;
  },
});

const globalApiErrorHandler = (error) => {
  const _error = error.response ? error.response.data : error;
  const statusCode = error.response ? error.response.status : null;

  if (!retryingWithRefreshToken && invalidAuthStatus.indexOf(statusCode) !== -1 && _error.detail === "Invalid credentials or expired token.") {
    retryingWithRefreshToken = true;
    if (!invalidTokens.has($authToken)) {
      return;
    }
    axios
      .post(`${morrDashboard.env.API_URL}/users/refresh`, null, {
        headers: { "X-Auth-Token": $refreshToken },
      })
      .then((res) => {
        retryingWithRefreshToken = false;
        stopRetries = false;
        authToken.update(() => res.data.access_token);
        isLoggedIn.update(() => true);
        error.config.headers["X-Auth-Token"] = res.data.access_token;
        navigateTo("/merchants/dashboard");
      })
      .catch((err) => {
        authToken.update(() => "");
        refreshToken.update(() => "");
        isLoggedIn.update(() => false);
        navigateTo("/auth/login");
      });
  }
};

onMount(() => {
  (async function getCountrycode() {
    let userCountryCode;
    console.log("Initial Country code", $userMobileCountryCode, userCountryCode, $userMobileCountryCodeVerified);
    if (!$userMobileCountryCodeVerified) {
      console.log("Setting Country code");
      try {
        const request = await fetch("https://ipinfo.io/json?token=4348641a1ea714");
        const resp = await request.json();
        userCountryCode = resp && resp.country ? resp.country : "MY";
        if (!userCountryCode) {
          userCountryCode = "MY";
        }
        userMobileCountryCode.update(() => userCountryCode);
        userMobileCountryCodeVerified.update(() => true);
      } catch (err) {
        console.log("Unable to fetch current country code", err);
        userCountryCode = "MY";
        userMobileCountryCode.update(() => userCountryCode);
        userMobileCountryCodeVerified.update(() => false);
      }
    }
    console.log("Done setting up Country code", $userMobileCountryCode, userCountryCode, $userMobileCountryCodeVerified);
  })();

  function revalidate() {
    axios
      .post(`${morrDashboard.env.API_URL}/users/re-validate`, null, {
        headers: { "X-Auth-Token": $authToken },
      })
      .then((res) => res)
      .catch((err) => err);
  }
  revalidate();
  loadRouter.set(true);
});

let isProduction = morrDashboard.env.APP_MODE === "production";
if (isProduction) {
  if (!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info", "table"];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

let toast_options = {
  duration: 3000, // duration of progress bar tween to the `next` value
  pausable: true, // pause progress bar tween on mouse hover
  dismissable: true, // allow dismiss with close button
};
</script>

{#if $loadRouter}
  <Router routes="{routes}" />
{/if}

<SvelteToast options="{toast_options}" />

<style windi:inject global windi:global windi:preflights:global windi:safelist:global>
:global(*), :global(::before), :global(::after) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
:global(*) {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:global(:root) {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:global(:-moz-focusring) {
  outline: 1px dotted ButtonText;
}
:global(:-moz-ui-invalid) {
  box-shadow: none;
}
:global(::moz-focus-inner) {
  border-style: none;
  padding: 0;
}
:global(::-webkit-inner-spin-button), :global(::-webkit-outer-spin-button) {
  height: auto;
}
:global(::-webkit-search-decoration) {
  -webkit-appearance: none;
}
:global(::-webkit-file-upload-button) {
  -webkit-appearance: button;
  font: inherit;
}
:global([type='search']) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
:global(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
:global(body) {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
:global(html) {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
:global(a) {
  color: inherit;
  text-decoration: inherit;
}
:global(b), :global(strong) {
  font-weight: bolder;
}
:global(button), :global(input), :global(optgroup), :global(select), :global(textarea) {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
:global(button), :global(select) {
  text-transform: none;
}
:global(button), :global([type='button']), :global([type='reset']), :global([type='submit']) {
  -webkit-appearance: button;
}
:global(blockquote), :global(dl), :global(dd), :global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6), :global(hr), :global(figure), :global(p), :global(pre) {
  margin: 0;
}
:global(button) {
  background-color: transparent;
  background-image: none;
}
:global(button:focus) {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
:global(button), :global([role="button"]) {
  cursor: pointer;
}
:global(code), :global(kbd), :global(samp), :global(pre) {
  font-size: 1em;
}
:global(fieldset) {
  margin: 0;
  padding: 0;
}
:global(hr) {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
:global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6) {
  font-size: inherit;
  font-weight: inherit;
}
:global(img) {
  border-style: solid;
}
:global(input::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(img), :global(svg), :global(video), :global(canvas), :global(audio), :global(iframe), :global(embed), :global(object) {
  display: block;
  vertical-align: middle;
}
:global(img), :global(video) {
  max-width: 100%;
  height: auto;
}
:global(legend) {
  padding: 0;
}
:global(ol), :global(ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}
:global(progress) {
  vertical-align: baseline;
}
:global(pre), :global(code), :global(kbd), :global(samp) {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
:global(small) {
  font-size: 80%;
}
:global(sub), :global(sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
:global(sub) {
  bottom: -0.25em;
}
:global(sup) {
  top: -0.5em;
}
:global(summary) {
  display: list-item;
}
:global(table) {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
:global(textarea) {
  resize: vertical;
}
:global(textarea::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(.morr-background) {
  background-color: #d1193e !important;
}
:global(.morr-background-light) {
  background-color: rgba(232, 173, 144, 0.411) !important;
}
:global(.morr-background-white) {
  background-color: #f5f1ea !important;
  color: black;
}
:global(.iti__flag) {
  background-image: url("/img/flags.png") !important;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :global(.iti__flag) {
    background-image: url("/img/flags@2x.png") !important;
  }
}
:global(input:focus ~ label), :global(input:not(:placeholder-shown) ~ label), :global(textarea:focus ~ label), :global(textarea:not(:placeholder-shown) ~ label), :global(select:focus ~ label), :global(select:not([value=""]):valid ~ label), :global(.iti ~ label) {
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  -webkit-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  -ms-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  --tw-translate-y: -1.5rem;
}
:global(input:focus ~ label), :global(select:focus ~ label), :global(.iti ~ label) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 0px;
  --tw-translate-x: 0px;
}
:global(input:-webkit-autofill) {
  background-color: transparent !important;
}
:global(.selectContainer) {
  max-height: 3rem !important;
  min-height: 3rem !important;
}
</style>
