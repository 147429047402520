<script>
import { form, bindClass } from "svelte-forms";
import Select from "svelte-select";
import { showErrorMessage } from "../../../utils/toast";
import { onMount } from "svelte";
import Toggle from "svelte-toggle";

export let showModal;
export let operationType;
export let rowsData;
export let callback;

let renderTemplate = false;
let row_name = [
  {
    label: "Quicklinks",
    value: "quicklinks",
  },
  {
    label: "Most Visited",
    value: "most_visited",
  },
  {
    label: "Promo Banners",
    value: "promo_banners",
  },
  {
    label: "Nearby",
    value: "nearby",
  },
];

let orientation = [
  {
    label: "Portrait",
    value: "portrait",
  },
  {
    label: "Landscape",
    value: "landscape",
  },
];
let layout = [
  {
    label: "Carousel",
    value: "carousel",
  },
  {
    label: "Icon list",
    value: "icon_list",
  },
  {
    label: "Thumbnail list",
    value: "thumbnail_list",
  },
  {
    label: "Detail list",
    value: "detail_list",
  },
];
let validationError;

let rowFormFields = {
  row: {
    title: "",
    order: 0,
    is_active: true,
    row_name: "",
    orientation: "",
    layout: "",
    api_url: "",
  },
};

const createRowForm = form(
  () => ({
    title: {
      value: rowFormFields.row.title,
      validators: ["required"],
    },
    order: {
      value: rowFormFields.row.order,
      validators: ["required"],
    },
    is_active: {
      value: rowFormFields.row.is_active,
      validators: ["required"],
    },
    row_name: {
      value: rowFormFields.row.row_name,
      validators: ["required"],
    },
    orientation: {
      value: rowFormFields.row.orientation,
      validators: ["required"],
    },
    layout: {
      value: rowFormFields.row.layout,
      validators: ["required"],
    },
    api_url: {
      value: rowFormFields.row.api_url,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function buildFormPayload() {
  return {
    title: rowFormFields.row.title,
    order: rowFormFields.row.order,
    is_active: rowFormFields.row.is_active,
    row_name: rowFormFields.row.row_name,
    orientation: rowFormFields.row.orientation,
    layout: rowFormFields.row.layout,
    api_url: rowFormFields.row.api_url,
  };
}

function validateRowData() {
  validationError = null;

  if (!rowFormFields.row.title) {
    validationError = "Title is required";
  } else if (rowFormFields.row.order <= 0) {
    validationError = "Order must be greater than 0";
  } else if (!rowFormFields.row.row_name) {
    validationError = "Row name is required";
  } else if (!rowFormFields.row.orientation) {
    validationError = "Orientation is required";
  } else if (!rowFormFields.row.layout) {
    validationError = "Layout is required";
  } else if (!rowFormFields.row.api_url) {
    validationError = "URL is required";
  }

  if (validationError) {
    showErrorMessage(validationError);
    return;
  }

  let payload = buildFormPayload();
  console.log(payload);
  callback(payload);
}

function handleRowName(e) {
  rowFormFields.row.row_name = e.detail.value;
}

function resetRowName(e) {
  rowFormFields.row.row_name = "";
}

function handleLayout(e) {
  rowFormFields.row.layout = e.detail.value;
}

function resetLayout(e) {
  rowFormFields.row.layout = "";
}

function handleOrientation(e) {
  rowFormFields.row.orientation = e.detail.value;
}
function resetOrientation(e) {
  rowFormFields.row.orientation = "";
}

onMount(() => {
  if (rowsData) {
    rowFormFields.row = rowsData;
  }
  setTimeout(() => {
    renderTemplate = true;
  }, 500);
});
</script>

{#if renderTemplate}
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0">
    <div class="flex-auto px-4 lg:px-4 py-4 pt-0">
      <form>
        <div class="flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            {operationType === "create" ? "Create" : "Update"} Row
          </h6>
          <div></div>
        </div>
        <div>
          <div class="w-full">
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="title"> Title </label>
                <input id="title" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter row title" bind:value="{rowFormFields.row.title}" use:bindClass="{{ form: createRowForm, name: 'title' }}" />
              </div>
            </div>
            <div class="flex">
              <div class="px-4 w-3/4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="order"> Order </label>
                  <input id="order" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter sequence number" bind:value="{rowFormFields.row.order}" use:bindClass="{{ form: createRowForm, name: 'order' }}" />
                </div>
              </div>
              <div class="px-4 items-center">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="is-active"> Status </label>
                  <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{rowFormFields.row.is_active}" />
                </div>
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="row_name"> RowName </label>
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{rowFormFields.row.row_name}" items="{row_name}" isMulti="{false}" placeholder="Please select row name" noOptionsMessage="No Row name available" on:select="{handleRowName}" on:clear="{resetRowName}" />
              </div>
            </div>

            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="layout"> Layout </label>
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{rowFormFields.row.layout}" items="{layout}" isMulti="{false}" placeholder="Please select layout" noOptionsMessage="No layouts available" on:select="{handleLayout}" on:clear="{resetLayout}" />
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="orientation"> Orientation </label>
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{rowFormFields.row.orientation}" items="{orientation}" isMulti="{false}" placeholder="Please select orientation" noOptionsMessage="No orientations available" on:select="{handleOrientation}" on:clear="{resetOrientation}" />
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="url"> Url </label>
                <input id="url" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your API url" bind:value="{rowFormFields.row.api_url}" use:bindClass="{{ form: createRowForm, name: 'api_url' }}" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <div class="flex items-center justify-center w-full m-4 justify-items-center ">
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault="{() => {
                validateRowData();
              }}">
              {operationType === "create" ? "Save" : "Update"}
            </button>
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault="{() => {
                showModal = !showModal;
              }}">
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
{/if}

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-items-center {
  justify-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-3\/4 {
  width: 75%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
