<ul role="tablist" class="svelte-tabs__tab-list">
  <slot />
</ul>

<style windi:inject>
.svelte-tabs__tab-list {
  border-bottom: 1px solid #cccccc;
  margin: 0;
  padding: 0;
}
</style>
