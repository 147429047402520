<script>
import { Route } from "svelte-router-spa";

// components for this layout
import AuthNavbar from "components/Navbars/AuthNavbar.svelte";
import FooterSmall from "components/Footers/FooterSmall.svelte";

const registerBg2 = "../assets/img/pattern_svelte.png";

export let currentRoute;
const params = {};
</script>

<div>
  <AuthNavbar />
  <main>
    <section class="relative w-full h-full py-40 min-h-screen">
      <div class="absolute top-0 w-full h-full bg-opacity-50 bg-no-repeat bg-full" style="background-color: #D1193E;"></div>
      <Route currentRoute="{currentRoute}" params="{params}" />
      <FooterSmall absolute="true" />
    </section>
  </main>
</div>

<style windi:inject>
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.h-full {
  height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-0 {
  top: 0px;
}
.w-full {
  width: 100%;
}
</style>