<script>
import "intl-tel-input/build/css/intlTelInput.css";
import CardAddMerchant from "../Cards/CardsAddMerchant/CardAddMerchant.svelte";
import CardAddress from "./CardsAddMerchant/CardAddress.svelte";
import CardContact from "./CardsAddMerchant/CardContact.svelte";
import CardDiscount from "./CardsAddMerchant/CardDiscount.svelte";
import CardImage from "./CardsAddMerchant/CardImage.svelte";
import CardAddFinal from "./CardsAddMerchant/CardAddFinal.svelte";
import { authToken } from "../../store/auth";
import axios from "axios";

let showComponent = "Add Merchant";
let showPrevComponent;
let exported_merchant_id;
let merchantInfo;

async function getMerchantInfoApi(merchantID) {
  return axios.get(`${morrDashboard.env.API_URL}/merchants/${merchantID}`, {
    headers: {
      "X-Auth-Token": $authToken,
    },
  });
}

async function handleMerchantId(e) {
  console.log("Handle merchantInfo ", merchantInfo);
  exported_merchant_id = e.detail;
  merchantInfo = await getMerchantInfoApi(exported_merchant_id)
    .then((res) => res.data.data)
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

merchantInfo = {};
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="p-row">
    <div class:active="{showComponent === 'Add Merchant' || showComponent === 'Address' || showComponent === 'Contact' || showComponent === 'Discount' || showComponent === 'Image' || showComponent === 'Final'}" class="p-col">Merchant Information</div>
    <div class:active="{showComponent === 'Address' || showComponent === 'Contact' || showComponent === 'Discount' || showComponent === 'Image' || showComponent === 'Final'}" class="p-col">Address Information</div>
    <div class:active="{showComponent === 'Contact' || showComponent === 'Discount' || showComponent === 'Image' || showComponent === 'Final'}" class="p-col">Contact Information</div>
    <div class:active="{showComponent === 'Discount' || showComponent === 'Image' || showComponent === 'Final'}" class="p-col">Discount Information</div>
    <div class:active="{showComponent === 'Image' || showComponent === 'Final'}" class="p-col">Add Image</div>
  </div>
  <div class="child">
    {#if showComponent === "Add Merchant" || showPrevComponent === "Add Merchant"}
      <CardAddMerchant bind:showComponent on:GetMerchantID="{handleMerchantId}" bind:showPrevComponent merchantInfo="{merchantInfo}" />
    {:else if (showComponent === "Address" || showPrevComponent === "Address") && exported_merchant_id}
      <CardAddress bind:showComponent exported_merchant_id="{exported_merchant_id}" on:GetMerchantID="{handleMerchantId}" bind:showPrevComponent merchantInfo="{merchantInfo}" />
    {:else if (showComponent === "Contact" || showPrevComponent === "Contact") && exported_merchant_id}
      <CardContact bind:showComponent exported_merchant_id="{exported_merchant_id}" on:GetMerchantID="{handleMerchantId}" bind:showPrevComponent bind:contactNumbers="{merchantInfo.contact_numbers}" />
    {:else if (showComponent === "Discount" || showPrevComponent === "Discount") && exported_merchant_id}
      <CardDiscount bind:showComponent exported_merchant_id="{exported_merchant_id}" on:GetMerchantID="{handleMerchantId}" bind:showPrevComponent merchantInfo="{merchantInfo}" />
    {:else if (showComponent === "Image" || showPrevComponent === "Image") && exported_merchant_id}
      <CardImage bind:showComponent exported_merchant_id="{exported_merchant_id}" bind:showPrevComponent />
    {:else if showComponent === "Final" && exported_merchant_id}
      <CardAddFinal bind:showComponent exported_merchant_id="{exported_merchant_id}" bind:showPrevComponent />
    {/if}
  </div>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}
.child {
  margin: 20px;
  justify-content: center;
  align-items: center;
}
.p-row {
  width: 100%;
  box-shadow: 0px -1px 5px -1px black;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.p-col {
  width: 20%;
  text-align: center;
  z-index: 1;
  font-weight: bolder;
  height: 100%;
  font-size: 14px;
}
.active {
  color: white;
  background-color: rgb(37, 36, 36);
  height: 100%;
  transition: 0.4s;
  box-shadow: 0px -1px 5px -1px black;
}
@media (max-width: 509px) {
  .p-row {
    display: flex;
    flex-direction: column;
  }
  .p-col {
    width: 100%;
    box-shadow: 0px -1px 2px -1px black;
  }
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-0 {
  border-width: 0px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
</style>
