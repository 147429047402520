<script>
import { createEventDispatcher } from "svelte";
import AddressCard from "../Address/AddressCard.svelte";

export let showComponent;
export let showPrevComponent;
export let exported_merchant_id;
export let merchantInfo;

let dispatch = createEventDispatcher();
let AddressCardRef;

function handleSave() {
  dispatch("GetMerchantID", exported_merchant_id);
  showComponent = "Contact";
}

function handlePrev() {
  showPrevComponent = "Add Merchant";
}
</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Address Information</h6>
  <div class="flex flex-wrap">
    <AddressCard address="{merchantInfo?.address || {}}" merchant_id="{exported_merchant_id}" callbackfn="{handleSave}" bind:this="{AddressCardRef}" />
  </div>
  <div class="next-btn">
    <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handlePrev}"> Prev </button>
    {#if showPrevComponent}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{() => AddressCardRef.handleUpdate()}"> Update & Next </button>
    {:else}
      <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{() => AddressCardRef.handleUpdate()}"> Save & Next </button>
    {/if}
  </div>
</div>

<style windi:inject>
.title {
  justify-content: center;
  text-align: center;
  font-size: larger;
}
.next-btn {
  justify-content: center;
  text-align: center;
  margin: 20px auto;
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.font-bold {
  font-weight: 700;
}
.h-8 {
  height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
