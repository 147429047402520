<script>
export let size = "100%";
export let strokeWidth = 2;
let customClass = "";
export { customClass as class };

if (size !== "100%") {
  size = size.slice(-1) === "x" ? size.slice(0, size.length - 1) + "em" : parseInt(size) + "px";
}
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="{size}" height="{size}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="{strokeWidth}" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield-off {customClass}"><path d="M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18"></path><path d="M4.73 4.73L4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>

<style windi:inject>
[fill~="none"] {
  fill: none;
}
</style>