<script>
import axios from "axios";
import "intl-tel-input/build/css/intlTelInput.css";
import { onMount } from "svelte";

import { authToken } from "../../store/auth";
import { toast } from "@zerodevx/svelte-toast";

import CardMobileNumberMultiple from "./MobileNumber/CardMobileNumberMultiple.svelte";

export let data;
export let merchant_id;

let baseApiURl = morrDashboard.env.API_URL;

let numberOfContacts = 0;
let numberOfDeletedContacts = 0;
let contactNumbers;
let deletedContacts = [];

let contactFormFields = {
  contacts: [],
  deleted_contacts: [],
};

let emptyContact = {
  country_code: 60,
  phone_number: "",
  order: 1,
  is_primary: false,
  is_whatsapp_contact: false,
};

function updateContactDetails(apiContacts) {
  apiContacts.forEach((contact) => {
    contactNumbers.push({ ...contact });
  });
  contactNumbers = [...contactNumbers];
}

function buildFormPayload(googleRecaptchaToken) {
  return {
    update_contacts: {
      contacts: contactNumbers,
      deleted_contacts: deletedContacts,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };
}

function postToUpdateContactAPI(payload) {
  console.log("PAYLOAD " + JSON.stringify(payload));

  axios
    .put(`${baseApiURl}/admin/merchants/update/${merchant_id}/contact`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      toast.push("Successfully updated merchant contacts", {
        theme: {
          "--toastBackground": "#48BB78",
          "--toastProgressBackground": "#2F855A",
        },
      });
      console.log("CONTACT_UPDATE", response);
      contactFormFields.deleted_contacts = [];
      deletedContacts = [];
      numberOfDeletedContacts = 0;
    })
    .catch((err) => {
      console.error("CONTACT_UPDATE_ERROR", err);
      parseAndShowErrorMessage(err);
    });
}

function updateContact() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "UpdateContact",
      })
      .then(function (token) {
        if (token) {
          let payload = buildFormPayload(token);
          postToUpdateContactAPI(payload);
        }
      });
  });
}

function addMoreContacts() {
  contactNumbers.push({ ...emptyContact });
  contactNumbers = [...contactNumbers];
}

function restoreContact(event, index) {
  event.preventDefault();

  const restoredContact = deletedContacts[index];

  deletedContacts.splice(index, 1);
  contactNumbers.push(restoredContact);

  contactNumbers = [...contactNumbers];
  deletedContacts = [...deletedContacts];
}

onMount(() => {
  let apiContacts = data.contact_numbers;
  if (apiContacts.length > 0) {
    contactNumbers = [];
    updateContactDetails(apiContacts);
  } else {
    contactNumbers = [{ ...emptyContact }];
  }
});
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Update Contact</h6>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <div class="rounded-t mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Contact Information</h6>
          <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click|preventDefault="{addMoreContacts}"> Add New Contact </button>
        </div>
      </div>

      <div class="flex flex-wrap w-full">
        {#if contactNumbers}
          <CardMobileNumberMultiple bind:contactInfoList="{contactNumbers}" bind:deletedContactsList="{deletedContacts}" />
        {/if}
      </div>

      {#if numberOfDeletedContacts !== 0}
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Deleted Contacts</h6>
          </div>
        </div>
      {/if}

      <div class="flex flex-wrap">
        {#each deletedContacts as deletedContact, index}
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-contact-number`}"> Mobile Number </label>
              <input id="{`${index}-contact-number`}" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter a valid mobile number" bind:value="{deletedContact.phone_number}" readonly />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-0">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-restore`}"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
            <button class="flex-shrink align-text-bottom" on:click="{(event) => restoreContact(event, index)}">
              <i class="fas fa-undo text-green-500"></i>
            </button>
          </div>
          <hr class="w-full lg:w-12/12 mt-6 mb-6 border-b-1 border-gray-400" />
        {/each}
      </div>

      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" type="submit" on:click|preventDefault="{() => updateContact()}"> Update Contact </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.h-8 {
  height: 2rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
  .lg\:w-12\/12 {
    width: 100%;
  }
}
</style>
