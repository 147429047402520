<script>
import axios from "axios";
import { showSuccessMessage } from "../../../utils/toast";
import TinyEditor from "@tinymce/tinymce-svelte";
import { authToken } from "../../../store/auth";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import minifyHTML from "../../../utils/minifyHtml";
import { onMount } from "svelte";
import { HTMLStringParser } from "../../../utils/minifyHtml";

export let templateUpdateObj;
export let emailTemplates;
export let index;

let htmlBody;

let tinyConf = {
  plugins: ["advlist autolink lists link image charmap print preview anchor fullpage", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste emoticons autoresize"],
  toolbar: "styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image emoticons | code",
  skin: "small",
};

function updateEmailTemplateAPI() {
  let payload = {
    name: templateUpdateObj.name,
    service_provider: templateUpdateObj.service_provider,
    subject: templateUpdateObj.subject,
    html_body: minifyHTML(htmlBody),
    text_body: templateUpdateObj.text_body,
  };
  axios
    .patch(`${morrDashboard.env.API_URL}/admin/email-templates/${templateUpdateObj.template_id}`, payload, {
      headers: {
        "x-auth-token": $authToken,
      },
    })
    .then((res) => {
      emailTemplates[index] = templateUpdateObj;
      emailTemplates = [...emailTemplates];
      htmlBody = HTMLStringParser(templateUpdateObj.html_body);
      let successMessage = "Successfully Updated Email Template";
      showSuccessMessage(successMessage);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  htmlBody = HTMLStringParser(templateUpdateObj.html_body);
});
</script>

<div class="flex items-center justify-center p-4 shadow-xl rounded-lg bg-gray-200">
  <div class="w-full flex items-center justify-center">
    <form class="w-full flex flex-col items-center leading-10 rounded-xl">
      <h1 class="text-center font-bold text-xl">Edit Email Template</h1>
      <div class="w-full flex flex-row items-center justify-center px-2 xs:flex-col">
        <div class="w-6/12 p-2">
          <input class="w-full my-2 px-2" id="name" type="text" placeholder="Name" bind:value="{templateUpdateObj.name}" />
          <input class="w-full my-2 px-2" id="service-provider" type="text" placeholder="Service Provider" bind:value="{templateUpdateObj.service_provider}" />
        </div>

        <div class="w-6/12 p-2">
          <input class="w-full my-2 px-2" id="subject" type="text" placeholder="Subject" bind:value="{templateUpdateObj.subject}" />
          <input class="w-full my-2 px-2" id="text-body" type="text" placeholder="Text Body" bind:value="{templateUpdateObj.text_body}" />
        </div>
      </div>

      <div class="w-full px-4 mx-4">
        <div class="bg-white w-full" style="font-size: 0.8rem;">
          <TinyEditor apiKey="{morrDashboard.env.TINYMCE_API_KEY}" conf="{tinyConf}" bind:value="{htmlBody}" />
        </div>
      </div>

      <div class="text-center w-full mt-2 mb-2">
        <button class="btn w-10/12 rounded-3xl uppercase py-0 font-bold outline-none" style="background-color: white;" type="button" on:click="{updateEmailTemplateAPI}"> Update Template</button>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.font-bold {
  font-weight: 700;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-4 {
  padding: 1rem;
}
.p-2 {
  padding: 0.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.shadow-xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
.w-10\/12 {
  width: 83.333333%;
}
</style>