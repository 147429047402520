<script>
import { fade, scale } from "svelte/transition";
import Carousel from "svelte-carousel";
import axios from "axios";
import { authToken } from "../../store/auth";
import { onMount } from "svelte";
import { Confirm } from "svelte-confirm";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import CardProductCreate from "./CardProductCreate.svelte";
import CardProductUpdate from "./CardProductUpdate.svelte";
import CardBulkProductUpload from "./CardBulkProductUpload.svelte";
import { dndzone } from "svelte-dnd-action";
import { flip } from "svelte/animate";
import { overrideItemIdKeyNameBeforeInitialisingDndZones } from "svelte-dnd-action";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
import StoreAddonsGroupList from "./StoreAddonGroups/StoreAddonGroupList.svelte";
import StoreAddonsList from "./StoreAddons/StoreAddonsList.svelte";
import { Tabs, Tab, TabList, TabPanel } from "svelte-tabs";
import SvelteGenericCrudTable from "svelte-generic-crud-table";

overrideItemIdKeyNameBeforeInitialisingDndZones("product_id");
const flipDurationMs = 200;

export let store_id;
export let brand_id;

let baseApiURL = morrDashboard.env.API_URL;
let data;
let product_id;
let showCreate = false;
let showBulkUpload = false;
let showUpdate = false;
let validationError = null;
let items = [];
let showProduct = false;
let noProduct = "loading products ...";
let updatedList = [];
let recentlyAddedProducts = [];
let showRecentlyAdded = false;
let currentUpdateIndex = null;
let categoryMapping = {};
let groupMapping = {};

function handleSort(e) {
  items = e.detail.items;
}

function changeSequence() {
  updatedList = JSON.parse(JSON.stringify(items));
  for (let i = 1; i < updatedList.length; i++) {
    updatedList[i - 1].next_product_id = updatedList[i].product_id;
  }
  updatedList[updatedList.length - 1].next_product_id = null;

  // change category list [cat1_details, cat2_details] to category_ids [1, 2 ...]
  // Put API just expects the IDs only not complete category details
  updatedList.map(function (product) {
    product.category_ids = product.categories.map(function (category) {
      return category.category_id;
    });
    delete product.categories;
    return product;
  });

  axios
    .put(`${baseApiURL}/admin/store/${store_id}/product`, updatedList, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      let successMessage = "Changes Saved";
      showSuccessMessage(successMessage);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}
function buildUpdatePayload(products) {
  let payload = [];
  products.forEach((product) => {
    payload.push({
      is_deliverable: product.is_deliverable,
      is_pickable: product.is_pickable,
      is_available: product.is_available,
      product_name: product.product_name,
      description: product.description,
      product_id: product.product_id,
      variations: product.variations,
      category_ids: categoryMapping[product.product_id],
      group_ids: groupMapping[product.product_id],
    });
  });
  return payload;
}
function getProductListApi() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${baseApiURL}/admin/store/${store_id}/product`, {
      headers: headers,
    })
    .then((res) => {
      items = res.data.data;
      items.forEach((item) => {
        var defaultVariation = {};
        item.variations.forEach((variation) => {
          if (variation.is_default) {
            defaultVariation = variation;
          }
        });
        item["variation_price"] = defaultVariation.price;
      });
      showProduct = true;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      showProduct = false;
      noProduct = "Products not found";
    });
}
onMount(() => {
  getProductListApi();
  handleBulkUpdateProductWidth();
});

function deleteProductAPI(productIndex, productID) {
  let headers = {
    "X-Auth-Token": $authToken,
  };

  axios
    .delete(`${baseApiURL}/admin/store/${store_id}/product/${productID}`, {
      headers: headers,
    })
    .then((res) => {
      items.splice(productIndex, 1);
      showProduct = true;
      showSuccessMessage("Succesfully deleted the product");
      getProductListApi();
    })
    .catch((err) => {
      console.log("PRODUCT_DELETE_ERR", err);
      parseAndShowErrorMessage(err);
    });
}

function addRecentProducts(e) {
  showRecentlyAdded = true;
  if (Array.isArray(e.detail)) {
    e.detail.forEach((product) => {
      recentlyAddedProducts = [...recentlyAddedProducts, product];
    });
  } else {
    recentlyAddedProducts = [...recentlyAddedProducts, e.detail];
  }
}

function postToCreateAPI() {
  axios
    .post(`${baseApiURL}/admin/store/${store_id}/product`, recentlyAddedProducts, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully Added Products");
      showRecentlyAdded = !showRecentlyAdded;
      recentlyAddedProducts = [];
      getProductListApi();
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function deleteProduct(productIndex, productID) {
  deleteProductAPI(productIndex, productID);
}

function deleteRecentlyProduct(productIndex) {
  recentlyAddedProducts.splice(productIndex, 1);
  recentlyAddedProducts = [...recentlyAddedProducts];
}

function visitUpdate(id, product, items, index) {
  product_id = id;
  data = product;
  showUpdate = !showUpdate;
  currentUpdateIndex = index;
}

$: if (data) {
  // After Updating a Product, update local copy as well without api call
  if (currentUpdateIndex != null && data != items[currentUpdateIndex]) {
    items[currentUpdateIndex] = data;
    items.forEach((item) => {
      var defaultVariation = {};
      item.variations.forEach((variation) => {
        if (variation.is_default) {
          defaultVariation = variation;
        }
      });
      item["variation_price"] = defaultVariation.price;
    });
  }
}

// TODO width of cols
var showBulk;
$: showBulk = false;
function handleBulkUpdateProductWidth() {
  if (window.innerWidth > 1250) {
    showBulk = true;
  }
}

const table_config = {
  name: "Products",
  options: ["EDIT"],
  columns_setting: [
    {
      name: "product_name",
      show: true,
      edit: true,
      width: "150px",
    },
    {
      name: "variation_price",
      show: true,
      edit: true,
      width: "150px",
    },
    {
      name: "description",
      show: true,
      edit: true,
      width: "200px",
    },
    {
      name: "is_pickable",
      show: true,
      edit: true,
      width: "150px",
    },
    {
      name: "is_available",
      show: true,
      edit: true,
      width: "150px",
    },
    {
      name: "is_deliverable",
      show: true,
      edit: true,
      width: "150px",
    },
  ],
};

function saveProducts() {
  // For Category IDS and group IDS and Default variation Price
  items.forEach((item) => {
    item.price = parseFloat(item.price);
    if (!(item.product_id in categoryMapping)) {
      categoryMapping[item.product_id] = [];
    }
    item.categories.forEach((category) => {
      categoryMapping[item.product_id].push(category.category_id);
    });
    if (!(item.product_id in groupMapping)) {
      groupMapping[item.product_id] = [];
    }
    item.addon_groups.forEach((group) => {
      groupMapping[item.product_id].push(group.group_id);
    });
    item.variations.forEach((variation) => {
      if (variation.is_default) {
        variation.price = parseFloat(item.variation_price);
      }
    });
  });
}

function postToBulkUpdateProductApi() {
  let payload = buildUpdatePayload(items);
  axios
    .put(`${baseApiURL}/admin/store/${store_id}/product`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      let successMessage = "Successfully updated.";
      showSuccessMessage(successMessage);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      console.error("PRODUCT_BULK_UPDATE_ERROR", err);
    });
}
</script>

{#if showCreate}
  <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-6xl">
      <div class="relative p-6 flex-auto">
        <CardProductCreate on:data="{addRecentProducts}" store_id="{store_id}" brand_id="{brand_id}" bind:showCreate />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

{#if showUpdate}
  <div class="overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-6xl">
      <div class="relative p-6 flex-auto">
        <CardProductUpdate store_id="{store_id}" product_id="{product_id}" brand_id="{brand_id}" bind:data bind:showUpdate />
      </div>
    </div>
  </div>
  <div class=""></div>
{/if}

{#if showBulkUpload}
  <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-6xl">
      <div class="relative p-6 flex-auto">
        <CardBulkProductUpload on:data="{addRecentProducts}" store_id="{store_id}" brand_id="{brand_id}" bind:showBulkUpload />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-white border-0">
  <Tabs>
    <TabList>
      <Tab>Products</Tab>
      <Tab>Addons</Tab>
      <Tab>Addon Groups</Tab>
      <Tab>Update Products</Tab>
    </TabList>
    <TabPanel>
      <div>
        <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
          <div class="header flex">
            <h6 class="text-gray-800 text-xl font-bold">Products</h6>
            <div class="flex justify-end w-6/12">
              <button class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{() => changeSequence()}"> Save changes </button>
              <button
                class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                on:click="{() => {
                  showCreate = !showCreate;
                }}">
                Add Product
              </button>
              <button
                class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                on:click="{() => {
                  showBulkUpload = !showBulkUpload;
                }}">
                Upload Products
              </button>
            </div>
          </div>
        </div>

        {#if showRecentlyAdded}
          <div class="recentProducts">
            <div class="header p-2">
              <h6 class="text-gray-800 text-xl font-bold">Recently Added products</h6>
              <div>
                <button class="z-10 bg-blue-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{() => postToCreateAPI()}"> Save Products </button>
                <button
                  class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  on:click="{() => {
                    recentlyAddedProducts = [];
                    showRecentlyAdded = !showRecentlyAdded;
                  }}">
                  Close
                </button>
              </div>
            </div>
            <div class="products">
              {#each recentlyAddedProducts as product, index}
                <div in:scale out:fade>
                  <div class="product p-4">
                    <Carousel>
                      {#each product.carousel as carousel}
                        <img class="" src="{carousel}" alt="" />
                      {/each}
                    </Carousel>
                    <div class="p-4">
                      <h2 class="mt-2 mb-2  font-bold">
                        {product.product_name}
                      </h2>
                      <p class="text-sm">
                        {product.description}
                      </p>
                    </div>
                    <div class="p-4  status flex border-t border-b text-xs text-gray-700 justify-evenly">
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Available?</p>
                        {#if product.is_available}
                          <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                        {:else}
                          <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                        {/if}
                      </div>
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Deliverable?</p>
                        {#if product.is_deliverable}
                          <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                        {:else}
                          <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                        {/if}
                      </div>
                      <div class="flex flex-row font-bold items-center m-2">
                        <p>Pickable?</p>
                        {#if product.is_pickable}
                          <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                        {:else}
                          <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                        {/if}
                      </div>
                    </div>
                    <div class="p-4 flex items-center justify-evenly text-sm text-gray-600">
                      <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                        <button on:click="{() => confirmThis(deleteRecentlyProduct, index)}">
                          <i class="fas fa-trash text-red-500"></i>
                        </button>

                        <span slot="title">
                          Are you sure, you want to delete <span class="font-semibold">{product.product_name}</span>?
                        </span>
                        <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                      </Confirm>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        <div use:dndzone="{{ items }}" on:consider="{handleSort}" on:finalize="{handleSort}" class="products">
          {#each items as product, index (product.product_id)}
            <div animate:flip="{{ duration: flipDurationMs }}">
              <div class="product p-4">
                <Carousel class="object-cover">
                  {#each product.carousel as carousel}
                    <img class="h-50 w-full" src="{carousel}" alt="" />
                  {/each}
                </Carousel>
                <div class="p-4">
                  <h2 class="mt-2 mb-2  font-bold">
                    {product.product_name}
                  </h2>
                  <h2 class="mt-2 mb-2  font-bold">
                    {product.price}
                  </h2>
                  <p class="text-sm">
                    {product.description}
                  </p>
                  <div class="flex flex-wrap justify-around m-2">
                    {#each product.variations as variation}
                      <div class="variation p-[5px] rounded ml-[3px] text-center">
                        {variation.title}
                      </div>
                    {/each}
                  </div>
                </div>
                <div class="flex flex-wrap border-t border-b text-xs text-gray-700 justify-evenly">
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Available?</p>
                    {#if product.is_available}
                      <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                    {:else}
                      <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                    {/if}
                  </div>
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Deliverable?</p>
                    {#if product.is_deliverable}
                      <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                    {:else}
                      <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                    {/if}
                  </div>
                  <div class="flex flex-row font-bold items-center m-2">
                    <p>Pickable?</p>
                    {#if product.is_pickable}
                      <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                    {:else}
                      <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                    {/if}
                  </div>
                </div>
                <div class="p-4 flex items-center justify-evenly text-sm text-gray-600">
                  <span class="ml-2">
                    <button
                      class="active:border-0"
                      on:click="{() => {
                        visitUpdate(product.product_id, product, items, index);
                      }}">Update</button>
                  </span>
                  <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                    <button on:click="{() => confirmThis(deleteProduct, index, product.product_id)}">
                      <i class="fas fa-trash text-red-500"></i>
                    </button>

                    <span slot="title">
                      Are you sure, you want to delete <span class="font-semibold">{product.product_name}</span>?
                    </span>
                    <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                  </Confirm>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </TabPanel>
    <TabPanel>
      <StoreAddonsList bind:store_id />
    </TabPanel>
    <TabPanel>
      <StoreAddonsGroupList bind:store_id />
    </TabPanel>
    <TabPanel>
      <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
        <div class="header flex">
          <h6 class="text-gray-800 text-xl font-bold">Update Products</h6>
          <div class="flex justify-end w-6/12">
            <button class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{() => postToBulkUpdateProductApi()}"> Save changes </button>
          </div>
        </div>
        {#if showBulk}
          <div class="mt-2 border-black border-2">
            <SvelteGenericCrudTable table_config="{table_config}" table_data="{items}" on:update="{saveProducts}" />
          </div>
        {:else}
          Not allowed for this screen size
        {/if}
      </div>
    </TabPanel>
  </Tabs>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.products {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  align-items: center;
  justify-content: center;
}
.product {
  background-color: rgb(231, 221, 207);
  border-radius: 20px;
  margin: 20px;
}
.recentProducts {
  box-shadow: 1px 1px 1px 1px gray;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
.variation {
  border: 1px solid rgb(207, 199, 188);
}
@media (max-width: 540px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    margin: 5px;
  }
}
@media (max-width: 1000px) {
  .products {
    display: grid;
    grid-template-columns: 93%;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .products {
    display: grid;
    grid-template-columns: 47% 47%;
  }
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.active\:border-0:active {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-50 {
  height: 12.5rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.m-2 {
  margin: 0.5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-\[3px\] {
  margin-left: 3px;
}
.max-w-6xl {
  max-width: 72rem;
}
.min-w-0 {
  min-width: 0px;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.p-6 {
  padding: 1.5rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-\[5px\] {
  padding: 5px;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-6\/12 {
  width: 50%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
