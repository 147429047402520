<script>
import CardMobileNumber from "./CardMobileNumber.svelte";
import { Confirm } from "svelte-confirm";

export let contactInfoList = [];
export let deletedContactsList = [];

function removeContact(event, index, contact) {
  event.preventDefault();

  // const removedContact = contactInfoList[index];
  contactInfoList.splice(contactInfoList.indexOf(contact), 1);
  deletedContactsList.push(contact);
  contactInfoList = [...contactInfoList];
  // contactInfoList = contactInfoList;
  deletedContactsList = [...deletedContactsList];
  console.log(deletedContactsList, contactInfoList);
}
</script>

{#each contactInfoList as contact, index}
  <div class="wrapper flex w-full flex-row m-4">
    <div class="w-11/12">
      <CardMobileNumber bind:contactInfo="{contact}" />
    </div>
    <div class="w-8">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-delete`}"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
      <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
        <button class="flex-shrink align-text-bottom" on:click|preventDefault="{(event) => confirmThis(removeContact, event, index, contact)}">
          <i class="fas fa-trash text-red-500"></i>
        </button>

        <span slot="title">
          Are you sure, you want to delete <span class="font-semibold">{contact.phone_number}</span>?
        </span>
      </Confirm>
    </div>
  </div>
{/each}

<style windi:inject>
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.w-full {
  width: 100%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-8 {
  width: 2rem;
}
</style>