<script>
import axios from "axios";
import { authToken } from "../../store/auth";
import isEmail from "validator/lib/isEmail";
import Select from "svelte-select";
import { form, bindClass } from "svelte-forms";
import { toast } from "@zerodevx/svelte-toast";

let validationError, isVerifying, managerEmail, userCheck, merchantCheck;
let merchantList = [];
let selectedMerchants = [];

const managerAccessForm = form(
  () => ({
    email: { value: managerEmail, validators: ["required", "email"] },
    merchants: { value: merchantList },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function showErrorMessage() {
  toast.push(validationError, {
    theme: {
      "--toastBackground": "#F56565",
      "--toastProgressBackground": "#C53030",
    },
  });
}

function listAllMerchants() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/merchants/merchant/list`, {
      headers: headers,
    })
    .then((res) => {
      validationError = null;
      isVerifying = false;
      res.data.data.forEach((merchant) => {
        merchantList.push({
          label: merchant.name,
          value: merchant.merchant_id,
          group: "default",
        });
      });
      merchantCheck = true;
      console.log("Merchat list updated", merchantList);
    })
    .catch((err) => {
      const _error = err.response ? err.response.data : err;
      console.log("MERCHANT_LISTING_ERROR", _error);
      isVerifying = false;
    });
}

function verifyUserExistsApi(email, googleRecaptchaToken) {
  validationError = null;
  isVerifying = true;

  let payload = {
    user: {
      email: email,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };

  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .post(`${morrDashboard.env.API_URL}/admin/users/exists`, payload, {
      headers: headers,
    })
    .then((res) => {
      console.log("USER_CHECK", res);
      validationError = null;
      isVerifying = false;
      userCheck = res.data.message === "user exists";
      if (userCheck && merchantList.length === 0) {
        listAllMerchants();
      }
    })
    .catch((err) => {
      const _error = err.response ? err.response.data : err;
      console.log("USER_CHECK_ERROR", _error);
      isVerifying = false;
      userCheck = false;
      if (err.response && err.response.status === 422) {
        validationError = "Unable to verify user email.";
      } else if (err.response && err.response.data) {
        validationError = err.response.data.detail;
      } else {
        validationError = "Ooops. Something went wrong!";
      }
      showErrorMessage();
    });
}

function verifyManagerEmail(email) {
  if (!isEmail(email)) {
    validationError = "Please enter a valid email";
    showErrorMessage();
    return;
  }

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "UserCheck",
      })
      .then(function (token) {
        if (token) {
          verifyUserExistsApi(email, token);
        }
      });
  });
}

function handleSelect(event) {
  selectedMerchants = event.detail || [];
}

function grantMerchantAccessAPI(selectedMerchants, googleRecaptchaToken) {
  validationError = null;
  isVerifying = true;

  let payload = {
    merchant_data: {
      ids: selectedMerchants.map((e) => e.value),
      user_email: managerEmail,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };

  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .post(`${morrDashboard.env.API_URL}/admin/merchants/managers/grant-access`, payload, {
      headers: headers,
    })
    .then((res) => {
      console.log("GRANT_ACCESS", res.data);
      validationError = null;
      isVerifying = false;
      let allowed_ids = res.data.data;

      let access_list = selectedMerchants.filter((merchant) => {
        return allowed_ids.indexOf(merchant.value) !== -1;
      });
      let merchantNames = access_list.map((merchant) => merchant.label).join(", ");

      if (merchantNames) {
        merchantNames = " to " + merchantNames;
      }

      toast.push(`Access granted: ${merchantNames}`, {
        theme: {
          "--toastBackground": "#48BB78",
          "--toastProgressBackground": "#2F855A",
        },
      });

      managerEmail = null;
      selectedMerchants = [];
      document.querySelector(".clearSelect").click();
      document.querySelector(".selectContainer").click();
    })
    .catch((err) => {
      const _error = err.response ? err.response.data : err;
      console.log("GRANT_ACCESS_ERROR", _error);
      isVerifying = false;
      if (err.response && err.response.status === 422) {
        validationError = "Unable to verify user email.";
      } else if (err.response && err.response.data) {
        validationError = err.response.data.detail;
      } else {
        validationError = "Ooops. Something went wrong!";
      }
      showErrorMessage();
    });
}

function grantMerchantAccess() {
  if (selectedMerchants.length < 1) {
    validationError = "Please select one or more merchant outlet";
    showErrorMessage();
    return;
  }

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "merchantAccess",
      })
      .then(function (token) {
        if (token) {
          grantMerchantAccessAPI(selectedMerchants, token);
        }
      });
  });
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full xl:w-6/12 mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Add Manager</h6>
    </div>
  </div>
  <div class="flex-auto lg:px-4 py-10 pt-0">
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase"></h6>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative w-full mb-3">
          <div class="hero bg-gredient-dark h-20 flex flex-col px-0">
            <div class="search-box mx-auto my-auto w-full px-10">
              <div class="flex flex-row">
                <span class="flex items-center bg-gray-100 rounded rounded-r-none border-0 px-3 font-bold text-grey-100">
                  <i class="fas fa-key"></i>
                </span>
                <input class="manager-email h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600" type="email" placeholder="Enter manager's registered email with Morr" bind:value="{managerEmail}" on:change="{() => verifyManagerEmail(managerEmail)}" use:bindClass="{{ form: managerAccessForm }}" />
                {#if isVerifying}
                  <span class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100">
                    <button class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded">
                      <svg class="loading h-5 w-5 mr-3 bg:dark" viewBox="0 0 24 24"></svg>
                    </button>
                  </span>
                {:else}
                  <span class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100" on:click|preventDefault="{() => verifyManagerEmail(managerEmail)}">
                    <button class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded">
                      <i class="fas fa-search"></i>
                    </button>
                  </span>
                {/if}
              </div>

              {#if validationError}
                <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {validationError}
                </p>
              {/if}
            </div>
          </div>
          {#if merchantCheck}
            <div class="hero bg-gredient-dark h-20 flex flex-col px-0 mt-8">
              <div class="w-full">
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{merchantList}" isMulti="{true}" placeholder="Please select one or more merchant outlet" noOptionsMessage="No merchants available" on:select="{handleSelect}" />
              </div>
            </div>

            {#if selectedMerchants.length > 0}
              <div class="flex flex-wrap items-center">
                <div class="w-6/12 px-4 mt-4 mx-auto">
                  <button class=" bg-gray-900 text-white active:bg-gray-100 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" type="submit" on:click|preventDefault="{() => grantMerchantAccess()}"> Grant Access </button>
                </div>
              </div>
            {/if}
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
.loading {
  display: inline-block;
  border: 3px solid rgba(132, 202, 243, 0.3);
  border-radius: 50%;
  border-top-color: rgb(56, 143, 201);
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-5 {
  height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-5 {
  width: 1.25rem;
}
.w-6\/12 {
  width: 50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1280px) {
  .xl\:w-6\/12 {
    width: 50%;
  }
}
</style>
