<script>
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { onMount } from "svelte";
import FilePond, { registerPlugin } from "svelte-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { authToken } from "../../store/auth";
import { navigateTo } from "svelte-router-spa";
import Select from "svelte-select";
import { showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
import ProductVariationMultiple from "./ProductVariations/ProductVariationMultiple.svelte";
import CardFilePondUploader from "./CardFilePondUploader.svelte";

export let data;
export let product_id;
export let store_id;
export let showUpdate;
export let brand_id;
export let variationInfoList = [];
export let deletedVariationIds = [];
let allowMultiple = true;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let baseApiURl = morrDashboard.env.API_URL;
let baseApiURL_V2 = morrDashboard.env.API_V2_URL;

let filepond_input_name = "filepond";
let cover_pond;
let validationError;
let successMessage;
let categories_list = [];
let categories = [];
let selectedCategory = null;
let categoryMapping = {};
let selectedGroupDetail = null;
let groupItems = [];
let groupList = [];
let carousel = [];
let groupMapping = {};

onMount(() => {
  updateProductDetails();
  getGroups();
  listAllCategories();
  if (data.categories.length > 0) {
    selectedCategory = [];
  }
  data.categories.forEach((category) => {
    selectedCategory.push({
      label: category.name,
      value: category.category_id,
    });
  });
  if (data.addon_groups.length > 0) {
    selectedGroupDetail = [];
  }
  data.addon_groups.forEach((group) => {
    selectedGroupDetail.push({
      label: group.name,
      value: group.group_id,
    });
  });
  variationInfoList = data.variations;
});

let productFormFields = {
  product: {
    product_name: "",
    price: 0.0,
    image_urls: [],
    carousel: [],
    description: "",
    is_available: true,
    is_deliverable: true,
    is_pickable: true,
    category_ids: [],
    group_ids: [],
  },
};

let imageUrlContainer;
$: if (imageUrlContainer != productFormFields?.product?.carousel) {
  if (imageUrlContainer) {
    productFormFields.product.carousel.push(imageUrlContainer.file_url);
  }
}

function updateProductDetails() {
  productFormFields.product.product_name = data.product_name ? data.product_name : "";
  productFormFields.product.price = data.price ? data.price : productFormFields.product.price;
  productFormFields.product.description = data.description.length === 0 ? productFormFields.product.description : data.description;
  productFormFields.product.image_urls = data.image_url?.length === 0 ? productFormFields.product.image_urls : [data.image_url];
  productFormFields.product.carousel = data.carousel?.length === 0 ? productFormFields.product.carousel : data.carousel;

  productFormFields.product.is_available = data.is_available;

  productFormFields.product.is_deliverable = data.is_deliverable;

  productFormFields.product.is_pickable = data.is_pickable;
}

let product_images = [data.image_url]?.map((url) => ({
  source: url,
  options: { type: "local" },
}));

const updateProductForm = form(
  () => ({
    product_name: {
      value: productFormFields.product.product_name,
      validators: ["required"],
    },
    description: {
      value: productFormFields.product.description,
      validators: ["required"],
    },
    image_urls: {
      value: productFormFields.product.image_urls,
      validators: ["required"],
    },
    carousel: {
      value: productFormFields.product.carousel,
      validators: ["required"],
    },
    is_deliverable: {
      value: productFormFields.product.is_deliverable,
      validators: ["required"],
    },
    is_pickable: {
      value: productFormFields.product.is_pickable,
      validators: ["required"],
    },
    is_available: {
      value: productFormFields.product.is_available,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function getProductName() {
  return productFormFields.product.product_name;
}

function getProductPrice() {
  return productFormFields.product.price;
}

function getUploadImageUrls() {
  return productFormFields.product.image_urls;
}

function getDescription() {
  return productFormFields.product.description;
}

function buildFormPayload() {
  return [
    {
      is_deliverable: productFormFields.product.is_deliverable,
      is_pickable: productFormFields.product.is_pickable,
      is_available: productFormFields.product.is_available,
      product_name: productFormFields.product.product_name,
      price: productFormFields.product.price,
      image_url: getUploadImageUrls()[0],
      carousel: productFormFields.product.carousel,
      description: getDescription(),
      category_ids: productFormFields.product.category_ids,
      product_id: product_id,
      next_product_id: data.next_product_id,
      group_ids: [...new Set(productFormFields.product.group_ids)],
      variations: variationInfoList,
      deleted_variations: deletedVariationIds,
    },
  ];
}

function postToUpdateProductApi(payload) {
  axios
    .put(`${baseApiURl}/admin/store/${store_id}/product`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      data = response.data.data[0];
      let tempCategories = data.category_ids.map((category) => categoryMapping[category]);
      data.categories = tempCategories;
      delete data.category_ids;
      let tempAddonGroups = data.group_ids.map((group) => groupMapping[group]);
      data.addon_groups = tempAddonGroups;
      delete data.group_ids;
      data.variations = data.variations;
      successMessage = "Successfully updated.";
      showSuccessMessage(successMessage);
      showUpdate = !showUpdate;
      if (brand_id) {
        navigateTo(`/merchants/store/product/list/brand/${brand_id}/${store_id}`);
      } else {
        navigateTo(`/merchants/store/product/list/${store_id}`);
      }
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      console.error("PRODUCT_UPDATE_ERROR", err);
    });
}

function updateProduct() {
  updateProductForm.validate();
  if ($updateProductForm.valid) {
    let payload = buildFormPayload();
    postToUpdateProductApi(payload);
  } else {
    for (const key in $updateProductForm.fields) {
      let field = $updateProductForm.fields[key];
      if (!field.valid) {
        parseAndShowErrorMessage(`${key.replace(/[^a-zA-Z ]/g, " ")} is ${field.errors.join(", ")}`);
      }
    }
  }
}

function getFilePondServerConfig(image_for, image_type) {
  return {
    url: `${baseApiURl}/admin/images`,
    load: async (source, load, error, progress, abort, headers) => {
      fetch(source, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          Origin: window.location.origin,
        },
      })
        .then(function (response) {
          response.blob().then(function (imageBlob) {
            load(imageBlob);
          });
        })
        .catch(function (_error) {
          error(_error);
        });
    },
    process: {
      url: "/upload",
      method: "POST",
      withCredentials: false,
      headers: {
        "X-Auth-Token": $authToken,
      },
      timeout: 7000,
      ondata: (formdata) => {
        formdata.append("image_for", image_for);
        formdata.append("image_type", image_type);
        return formdata;
      },
      onload: (response) => {
        response = JSON.parse(response);
        let file_url = response.data.file_url;
        productFormFields.product.image_urls[0] = file_url;
        productFormFields.product.carousel.push(file_url);
        return file_url;
      },
      onerror: (response) => {
        let err = JSON.parse(response);
        parseAndShowErrorMessage(err);
        return err.detail;
      },
    },
    revert: (uniqueFileId, load, error) => {
      axios
        .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
          headers: {
            "X-Auth-Token": $authToken,
          },
        })
        .then((res) => {
          let elementIndex = productFormFields.product.image_urls.indexOf(uniqueFileId);
          productFormFields.product.image_urls.splice(elementIndex, 1);
          let carouselElementIndex = productFormFields.product.carousel.indexOf(uniqueFileId);
          productFormFields.product.carousel.splice(carouselElementIndex, 1);
          load();
        })
        .catch((err) => {
          parseAndShowErrorMessage(err);
          console.error("FAILED_TO_REMOVE_IMAGE", err);
          error(err);
        });
    },
  };
}

function listAllCategories() {
  axios
    .get(`${baseApiURl}/admin/store/category?store_id=${store_id}`, {
      headers: {
        "X-Auth-token": $authToken,
      },
    })
    .then((res) => {
      categories = res.data.data;
      categories.forEach((category) => {
        category.merchant_name = null;
        categoryMapping[category.category_id] = category;
        if (category.store_id === store_id) {
          categories_list.push({
            label: category.name,
            value: category.category_id,
          });
        }
      });
      categories_list = categories_list;
    })
    .catch((err) => {
      const _error = (err.hasOwnProperty("detail") && err.detail) || err;
      console.log("CATEGORY_LISTING_ERROR", _error);
    });
}
function getGroups() {
  axios
    .get(`${baseApiURL_V2}/admin/store/${store_id}/addons/groups/`, {
      headers: {
        "X-auth-token": $authToken,
      },
    })
    .then((response) => {
      groupItems = response.data.data;
      groupItems.forEach((group) => {
        groupMapping[group.group_id] = group;
        if (group.store_id === store_id) {
          groupList.push({ label: group.name, value: group.group_id });
        }
      });
      groupList = groupList; //Due to svelte render
    })
    .catch((err) => {
      console.log("GROUP_LISTING_ERROR", err);
      // parseAndShowErrorMessage(err);
    });
}

function handleGroups(e) {
  selectedGroupDetail = e.detail || [];
  productFormFields.product.group_ids = [];
  selectedGroupDetail.forEach((selectedGroup) => {
    productFormFields.product.group_ids.push(selectedGroup.value);
  });
}

function resetGroups(e) {
  productFormFields[0].group_ids = [];
}

function handleCategorySelection(event) {
  selectedCategory = event.detail || [];
  productFormFields.product.category_ids = [];
  selectedCategory.forEach((selected) => {
    productFormFields.product.category_ids.push(selected.value);
  });
}
</script>

<div class="relative overflow-y-auto h-fit flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0 update-product-modal">
  <div class="flex-auto px-4 lg:px-4 py-4 pt-0">
    <form>
      <div class="flex justify-between">
        <h6 class="text-gray-500 text-sm mt-3 mb-3 font-bold uppercase">Update Product Information</h6>
      </div>
      <div class="flex flex-col md:flex-row justify-between">
        <div class=" w-full md:w-7/12">
          <div class="px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="product-name"> Product Name </label>
              <input id="product-name" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your product Name" bind:value="{productFormFields.product.product_name}" use:bindClass="{{ form: updateProductForm, name: 'product_name' }}" />
            </div>
          </div>
          <div class="px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="description"> Description </label>
              <textarea id="description" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="please enter description" bind:value="{productFormFields.product.description}" use:bindClass="{{ form: updateProductForm, name: 'description' }}"></textarea>
            </div>
          </div>
        </div>
        <div class="w-full md:w-4/12">
          <div class="flex text-center">
            <div class="px-1">
              <div class="relative w-full mb-3">
                <label class="uppercase text-gray-700 text-xs font-bold mb-2" for="available"> Available? </label>

                <input type="checkbox" id="available" class="form-checkbox text-gray-800 ml-1 w-10 h-10 ease-linear transition-all duration-150" placeholder="Is Available" bind:checked="{productFormFields.product.is_available}" />
              </div>
            </div>
            <div class="px-1">
              <div class="relative w-full mb-3 text-center">
                <label class="uppercase text-gray-700 text-xs font-bold mb-2" for="delivering"> Deliverable? </label>

                <input type="checkbox" id="delivering" class="form-checkbox text-gray-800 ml-1  w-10 h-10 ease-linear transition-all duration-150" placeholder="Is delivering" bind:checked="{productFormFields.product.is_deliverable}" />
              </div>
            </div>
            <div class="px-1">
              <div class="relative w-full mb-3">
                <label class="uppercase text-gray-700 text-xs font-bold mb-2" for="pickable"> Pickable? </label>

                <input type="checkbox" id="pickable" class="form-checkbox text-gray-800 ml-1 w-10 h-10 ease-linear transition-all duration-150" placeholder="Is delivering" bind:checked="{productFormFields.product.is_pickable}" />
              </div>
            </div>
          </div>
          <div class="px-1">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="categories"> Categories </label>
              <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600' }}" bind:value="{selectedCategory}" inputStyles="font-size: 1.125rem;" items="{categories_list}" isMulti="{true}" placeholder="Please select one or more categories" noOptionsMessage="No Categories available" on:select="{handleCategorySelection}" />
            </div>
          </div>
          <div class="px-1">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="groups"> Addon Groups </label>
              <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{groupList}" value="{selectedGroupDetail}" isMulti="{true}" placeholder="Please select one or more groups" noOptionsMessage="No Groups available" on:select="{handleGroups}" on:reset="{resetGroups}" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full image_multi">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="images"> Images </label>
        <CardFilePondUploader bind:imageUrlContainer image_for="products" image_type="product" bind:existingFiles="{data.carousel}" bind:allowMultiple />
      </div>

      <div class="flex flex-col w-full">
        {#if variationInfoList}
          <ProductVariationMultiple bind:variationInfoList bind:deletedVariationIds />
        {/if}
      </div>
      <div class="px-2">
        <div class="relative flex items-center justify-center w-full m-4">
          <button
            class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault="{() => {
              updateProduct();
            }}">
            Save
          </button>
          <button
            class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
            on:click|preventDefault="{() => {
              showUpdate = !showUpdate;
            }}">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}
@media screen and (max-width: 480px) {
  .update-product-modal {
    height: auto;
    min-height: 300px;
    max-height: 400px;
  }
}
@media screen and (max-width: 720px) {
  .update-product-modal {
    height: auto;
    min-height: 460px;
    max-height: 600px;
  }
}
@media screen and (min-width: 1080px) {
  .update-product-modal {
    height: auto;
    min-height: 560px;
    max-height: 600px;
  }
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.h-10 {
  height: 2.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-10 {
  width: 2.5rem;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 768px) {
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:w-4\/12 {
    width: 33.333333%;
  }
}
@media (min-width: 1024px) {
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
