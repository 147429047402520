<script>
import CardOrdersDatatable from "components/Cards/Orders/CardOrdersDatatable.svelte";
import { onMount } from "svelte";

export let currentRoute;
let merchant_id;

onMount(() => {
  merchant_id = currentRoute.namedParams.merchant_id;
});
</script>

<div>
  <div class="flex flex-wrap">
    <div class="w-full">
      {#if merchant_id}
        <CardOrdersDatatable merchant_id="{merchant_id}" />
      {/if}
    </div>
  </div>
</div>

<style windi:inject>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>