<script>
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import { showSuccessMessage } from "../../../utils/toast";
import StoreAddonGroupCrud from "./StoreAddonGroupCrud.svelte";
import { recaptchaWrapper } from "../../../utils/google-captcha";

export let store_id;

let baseApiURL = morrDashboard.env.API_V2_URL;
let color = "light";
let groups = [];
let showGroups;
let showModal;
let groupsCrudCallback = () => {};
function getGroups() {
  axios
    .get(`${baseApiURL}/admin/store/${store_id}/addons/groups/`, {
      headers: {
        "X-auth-token": $authToken,
      },
    })
    .then((response) => {
      groups = response.data.data;
      showGroups = true;
    })
    .catch((err) => {
      console.log(err);
      // parseAndShowErrorMessage(err);
    });
}

function addGroup(payload) {
  recaptchaWrapper("StoreAddonGroupCreate", postToAddGroup, [payload]);
}
function postToAddGroup(token, payload) {
  if (!payload.name) {
    showErrorMessage("Group name can't be empty");
  } else {
    axios
      .post(`${baseApiURL}/admin/store/${store_id}/addons/group`, payload, {
        headers: {
          "X-auth-token": $authToken,
          "x-google-token": token,
        },
      })
      .then((response) => {
        showSuccessMessage("New Group Created");
        showModal = !showModal;
        groups = [...groups, response.data.data];
      })
      .catch((err) => {
        parseAndShowErrorMessage(err);
      });
  }
}

onMount(() => {
  getGroups();
});
</script>

{#if showModal}
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <StoreAddonGroupCrud bind:showModal bind:callback="{groupsCrudCallback}" />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
    <div class="header flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Addon Groups</h6>
      <div class="flex justify-end">
        <button
          class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click="{() => {
            showModal = true;
            groupsCrudCallback = addGroup;
          }}">
          Add Group
        </button>
      </div>
    </div>
  </div>
  {#if showGroups}
    {#each groups as group}
      <h6 class="text-gray-800 text-xl font-bold m-4 py-2 px-4" id="{group}">
        {group.name}
      </h6>
    {/each}
  {:else}
    <div class="block w-full overflow-x-auto m-4">
      <div>{"No groups found"}</div>
    </div>
  {/if}
</div>

<style windi:inject>
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border-b {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-1 {
  z-index: 1;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
