<script>
import { onMount } from "svelte";
import axios from "axios";
import { authToken } from "../../../store/auth";

export let order_id;
export let delivery_partner;

let baseApiURL = morrDashboard.env.API_URL;
let riderInfo;

function getRiderDetails() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${baseApiURL}/admin/delivery-partners/${order_id}`, {
      headers: headers,
    })
    .then((res) => {
      riderInfo = res.data.data;
    })
    .catch((err) => {
      console.error("Get Delivery Details Error", err);
    });
}

onMount(() => {
  getRiderDetails();
});
</script>

{#if riderInfo}
  <div class="items-container">
    <div class="rider text-sm leading-relaxed">
      <div class="flex justify-around px-4">
        <div class="title text-left w-6/12 font-normal">Status</div>
        <div class="value text-right  w-6/12">
          <span class="text-slate-500 font-semibold">
            {riderInfo.current_status}
          </span>
        </div>
      </div>

      <div class="flex justify-around px-4">
        <div class="title text-left w-6/12 font-normal">Rider Name</div>
        <div class="value text-right  w-6/12">
          <span class="text-slate-500 font-semibold">
            {riderInfo?.rider_info?.name}
          </span>
        </div>
      </div>

      <div class="flex justify-around px-4">
        <div class="title text-left w-6/12 font-normal">Rider Phone</div>
        <div class="value text-right  w-6/12">
          <span class="text-slate-500 font-semibold">
            {riderInfo?.rider_info?.phone}
          </span>
        </div>
      </div>
      <div class="flex justify-around px-4">
        <div class="title text-left w-6/12 font-normal">Vehicle Plate Number</div>
        <div class="value text-right  w-6/12">
          <span class="text-slate-500 font-semibold">
            {riderInfo?.rider_info?.plateNumber}
          </span>
        </div>
      </div>
      <div class="flex justify-around px-4">
        <div class="title text-left w-6/12 font-normal">Service Provider</div>
        <div class="value text-right  w-6/12">
          <span class="text-slate-500 font-semibold uppercase">
            {delivery_partner}
          </span>
        </div>
      </div>
    </div>
  </div>
{:else}
  <div class="text-center">
    <i class="fas fa-user-clock"></i> awaiting delivery details
  </div>
{/if}

<style windi:inject>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.leading-relaxed {
  line-height: 1.625;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.w-6\/12 {
  width: 50%;
}
</style>