<script>
import axios from "axios";

import FilePond, { registerPlugin } from "svelte-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { authToken } from "../../../store/auth";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let filepond_input_name = "filepond";
let banners_pond;
let cover_pond;
let profile_pond;
let portrait_pond;
let landscape_pond;

export let exported_merchant_id;
export let showComponent;
export let showPrevComponent;

let merchantFormFields = {
  images: {
    profile: [],
    cover: [],
    banners: [],
    portrait: [],
    landscape: [],
  },
};

function getFilePondServerConfig(image_for, image_type) {
  return {
    url: `${morrDashboard.env.API_URL}/admin/images`,
    process: {
      url: "/upload",
      method: "POST",
      withCredentials: false,
      headers: {
        "X-Auth-Token": $authToken,
      },
      timeout: 7000,
      ondata: (formdata) => {
        formdata.append("image_for", image_for);
        formdata.append("image_type", image_type);
        return formdata;
      },
      onload: (response) => {
        console.log(typeof response);
        response = JSON.parse(response);
        let image_url = response.data.file_url;
        let image_id = response.data.image_id;
        merchantFormFields.images[image_type].push(image_url);
        return image_id;
      },
      onerror: (response) => {
        let err = JSON.parse(response);
        validationError = (err.hasOwnProperty("detail") && err.detail) || err;
        showErrorMessage();
        return err.detail;
      },
    },
    revert: (uniqueFileId, load, error) => {
      axios
        .delete(`${morrDashboard.env.API_URL}/admin/images/${uniqueFileId}`, {
          headers: {
            "X-Auth-Token": $authToken,
          },
        })
        .then((res) => {
          console.log("Images before", merchantFormFields.images[image_type]);
          let elementIndex = merchantFormFields.images[image_type].indexOf(uniqueFileId);
          merchantFormFields.images[image_type].splice(elementIndex, 1);
          console.log("Images after", merchantFormFields.images[image_type]);
          load();
        })
        .catch((err) => {
          parseAndShowErrorMessage(err);
        });
    },
  };
}

function performAddImage() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "MerchantImage",
      })
      .then(function (token) {
        if (token) {
          postToCreateImageApi(token);
        }
      });
  });
}
function getUploadImageUrls() {
  merchantFormFields.images.banners = merchantFormFields.images.banner;
  return merchantFormFields.images;
}

function postToCreateImageApi(token) {
  let payload = {
    images: merchantFormFields.images,
  };
  axios
    .post(`${morrDashboard.env.API_URL}/admin/merchants/onboarding/${exported_merchant_id}/images`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Images Added.");
      showComponent = "Final";
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function handleSave() {
  performAddImage();
}

function handlePrev() {
  showPrevComponent = "Discount";
}
</script>

<div class="main">
  <h6 class="title text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Upload Images</h6>

  <div class="w-full image_full">
    <small class="font-bold">Upload Cover Photo</small>
    <FilePond required="{true}" bind:this="{cover_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'cover')}" allowMultiple="{true}" />
  </div>
  <div class="w-full image_full">
    <small class="font-bold">Upload Profile Photo</small>
    <FilePond required="{true}" bind:this="{profile_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'profile')}" allowMultiple="{true}" />
  </div>

  <div class="w-full image_multi">
    <small class="font-bold">Upload Carousel Image</small>
    <FilePond required="{true}" bind:this="{banners_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'banner')}" allowMultiple="{true}" />
  </div>

  <div class="w-full image_multi">
    <small class="font-bold">Upload Recent Photos</small>
    <FilePond required="{true}" bind:this="{portrait_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'portrait')}" allowMultiple="{true}" />
  </div>
</div>

<div class="next-btn">
  <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handlePrev}"> Prev </button>
  <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click="{handleSave}"> Save </button>
</div>

<style windi:inject>
.title {
  justify-content: center;
  text-align: center;
  font-size: larger;
}
.next-btn {
  justify-content: center;
  text-align: center;
  margin: 20px auto;
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.font-bold {
  font-weight: 700;
}
.h-8 {
  height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
