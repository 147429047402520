<script>
import { form, bindClass } from "svelte-forms";
import Select from "svelte-select";
import { onMount } from "svelte";
import { showErrorMessage } from "../../../utils/toast";
import Toggle from "svelte-toggle";
import CardFilePondUploader from "../CardFilePondUploader.svelte";
import { linkType } from "../../../constants/linkType";

export let showModal;
export let operationType;
export let promoBannerData;
export let callback;

let renderTemplate = false;

let imageUrlContainer;
$: if (imageUrlContainer != promoBannerFormField?.promoBanner?.thumbnail) {
  if (imageUrlContainer) {
    promoBannerFormField.promoBanner.thumbnail = imageUrlContainer.file_url;
    promoBannerFormField.promoBanner.files = [imageUrlContainer.file_url];
  }
}

let validationError;

let promoBannerFormField = {
  promoBanner: {
    title: "",
    description: "",
    thumbnail: "",
    order: 0,
    is_listed: true,
    link_type: "",
    link_destination: "",
    active_tab: "",
    files: [],
  },
};

const promoBannerForm = form(
  () => ({
    title: {
      value: promoBannerFormField.promoBanner.title,
      validators: ["required"],
    },
    order: {
      value: promoBannerFormField.promoBanner.order,
      validators: ["required"],
    },
    is_listed: {
      value: promoBannerFormField.promoBanner.is_listed,
      validators: ["required"],
    },
    description: {
      value: promoBannerFormField.promoBanner.description,
    },
    thumbnail: {
      value: promoBannerFormField.promoBanner.thumbnail,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function buildFormPayload() {
  return {
    title: promoBannerFormField.promoBanner.title,
    order: promoBannerFormField.promoBanner.order,
    is_listed: promoBannerFormField.promoBanner.is_listed,
    description: promoBannerFormField.promoBanner.description,
    thumbnail: promoBannerFormField.promoBanner.thumbnail,
    link_type: promoBannerFormField.promoBanner.link_type,
    link_destination: promoBannerFormField.promoBanner.link_destination,
    active_tab: promoBannerFormField.promoBanner.active_tab,
  };
}

function validatePromoBanner() {
  validationError = null;
  if (!promoBannerFormField.promoBanner.title) {
    validationError = "Title is required";
  } else if (promoBannerFormField.promoBanner.order <= 0) {
    validationError = "Order must be greater than 0";
  } else if (!promoBannerFormField.promoBanner.thumbnail) {
    validationError = "Thumbnail is required";
  }
  if (promoBannerFormField.promoBanner.link_destination) {
    if (!promoBannerFormField.promoBanner.link_type) {
      validationError = "Link type is required";
    }
  }
  if (validationError) {
    showErrorMessage(validationError);
    return;
  }
  let payload = buildFormPayload();
  callback(payload);
}

function handleLinkType(e) {
  promoBannerFormField.promoBanner.link_type = e.detail.value;
}

function resetLinkType(e) {
  promoBannerFormField.promoBanner.link_type = "";
}

let selectedLinkType;
function getLinkType() {
  let links = linkType.filter((linkType) => {
    if (linkType.value === promoBannerFormField.promoBanner.link_type) {
      return linkType;
    }
  });
  console.log("selectedLinkType", links);
  if (links.length > 0) {
    selectedLinkType = links[0];
  }
}

onMount(() => {
  if (promoBannerData) {
    promoBannerFormField.promoBanner = promoBannerData;
    promoBannerFormField.promoBanner.files = [promoBannerFormField.promoBanner.thumbnail];
    getLinkType();
  }
  renderTemplate = true;
});
</script>

{#if renderTemplate}
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0">
    <div class="second flex-auto px-4 lg:px-4 py-4 pt-0">
      <form>
        <div class="flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            {operationType === "Create" ? "Create" : "Update"} Promo Banner
          </h6>
          <div></div>
        </div>
        <div>
          <div class="w-full">
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="thumbnail"> Thumbnail </label>
                <CardFilePondUploader bind:imageUrlContainer image_for="promoBanners" image_type="cover" bind:existingFiles="{promoBannerFormField.promoBanner.files}" />
              </div>
            </div>
            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-8/12">
                <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="title"> Title </label>
                <input id="title" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter promo-banner title" bind:value="{promoBannerFormField.promoBanner.title}" use:bindClass="{{ form: promoBannerForm, name: 'title' }}" />
              </div>

              <div class="px-4 w-full md:w-2/12">
                <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="order"> Order </label>
                <input id="order" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter sequence number" bind:value="{promoBannerFormField.promoBanner.order}" use:bindClass="{{ form: promoBannerForm, name: 'order' }}" />
              </div>

              <div class="px-4 items-center">
                <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="is-listed"> Status </label>
                <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{promoBannerFormField.promoBanner.is_listed}" />
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="description"> Description </label>
                <textarea id="description" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your banner description" bind:value="{promoBannerFormField.promoBanner.description}" use:bindClass="{{ form: promoBannerForm, name: 'description' }}"></textarea>
              </div>
            </div>

            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-full">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="link-type"> Link Type </label>
                  <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" items="{linkType}" isMulti="{false}" placeholder="Please select Link Type" noOptionsMessage="No link type available" bind:value="{selectedLinkType}" on:select="{handleLinkType}" on:clear="{resetLinkType}" />
                </div>
              </div>
            </div>
            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-full md:w-6/12">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="link-destination"> Link Destination </label>
                  <input id="link_destination" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter link destination" bind:value="{promoBannerFormField.promoBanner.link_destination}" />
                </div>
              </div>

              <div class="px-4 w-full md:w-6/12">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold my-2" for="active-tab"> Active Tab </label>
                  <input id="active-tab" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter active tab" bind:value="{promoBannerFormField.promoBanner.active_tab}" />
                </div>
              </div>
            </div>
            <div class="px-4">
              <div class="flex items-center justify-center w-full m-4 justify-items-center ">
                <button
                  class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="submit"
                  on:click|preventDefault="{() => {
                    validatePromoBanner();
                  }}">
                  {operationType === "Create" ? "Save" : "Update"}
                </button>
                <button
                  class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="submit"
                  on:click|preventDefault="{() => {
                    showModal = !showModal;
                  }}">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
{/if}

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-items-center {
  justify-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 768px) {
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:w-8\/12 {
    width: 66.666667%;
  }
  .md\:w-2\/12 {
    width: 16.666667%;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
