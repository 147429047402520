<script>
// core components
import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
</script>

<nav class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
  <div class="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
    <a class="text-dark text-sm uppercase hidden lg:inline-block font-semibold" href="/" on:click="{(e) => e.preventDefault()}"></a>

    <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
      <UserDropdown />
    </ul>
  </div>
</nav>

<style windi:inject>
.bg-transparent {
  background-color: transparent;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-semibold {
  font-weight: 600;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.list-none {
  list-style-type: none;
}
.p-4 {
  padding: 1rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.z-10 {
  z-index: 10;
}
@media (min-width: 768px) {
  .md\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:inline-block {
    display: inline-block;
  }
}
</style>