<script>
import { onMount } from "svelte";
import axios from "axios";

import { authToken } from "../../../store/auth";
import { showSuccessMessage } from "../../../utils/toast";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

export let address;
export let merchant_id;
export let callbackfn;

let merchantMapContainerRef;
let merchantMap;
let merchantMarker;

let place;

let autocomplete;

let googleAddressComponents = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "long_name",
  postal_code: "short_name",
};

let addressFormFields = {
  locality: address ? address.city : " ",
  country: address ? address.country : " ",
  postal_code: address ? address.postal_code : " ",
  streetAddress: address ? address.address : " ",
  latitude: address?.latitude,
  longitude: address?.longitude,
  google_map_link: address.google_map_link,
};

let geolocation = {
  lat: address?.latitude || 0,
  lng: address?.longitude || 0,
};

let googleInput = address ? address.address : "";

$: if (geolocation && merchantMarker) {
  let newLatLng = new google.maps.LatLng(geolocation.lat, geolocation.lng);
  merchantMarker.setPosition(newLatLng);
  recenterMap(merchantMap, merchantMarker);
}

function postToUpdateAddressAPI(payload) {
  axios
    .put(`${morrDashboard.env.API_URL}/admin/merchants/update/${merchant_id}/address`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      showSuccessMessage("Address updated successfully");
      if (callbackfn) {
        callbackfn();
      }
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      throw err;
    });
}

export function handleUpdate() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "AddressUpdate",
      })
      .then(function (token) {
        if (token) {
          let payload = {
            updated_address: {
              address: addressFormFields.streetAddress,
              city: addressFormFields.locality,
              country: addressFormFields.country,
              longitude: geolocation?.lng,
              latitude: geolocation?.lat,
              google_map_link: addressFormFields.google_map_link,
              postal_code: addressFormFields.postal_code,
            },
            captcha: {
              captcha_token: token,
            },
          };

          postToUpdateAddressAPI(payload);
        }
      });
  });
}

function initAutocomplete() {
  if (!(address?.latitude || address?.longitude)) {
    geolocate();
  }

  setupGoogleMap();
  autocomplete = new google.maps.places.Autocomplete(googleInput, {
    types: ["establishment"],
  });
  autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
  place = autocomplete.getPlace();
  addressFormFields.streetAddress = `${place.name}, ${place.formatted_address}`;
  addressFormFields.google_map_link = place.url;
  console.log("Place details", place);
  let latitude = place?.geometry?.location?.lat();
  let longitude = place?.geometry?.location?.lng();
  if (latitude && longitude) {
    geolocation = {
      lat: latitude,
      lng: longitude,
    };
  }

  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0];
    if (addressFormFields.hasOwnProperty(addressType)) {
      let val = place.address_components[i][googleAddressComponents[addressType]];
      addressFormFields[addressType] = val;
    }
  }
}

function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      let latitude = position?.coords?.latitude;
      let longitude = position?.coords?.longitude;
      if (latitude && longitude) {
        geolocation = {
          lat: latitude,
          lng: longitude,
        };
      }

      console.log("found geo", geolocation);

      let circle = new google.maps.Circle({
        center: geolocation,
        radius: position?.coords?.accuracy,
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

function recenterMap(map, marker) {
  map.setCenter(marker.position);
  marker.setMap(map);
  map.setZoom(15);
}

function setupGoogleMap() {
  merchantMap = new google.maps.Map(document.getElementById("merchantMapContainer"), {
    zoom: 1,
    center: new google.maps.LatLng(geolocation.lat, geolocation.lng, 9),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  });

  merchantMarker = new google.maps.Marker({
    position: new google.maps.LatLng(geolocation.lat, geolocation.lng),
    draggable: true,
  });

  google.maps.event.addListener(merchantMarker, "dragend", function (evt) {
    let latitude = evt.latLng?.lat();
    let longitude = evt.latLng?.lng();

    if (latitude && longitude) {
      geolocation = {
        lat: latitude,
        lng: longitude,
      };
    }
  });

  merchantMap.setCenter(merchantMarker.position);
  merchantMarker.setMap(merchantMap);
}

onMount(() => {
  if (!window.hasOwnProperty("google")) {
    setTimeout(function () {
      initAutocomplete();
    }, 1000);
  } else {
    initAutocomplete();
  }
});
</script>

<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
  <form>
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Address Information</h6>
    <div class="flex flex-wrap">
      <div class="w-full h-96 px-4" id="merchantMapContainer" bind:this="{merchantMapContainerRef}"></div>

      <div class="w-full lg:w-full px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-text"> Address Search </label>
          <input type="text" class="form-control px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" id="search_input" bind:this="{googleInput}" placeholder="Please start typing your store address...; e.g: Velvet Vintage, Kuala Lumpur" />
        </div>
      </div>
      <div class="w-full lg:w-full px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="street-address"> Street Address </label>
          <input id="street-address" type="street" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Street Address" bind:value="{addressFormFields.streetAddress}" />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-city"> City </label>
          <input id="address-city" type="city" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="City where Merchant is located" bind:value="{addressFormFields.locality}" />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-country"> Country </label>
          <input id="address-country" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Country where Merchant is located" bind:value="{addressFormFields.country}" />
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-postal-code"> Postal Code </label>
          <input id="address-postal-code" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Postal code" bind:value="{addressFormFields.postal_code}" />
        </div>
      </div>

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-postal-code"> Google Map link </label>
          <input id="address-postal-code" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Google Map link" bind:value="{addressFormFields.google_map_link}" />
        </div>
      </div>

      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-postal-code"> Latitude </label>
          <input id="address-postal-code" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Postal code" bind:value="{geolocation.lat}" />
        </div>
      </div>

      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="address-postal-code"> Longitude </label>
          <input id="address-postal-code" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Postal code" bind:value="{geolocation.lng}" />
        </div>
      </div>
    </div>
  </form>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-96 {
  height: 24rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pt-0 {
  padding-top: 0px;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
  .lg\:w-3\/12 {
    width: 25%;
  }
}
</style>
