<script>
import { options } from "./stores/options.js";
import { columns } from "./stores/columns.js";
</script>

<tr>
  {#each $columns as column}
    {#if column.key}
      <th class="filter" class:css="{$options.css}">
        <input type="text" placeholder="{$options.labels.filter}" class="browser-default" on:input="{(e) => columns.filter(column.key, e.target.value)}" />
      </th>
    {:else}
      <th class="filter"></th>
    {/if}
  {/each}
</tr>

<style windi:inject>
.css {
  padding: 0;
  margin: 0;
  background-image: none;
  border: 1px solid #fafafa;
}
th.filter input {
  margin: 0;
  padding: 0;
  width: 100%;
}
.css input {
  background: #fff;
  height: 24px;
  border: none;
  border-bottom: 1px solid #eee;
  text-align: center;
  outline: none;
  border-radius: 0;
  font-size: 14px;
}
.css input::placeholder {
  color: #bdbdbd;
  font-style: italic;
  font-size: 13px;
}
[placeholder~="$options.labels.filter}"]::-webkit-input-placeholder {
  color: var(--options);
}
[placeholder~="$options.labels.filter}"]::-moz-placeholder {
  color: var(--options);
}
[placeholder~="$options.labels.filter}"]:-ms-input-placeholder {
  color: var(--options);
}
[placeholder~="$options.labels.filter}"]::-ms-input-placeholder {
  color: var(--options);
}
[placeholder~="$options.labels.filter}"]::placeholder {
  color: var(--options);
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
</style>
