<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../store/auth";
import { onMount } from "svelte";
import { Confirm } from "svelte-confirm";
import { Navigate } from "svelte-router-spa";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
import Pagination from "@fouita/pagination";

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
};
const limit = 10;

let current = 1;
let skip = 0;
let totalItems = 0;
let allBrands = [];
let showBrands = false;
let noBrands = "loading Brands ...";

function getBrandListApi(skip) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios

    .get(`${morrDashboard.env.API_URL}/admin/brand/?skip=${skip}&limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("ALL_BRANDS_LISTING", res);
      allBrands = res.data.data;
      totalItems = res.data.paging.total;
      showBrands = true;
    })
    .catch((err) => {
      console.error("ALL_BRANDS_LISTING_ERROR", err);
      showBrands = false;
      noBrands = "Brands not found";
      parseAndShowErrorMessage(err);
    });
}

function paginate(e) {
  if (current === 1) {
    getBrandListApi(0);
  } else if (current > 1) {
    skip = limit * (current - 1);
    getBrandListApi(skip);
  }
}

onMount(() => {
  getBrandListApi(0);
});

let color = "light";
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Brands</h6>
    </div>
  </div>
  <div class="brand-table block w-full overflow-x-auto">
    {#if showBrands}
      <Datatable settings="{settings}" data="{allBrands}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="name"> Brand Name </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="name"> Product Template </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center flex flex-col items-center align-middle font-thin">
                <span class="flex flex-row">
                  <span class="ml-3 font-bold {color === 'light' ? 'btext-gray-700' : 'text-whit'}">
                    {row.name}
                  </span>
                </span>
              </td>
              <td>
                <span class="ml-3 font-bold {color === 'light' ? 'btext-gray-700' : 'text-whit'}">
                  <Navigate to="{`/merchants/brands/product/template/list/${row.id}`}" styles="text-xs uppercase  font-bold block text-center">
                    <i class="fas fa-folder-open text-blue-1000"></i>
                  </Navigate>
                </span>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination small bind:current per_page="{limit}" num_items="{totalItems}" on:navigate="{paginate}" />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noBrands}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
.brand-table {
  height: 65vh;
  background-color: white;
}
tr:nth-child(2n) {
  background-color: rgb(243, 243, 243);
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.font-thin {
  font-weight: 100;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-3 {
  margin-left: 0.75rem;
}
.min-w-0 {
  min-width: 0px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
</style>
