<script>
import { onMount } from "svelte";
import initPhoneValidator from "../../../utils/phone-number";

export let contactInfo;
let contactHandlerElement;
let contactHandlerInstance;
let isMounted = false;

$: if (contactInfo && isMounted) {
  let selectedCountryCode = contactInfo?.newCountryDialCode ? contactInfo.newCountryDialCode : contactHandlerInstance.getSelectedCountryData().dialCode;
  contactHandlerInstance.setNumber(`+${selectedCountryCode}${contactInfo.phone_number}`);
}

onMount(() => {
  contactHandlerInstance = initPhoneValidator(contactHandlerElement);
  if (contactInfo.phone_number && contactInfo.country_code) {
    contactInfo.newCountryDialCode = contactInfo.country_code;
    contactHandlerInstance.setNumber(`+${contactInfo.country_code}${contactInfo.phone_number}`);
  }
  isMounted = true;
  contactHandlerElement.addEventListener("countrychange", function () {
    let selectedCountryCode = contactHandlerInstance.getSelectedCountryData().dialCode;
    contactInfo.newCountryDialCode = selectedCountryCode;
  });
});
</script>

<div class="mobile-number-wrapper">
  <div class="flex flex-wrap flex-row">
    <div class="w-full xl:w-5/12">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${contactInfo.phone_number}-contact-number`}"> Mobile Number </label>

      <input
        id="{`${contactInfo.phone_number}-contact-number`}"
        type="tel"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="Please enter a valid mobile number"
        bind:value="{contactInfo.phone_number}"
        on:input="{() => {
          contactHandlerInstance.setNumber(contactHandlerInstance.getNumber());
        }}"
        on:change="{() => {
          contactInfo.country_code = contactHandlerInstance.getSelectedCountryData().dialCode;
        }}"
        bind:this="{contactHandlerElement}" />
    </div>

    <div class="mx-2 mt-5 w-5/12 xl:w-3/12">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2 justify-around" for="{`${contactInfo.phone_number}-is-primary`}">
        <input id="{`${contactInfo.phone_number}-is-primary`}" type="checkbox" class="form-checkbox text-gray-800 ml-1 w-12 h-12 ease-linear transition-all duration-150" name="is_primary" bind:checked="{contactInfo.is_primary}" />
        <span class="ml-2 text-sm font-semibold text-gray-700"> Primary Number ? </span>
      </label>
    </div>

    <div class="mt-5 w-5/12 xl:w-3/12">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${contactInfo.phone_number}-is-whatsapp-contact`}">
        <input id="{`${contactInfo.phone_number}-is-whatsapp-contact`}" type="checkbox" class="form-checkbox text-gray-800 ml-1 w-12 h-12 ease-linear transition-all duration-150" name="is_whatsapp_contact" bind:checked="{contactInfo.is_whatsapp_contact}" />
        <span class="ml-2 text-sm font-semibold text-gray-700"> Whatsapp Number ? </span>
      </label>
    </div>
  </div>
</div>

<style windi:inject>
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-12 {
  height: 3rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-12 {
  width: 3rem;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1280px) {
  .xl\:w-5\/12 {
    width: 41.666667%;
  }
  .xl\:w-3\/12 {
    width: 25%;
  }
}
</style>