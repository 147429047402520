<script>
import axios from "axios";
import { authToken } from "../../../store/auth";
import { showSuccessMessage, showErrorMessage } from "../../../utils/toast";
import { onMount } from "svelte";
import Toggle from "svelte-toggle";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

export let merchant_data;
let operationFormField = {
  is_operating: false,
  is_delivering: false,
  is_picking: false,
  is_dine_in: false,
};

let message;

function updateOperationFormField() {
  (operationFormField.is_operating = merchant_data.store.is_operating ? merchant_data.store.is_operating : false), (operationFormField.is_delivering = merchant_data.store.is_delivering ? merchant_data.store.is_delivering : false), (operationFormField.is_picking = merchant_data.store.is_picking_up ? merchant_data.store.is_picking_up : false), (operationFormField.is_dine_in = merchant_data.store.is_dine_in ? merchant_data.store.is_dine_in : false);
}

onMount(() => {
  updateOperationFormField();
});

function postToUpdateStoreOperation(payload) {
  axios
    .put(`${morrDashboard.env.API_URL}/admin/merchants/${merchant_data.merchant_id}/update/store`, payload, {
      headers: {
        "x-auth-token": $authToken,
      },
    })
    .then((response) => {
      showSuccessMessage(`${message}`);
    })
    .catch((error) => {
      parseAndShowErrorMessage(error);
    });
}

function payloadUploadStoreStatus(status) {
  let payload = {
    delivery_fee_per_km: merchant_data.store.delivery_fee_per_km,
    store_custom_tax_rate: merchant_data.store.store_custom_tax_rate,
    is_operating: buildFormOperatingPayload(status),
    is_delivering: buildFormDeliveryPayload(status),
    is_picking_up: buildFormPickupPayload(status),
    is_custom_tax_rate_enabled: merchant_data.store.is_custom_tax_rate_enabled,
    is_dine_in: buildFormDineinPayload(status),
    schedule: merchant_data.store.schedule,
  };
  postToUpdateStoreOperation(payload);
}

function buildFormDeliveryPayload(status) {
  if (status === "Delivery") {
    if (!operationFormField.is_delivering) {
      message = `${status} is Enabled`;
    } else {
      message = `${status} is Disabled`;
    }
    return !operationFormField.is_delivering;
  }
  return operationFormField.is_delivering;
}

function buildFormOperatingPayload(status) {
  if (status === "Operating") {
    if (!operationFormField.is_operating) {
      message = `${status} is Enabled`;
    } else {
      message = `${status} is Disabled`;
    }
    return !operationFormField.is_operating;
  }
  return operationFormField.is_operating;
}

function buildFormPickupPayload(status) {
  if (status === "Pickup") {
    if (!operationFormField.is_picking) {
      message = `${status} is Enabled`;
    } else {
      message = `${status} is Disabled`;
    }
    return !operationFormField.is_picking;
  }
  return operationFormField.is_picking;
}

function buildFormDineinPayload(status) {
  if (status === "Dine In") {
    if (!operationFormField.is_dine_in) {
      message = `${status} is Enabled`;
    } else {
      message = `${status} is Disabled`;
    }
    return !operationFormField.is_dine_in;
  }
  return operationFormField.is_dine_in;
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0" style="background-color: rgb(245,241,234);">
  <div class="w-full rounded-t  mb-0 px-6 py-6" style="background-color:rgb(232,173,145)">
    <h6 class="text-center text-gray-800 text-xl font-bold uppercase">Store Status</h6>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Delivery Status</h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-is-operating">Delivering </label>

          <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{operationFormField.is_delivering}" on:click="{() => payloadUploadStoreStatus('Delivery')}" />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Operation Status</h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-is-operating">Operating </label>

          <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{operationFormField.is_operating}" on:click="{() => payloadUploadStoreStatus('Operating')}" />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Pickup Status</h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-is-operating">Pickup </label>

          <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{operationFormField.is_picking}" on:click="{() => payloadUploadStoreStatus('Pickup')}" />
        </div>
      </div>
    </div>

    <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Dine In Status</h6>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="store-is-operating">Dine In </label>

          <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{operationFormField.is_dine_in}" on:click="{() => payloadUploadStoreStatus('Dine In')}" />
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
}
</style>