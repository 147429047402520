<script>
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { onMount } from "svelte";
import FilePond, { registerPlugin } from "svelte-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { authToken } from "../../store/auth";
import { showSuccessMessage, showErrorMessage } from "../../utils/toast";
import { navigateTo } from "svelte-router-spa";

export let isShowUpdateModal;
export let data;
export let brand_id;
export let product_template_id;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let baseApiURl = morrDashboard.env.API_URL;

let filepond_input_name = "filepond";
let cover_pond;

let validationError;
let successMessage;

let productTemplateFormFields = {
  productTemplate: {
    product_name: "",
    image_url: [],
    description: "",
    price: 0.0,
    id: product_template_id,
  },
};

let product_templates_images = [data.image_url]?.map((url) => ({
  source: url,
  options: { type: "local" },
}));

function updateProductTemplateDetails() {
  productTemplateFormFields.productTemplate.product_name = data.name ? data.name : "";
  productTemplateFormFields.productTemplate.price = data.price || data.price === 0 ? data.price : productTemplateFormFields.productTemplate.price;
  productTemplateFormFields.productTemplate.description = data.description ? data.description : "";
  productTemplateFormFields.productTemplate.image_url = data.image_url?.length === 0 ? productTemplateFormFields.productTemplate.image_url[0] : [data.image_url];
  productTemplateFormFields.productTemplate.id = product_template_id;
}

const updateProductTemplateForm = form(
  () => ({
    ProductTemplate_price: {
      value: productTemplateFormFields.productTemplate.price,
      validators: ["required"],
    },
    ProductTemplate_product_name: {
      value: productTemplateFormFields.productTemplate.product_name,
      validators: ["required"],
    },
    ProductTemplate_description: {
      value: productTemplateFormFields.productTemplate.description,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function buildFormPayload() {
  return [
    {
      name: productTemplateFormFields.productTemplate.product_name,
      image_url: productTemplateFormFields.productTemplate.image_url[0],
      price: productTemplateFormFields.productTemplate.price,
      description: productTemplateFormFields.productTemplate.description,
      id: product_template_id,
    },
  ];
}

function postToUpdateProductTemplateApi(payload) {
  axios
    .put(`${baseApiURl}/admin/brand/${brand_id}/product/template`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      successMessage = "Successfully updated.";
      showSuccessMessage(successMessage);
      isShowUpdateModal = !isShowUpdateModal;
      navigateTo(`/merchants/brands/product/template/list/${brand_id}`);
    })
    .catch((err) => {
      validationError = err.response ? err.response.data : err;
      showErrorMessage(validationError);
    });
}

function updateProductTemplate() {
  let payload = buildFormPayload();
  postToUpdateProductTemplateApi(payload);
}

onMount(() => {
  updateProductTemplateDetails();
});

function getFilePondServerConfig(image_for, image_type) {
  return {
    url: `${baseApiURl}/admin/images`,
    load: async (source, load, error, progress, abort, headers) => {
      fetch(source, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          Origin: window.location.origin,
        },
      })
        .then(function (response) {
          response.blob().then(function (imageBlob) {
            load(imageBlob);
          });
        })
        .catch(function (_error) {
          error(_error);
        });
    },
    process: {
      url: "/upload",
      method: "POST",
      withCredentials: false,
      headers: {
        "X-Auth-Token": $authToken,
      },
      timeout: 7000,
      ondata: (formdata) => {
        formdata.append("image_for", image_for);
        formdata.append("image_type", image_type);
        return formdata;
      },
      onload: (response) => {
        response = JSON.parse(response);
        let file_url = response.data.file_url;
        productTemplateFormFields.productTemplate.image_url[0] = file_url;
        return file_url;
      },
      onerror: (response) => {
        let err = JSON.parse(response);
        validationError = (err.hasOwnProperty("detail") && err.detail) || err;
        showErrorMessage(validationError);
        return err.detail;
      },
    },
    revert: (uniqueFileId, load, error) => {
      axios
        .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
          headers: {
            "X-Auth-Token": $authToken,
          },
        })
        .then((res) => {
          let elementIndex = productTemplateFormFields.productTemplate.image_url.indexOf(uniqueFileId);
          productTemplateFormFields.productTemplate.image_url.splice(elementIndex, 1);
          load();
        })
        .catch((err) => {
          validationError = (err.hasOwnProperty("detail") && err.detail) || err;
          showErrorMessage(validationError);
        });
    },
  };
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Update Product Template</h6>

      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="productTemplate-name"> Name </label>
            <input id="productTemplate-name" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="product Template Name" bind:value="{productTemplateFormFields.productTemplate.product_name}" use:bindClass="{{ form: updateProductTemplateForm, name: 'ProductTemplate_product_name' }}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="productTemplate-price"> Price </label>
            <input id="productTemplate-price" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Product Template's price" bind:value="{productTemplateFormFields.productTemplate.price}" use:bindClass="{{ form: updateProductTemplateForm, name: 'ProductTemplate_price' }}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="productTemplate-description"> Description </label>
            <textarea id="productTemplate-price" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Product Template's Description" bind:value="{productTemplateFormFields.productTemplate.description}" use:bindClass="{{ form: updateProductTemplateForm, name: 'ProductTemplate_description' }}"></textarea>
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="images"> If you want to update images, add it here </label>
            <FilePond required="{true}" bind:this="{cover_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('productTemplate', 'cover')}" allowMultiple="{false}" files="{product_templates_images}" />
          </div>
        </div>
      </div>

      <button
        class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
        type="submit"
        disabled="{!$updateProductTemplateForm.valid}"
        on:click|preventDefault="{() => {
          updateProductTemplate();
        }}">
        Save
      </button>
      <button
        class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
        type="submit"
        on:click|preventDefault="{() => {
          isShowUpdateModal = !isShowUpdateModal;
        }}">
        Close
      </button>
    </form>
  </div>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
}
</style>
