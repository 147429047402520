<script>
import CardAddStoreCategory from "./CardAddStoreCategory.svelte";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

export let store_id;
export let categories;

let baseApiURL = morrDashboard.env.API_URL;
let showProduct = false;
let showAddCategory = false;
let color = "light";
let items = [];

function getProductListApi() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${baseApiURL}/admin/store/${store_id}/category/products`, {
      headers: headers,
    })
    .then((res) => {
      items = res.data.data;
      showProduct = true;
    })
    .catch((err) => {
      console.error("ALL_Products_Categories_LISTING_ERROR", err);
      showProduct = false;
      noProduct = "Products not found";
      parseAndShowErrorMessage(err);
    });
}
onMount(() => {
  getProductListApi();
});
</script>

{#if showAddCategory}
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardAddStoreCategory bind:categories bind:showAddCategory bind:store_id />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6 flex flex-row justify-between border-b border-gray-200">
    <h6 class="text-gray-800 text-xl font-bold flex">Category View</h6>
    <button
      class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"
      on:click="{() => {
        showAddCategory = !showAddCategory;
      }}">
      Add Category
    </button>
  </div>
  {#if showProduct}
    {#each Object.keys(items) as category}
      <h6 class="text-gray-800 text-xl font-bold m-4 py-2 px-4" id="{category}">
        {category}
      </h6>
      <div class="products">
        {#each items[category] as product, index (product.product_id)}
          <div>
            <div class="product p-4">
              <div class="relative pb-48 overflow-hidden rounded-lg">
                <img class="absolute inset-0 h-full w-full object-cover" src="{product.image_url}" alt="" />
              </div>
              <div class="p-4">
                <h2 class="mt-2 mb-2 font-bold">
                  {product.product_name}
                </h2>
                <p class="text-sm">
                  {product.description}
                </p>
                <div class="mt-3 flex items-center">
                  <span class="text-sm font-semibold">RM</span>&nbsp;<span class="font-bold text-xl">{product.price}</span>
                </div>
              </div>
              <div class="py-2 px-8 status flex border-t border-b text-xs text-gray-700 justify-evenly">
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Available?</p>
                  {#if product.is_available}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                  {/if}
                </div>
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Deliverable?</p>
                  {#if product.is_deliverable}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                  {/if}
                </div>
                <div class="flex flex-row font-bold items-center m-2">
                  <p>Pickable?</p>
                  {#if product.is_pickable}
                    <i class="fa fa-check-circle text-green-500 text-lg ml-2"></i>
                  {:else}
                    <i class="fas fa-times-circle text-red-500 text-lg ml-2"></i>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/each}

        {#if items[category].length === 0}
          <div class="text-black px-6 py-4 border-0 rounded relative mb-4 bg-orange-300">
            <span class="text-xl inline-block mr-5 align-middle">
              <i class="fas fa-bell"></i>
            </span>
            <span class="inline-block align-middle mr-8"> Please add products to this category </span>
          </div>
        {/if}
      </div>
    {/each}
  {:else}
    <div class="block w-full overflow-x-auto m-4">
      <div>{"No categories found"}</div>
    </div>
  {/if}
</div>

<style windi:inject>
.products {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  align-items: center;
  justify-content: center;
}
.product {
  background-color: rgb(231, 221, 207);
  border-radius: 20px;
  margin: 20px;
}
@media (max-width: 540px) {
  button {
    margin: 5px;
  }
}
@media (max-width: 1000px) {
  .products {
    display: grid;
    grid-template-columns: 93%;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .products {
    display: grid;
    grid-template-columns: 47% 47%;
  }
}
@media (max-width: 1497px) {
  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 186, 116, var(--tw-bg-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-full {
  height: 100%;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-8 {
  margin-right: 2rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-48 {
  padding-bottom: 12rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-1 {
  z-index: 1;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
