<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import CardEmailTemplateEdit from "./CardEmailTemplateEdit.svelte";
import { HTMLStringParser } from "../../../utils/minifyHtml";
import Pagination from "@fouita/pagination";
import { showSuccessMessage } from "../../../utils/toast";
import { navigateTo } from "svelte-router-spa";

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
};
const limit = 10;
let current = 1;
let skip = 0;
let totalItems = 0;

let emailTemplates = [];
let showEmailTemplates = false;
let templateLoadingStatus = "Loading templates ...";

// template preview modal
let isPreviewingTemplate = false;
let templatePreview = null;

// template edit modal
let isShowingEditModal = false;
let templateUpdateObj;
let updateTemplateIndex = 0;

function getEmailTemplatesAPI(skip) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios

    .get(`${morrDashboard.env.API_URL}/admin/email-templates/?skip=${skip}&limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("ALL_EMAIL_TEMPLATE_LISTING", res);
      emailTemplates = res.data.data;
      totalItems = res.data.paging.total;
      showEmailTemplates = true;
    })
    .catch((err) => {
      showEmailTemplates = false;
      templateLoadingStatus = "Email Template not found";
      parseAndShowErrorMessage(err);
    });
}

function showTemplateEditModal() {
  isShowingEditModal = true;
}

function closeTemplateEditModal() {
  isShowingEditModal = false;
}

function showPreview() {
  isPreviewingTemplate = true;
}

function closePreview() {
  isPreviewingTemplate = false;
}

function editTemplate(index) {
  showTemplateEditModal();
  const emailTemplate = emailTemplates[index];
  templateUpdateObj = emailTemplate;
  updateTemplateIndex = index;
}

function showHTMLBodyPreview(index) {
  showPreview();
  const emailTemplate = emailTemplates[index];
  templatePreview = HTMLStringParser(emailTemplate.html_body);
}

function handleKeyDown(event, closeModalHandler) {
  if (event.key === "Escape") closeModalHandler();
}

function updateTemplateActionAPI(index) {
  let emailTemplate = emailTemplates[index];
  const action = emailTemplate.is_published ? "unpublish" : "publish";
  showSuccessMessage(`${action}ing ${emailTemplate.name}`);

  let headers = {
    "x-auth-token": $authToken,
  };
  axios
    .post(
      `${morrDashboard.env.API_URL}/admin/email-templates/manage/${emailTemplate.template_id}/${action}`,
      {},
      {
        headers: headers,
      }
    )
    .then((res) => {
      emailTemplate.is_published = !emailTemplate.is_published;
      emailTemplates[index] = emailTemplate;
      emailTemplates = [...emailTemplates];
      console.log("EMAIL_TEMPLATE_ACTION_UPDATE", res);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function deleteTemplateActionAPI(index) {
  let emailTemplate = emailTemplates[index];
  showSuccessMessage(`Deleting ${emailTemplate.name}`);

  let headers = {
    "x-auth-token": $authToken,
  };
  axios
    .delete(`${morrDashboard.env.API_URL}/admin/email-templates/${emailTemplate.template_id}`, {
      headers: headers,
    })
    .then((res) => {
      emailTemplates.splice(index, 1);
      emailTemplates = [...emailTemplates];
      console.log("EMAIL_TEMPLATE_ACTION_DELETE", res);
      showSuccessMessage(`${emailTemplate.name} successfully deleted`);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function paginate(e) {
  if (current === 1) {
    getEmailTemplatesAPI(0);
  } else if (current > 1) {
    skip = limit * (current - 1);
    getEmailTemplatesAPI(skip);
  }
}

$: if (isPreviewingTemplate) {
  document.addEventListener("keydown", (event) => {
    handleKeyDown(event, closePreview);
  });
}

$: if (isShowingEditModal) {
  document.addEventListener("keydown", (event) => {
    handleKeyDown(event, closeTemplateEditModal);
  });
}

onMount(() => {
  getEmailTemplatesAPI(0);
});

let color = "light";
</script>

{#if isPreviewingTemplate}
  <div class="background overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex" on:click|self="{() => closePreview()}">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <i class="fas fa-times-circle text-red-500 text-lg ml-2" on:click="{() => closePreview()}"></i>
        <div contenteditable="false" bind:innerHTML="{templatePreview}"></div>
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}
{#if isShowingEditModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex" on:click|self="{() => closeTemplateEditModal()}">
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CardEmailTemplateEdit bind:templateUpdateObj bind:emailTemplates index="{updateTemplateIndex}" />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Email Templates</h6>
      <button class="absolute top-2 right-2 z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" style="z-index: 1;" on:click="{() => navigateTo('/admin/email-template/create')}"> Add New </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    {#if showEmailTemplates}
      <Datatable settings="{settings}" data="{emailTemplates}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="template-name"> Template Name </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="subject"> Subject </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="service-provider"> Service Provider </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="status"> Status </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="preview"> Actions </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-whit'}">{row.name} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-whit'}">{row.subject} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {row.is_published ? 'text-green-500' : 'text-gray-500'}">
                {#if row.service_provider === "ses"}
                  <i class="fab fa-aws text-blue-1000" title="Amazon SES"></i>
                {/if}
                <br />
                {row.service_provider.toUpperCase()}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{row.status} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-whit'}">
                <i class="fas fa-eye text-blue-700" on:click="{() => showHTMLBodyPreview(index)}" title="Preview"></i>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i class="fas fa-pen text-yellow-500" on:click="{() => editTemplate(index)}" title="Edit"></i>
                &nbsp;&nbsp; &nbsp;
                {#if row.is_published}
                  <i class="fas fa-cloud-sun  text-red-500 text-lg" title="Click to unpublish" on:click="{() => updateTemplateActionAPI(index)}"></i>
                {:else}
                  <i class="fas fa-cloud text-green-500  text-lg" title="click to publish template" on:click="{() => updateTemplateActionAPI(index)}"></i>
                {/if}
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i class="fas fa-trash text-red-500" on:click="{() => deleteTemplateActionAPI(index)}" title="Delete"></i>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination small bind:current per_page="{limit}" num_items="{totalItems}" on:navigate="{paginate}" />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{templateLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
tr:nth-child(2n) {
  background-color: rgb(243, 243, 243);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
