<script>
import axios from "axios";
import { authToken } from "../../store/auth";
import { onMount } from "svelte";

let transactionStats = null;
let noTransactions = "Loading stats ...";

function loadStats() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/payments/transactions/stats`, {
      headers: headers,
    })
    .then((res) => {
      console.log("TRANSACTION_STATS", res);
      transactionStats = res.data.data;
    })
    .catch((err) => {
      console.error("TRANSACTION_STATS_ERROR", err);
      noTransactions = "Transaction data not found";
    });
}

onMount(() => {
  loadStats();
});
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Transaction Stats</h6>
    </div>
  </div>
  <div class="flex-auto p-8 space-x-4">
    {#if transactionStats}
      <div class="flex columns-5xs bg-white flex-wrap w-full text-center justify-between my-2">
        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">Total</h5>
                  <span class="font-semibold text-xl text-gray-800">{transactionStats.total}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4 mr-2">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">Success</h5>
                  <span class="font-semibold text-xl text-gray-800">{transactionStats.success}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">Fail</h5>
                  <span class="font-semibold text-xl text-gray-800">{transactionStats.failed}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">Pending/ Expired</h5>
                  <span class="font-semibold text-xl text-gray-800">{transactionStats.other}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="relative flex flex-col min-w-0 break-words  mb-6 xl:mb-0">
            <div class="flex-auto p-4">
              <div class="flex flex-wrap">
                <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 class="text-gray-500 uppercase font-bold text-xs">Amount</h5>
                  <span class="font-semibold text-xl text-gray-800">RM {transactionStats.amount_earned}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {:else}
      <p>{noTransactions}</p>
    {/if}
  </div>
</div>

<style windi:inject>
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-2 {
  margin-right: 0.5rem;
}
.max-w-full {
  max-width: 100%;
}
.min-w-0 {
  min-width: 0px;
}
.p-8 {
  padding: 2rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
@media (min-width: 1280px) {
  .xl\:mb-0 {
    margin-bottom: 0px;
  }
}
</style>