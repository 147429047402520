<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import { showSuccessMessage } from "../../../utils/toast";
import HomePageRowsCrudModal from "./HomePageRowsCrudModal.svelte";
import { Confirm } from "svelte-confirm";
import Toggle from "svelte-toggle";
import { recaptchaWrapper } from "../../../utils/google-captcha";

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
};
let baseApiURL = morrDashboard.env.API_V2_URL;

let data;
let currentUpdateIndex = null;
let showModal = false;
let color = "light";
let showRows = true;
let rowsLoadingStatus = "Loading..";
let row_id;
let homePageRows = [];
let operationType;
let rowsCrudCallback = () => {};

function createRow(payload) {
  recaptchaWrapper("HomePageRowCreate", createRowApi, [payload]);
}
function createRowApi(token, payload) {
  axios
    .post(`${baseApiURL}/admin/homepage/rows/`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully added row");
      showModal = !showModal;
      getRowsApi();
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function getRowsApi() {
  axios
    .get(`${baseApiURL}/admin/homepage/rows/`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      homePageRows = response.data.data;
      console.log(response);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function visitUpdate(id, row, index) {
  row_id = id;
  data = row;
  currentUpdateIndex = index;
  showModal = !showModal;
  rowsCrudCallback = updateRow;
}

$: if (data) {
  // After Updating a Product, update local copy as well without api call, but we need to call rows api becoz if we update and delete it will not shows up the update list
  if (currentUpdateIndex != null && data != homePageRows[currentUpdateIndex]) {
    homePageRows[currentUpdateIndex] = data;
  }
}

function deleteRow(rowId, index) {
  recaptchaWrapper("HomepageRowDelete", deleteRowApi, [rowId, index]);
}

function deleteRowApi(token, rowId, index) {
  axios
    .delete(`${baseApiURL}/admin/homepage/rows/${rowId}`, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully Deleted.");
      homePageRows.splice(index, 1);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updateRow(payload) {
  recaptchaWrapper("HomepageRowsUpdate", updateRowApi, [payload]);
}
function updateRowApi(token, payload) {
  axios
    .put(`${baseApiURL}/admin/homepage/rows/${row_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully update row");
      data = response.data.data;
      showModal = !showModal;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updateStatusOfRow(event, rowData, index) {
  recaptchaWrapper("HomepageRowsUpdate", updateStatusOfRowApi, [event, rowData, index]);
}
function updateStatusOfRowApi(token, event, rowData, index) {
  if (event.type != "click" || event.target.id.indexOf("toggle") === -1) return;
  let activeStatus = rowData.is_active ? false : true;
  let payload = {
    title: rowData.title,
    order: rowData.order,
    is_active: activeStatus,
    row_name: rowData.row_name,
    orientation: rowData.orientation,
    layout: rowData.layout,
    api_url: rowData.api_url,
  };
  axios
    .put(`${baseApiURL}/admin/homepage/rows/${rowData.row_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully update status");
      currentUpdateIndex = index;
      data = response.data.data;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  getRowsApi();
});
</script>

{#if showModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <HomePageRowsCrudModal bind:showModal bind:rowsData="{data}" bind:operationType bind:callback="{rowsCrudCallback}" />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Home Page Rows</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click="{() => {
          showModal = !showModal;
          data = null;
          operationType = 'create';
          row_id = null;
          rowsCrudCallback = createRow;
        }}">
        Add New
      </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showRows}
      <Datatable settings="{settings}" data="{homePageRows}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="title"> Title </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="order"> Order </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="is-active"> Is Active </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="row-name"> Row Name </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="orientation"> Orientation </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="layout"> Layout </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="api-url"> Url </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="edit">
            <i class="fas fa-pen text-yellow-500" title="Edit"></i>
          </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="delete">
            <i class="fas fa-trash text-red-500" title="Delete"></i>
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">{row.title} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">{row.order} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold">
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled="{row.is_active}"
                  on:click="{(e) => {
                    updateStatusOfRow(e, row, index);
                  }}" />
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{row.row_name} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{row.orientation.toUpperCase()} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {row.layout.replace("_", " ").toUpperCase()}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {row.api_url}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <i class="fas fa-pen text-yellow-500" title="Edit" on:click="{() => visitUpdate(row.row_id, row, index)}"></i>
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                  <button on:click="{() => confirmThis(deleteRow, row.row_id, index)}">
                    <i class="fas fa-trash text-red-500"></i>
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold">{row.title}</span>?
                  </span>
                  <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{rowsLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
tr:nth-child(2n) {
  background-color: rgb(243, 243, 243);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-screen {
  height: 100vh;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
