<script>
export let products;
export let invoiceDetails;
</script>

<div class="items-container">
  {#if products}
    <div class="flex flex-col w-full m-0">
      {#each products as product}
        <div class="test product w-full my-2 justify-between flex flex-col lg:flex-row max-w-full rounded-md border-1 border-slate-200">
          <div class="product-thumbnail h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style="{`background-image: url('${product.product.image_url}')`}" title="{product.product.product_name}"></div>
          <div class="{`py-1 px-4 leading-relaxed  w-full ${product.addons.length > 0 ? 'lg:w-7/12' : ''}`}">
            <div class="mb-1 w-full">
              <div class="text-gray-900 font-bold text-lg">
                {product.product.product_name}
                {#if product.variation}
                  <span class="font-bold text-indigo-500">({product.variation.title})</span>
                {/if}
                <span class="text-slate-500 text-2xl">x{product.number_of_products}</span>
              </div>

              <p class="font-normal my-2">
                <i class="fas fa-file-invoice-dollar"></i> RM
                <span class="font-bold text-gray-800">{product.price_per_unit}</span>
              </p>
              <p class="text-gray-600 text-sm w-full break-words text-justify my-1">
                Customer Note:
                <br />
                <i class="fas fa-quote-left"></i>
                {product.note || "-"}
                <i class="fas fa-quote-right"></i>
              </p>
            </div>
          </div>
          <div class="{`py-1 px-4 leading-normal w-full ${product.addons.length > 0 ? 'lg:w-4/12' : ''}`}">
            {#if product.addons.length > 0}
              <div class="justify-between">
                <span class="text-slate-800 text-lg font-semibold mt-2">Addons:</span>
                <br />
                {#each product.addons as addon}
                  <div class="flex m-0 p-0 justify-between border-b mb-2 pb-2">
                    <p class="text-indigo-500 text-sm font-semibold">
                      {addon.name}
                    </p>
                    <p class="text-gray-600 text-sm">
                      RM <span class="text-gray-800 font-bold"> {addon.price}</span>
                    </p>
                  </div>
                {/each}
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>

<style windi:inject>
.bg-cover {
  background-size: cover;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-1 {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.h-48 {
  height: 12rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-normal {
  line-height: 1.5;
}
.m-0 {
  margin: 0px;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.max-w-full {
  max-width: 100%;
}
.overflow-hidden {
  overflow: hidden;
}
.p-0 {
  padding: 0px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
@media (min-width: 1024px) {
  .lg\:rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .lg\:h-auto {
    height: auto;
  }
  .lg\:w-48 {
    width: 12rem;
  }
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
}
</style>