<script>
let klass = "";
export { klass as class };
</script>

<svg
  class="{klass} stroke-current"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-hidden="true"
  focusable="false"
  width="1em"
  height="1em"
  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg);
   transform: rotate(360deg);"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 24 24">
  <g fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M15 18l-6-6l6-6"></path>
  </g>
</svg>

<style windi:inject>
[fill~="none"] {
  fill: none;
}
.stroke-current {
  stroke: currentColor;
}
</style>