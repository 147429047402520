<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { toast } from "@zerodevx/svelte-toast";
import { Confirm } from "svelte-confirm";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import Pagination from "@fouita/pagination";
import { recaptchaWrapper } from "../../../utils/google-captcha";
import StoreAddonCrudModal from "./StoreAddonCrudModal.svelte";
import { showSuccessMessage } from "../../../utils/toast";

export let store_id;
let validationError = null;

let baseApiURL = morrDashboard.env.API_V2_URL;
const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
  blocks: {
    searchInput: false,
  },
};

const limit = 10;
let addon_id;
let addonCrudCallback = () => {};
let color = "light";
let operationType;
let showModal = false;
let addonData;
let current = 1;
let skip = 0;
let totalItems = 0;
let allAddons = [];
let addonsToShow = [];
let showAddons = false;
let noAddons = "loading addons ...";
function getAddonListApi(skip) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${baseApiURL}/admin/store/${store_id}/addons?skip=${skip}&limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("ALL_ADDONS_LISTING", res);
      allAddons = res.data.data;
      addonsToShow = [...allAddons];
      totalItems = res.data.paging.total;
      showAddons = true;
    })
    .catch((err) => {
      console.error("ALL_ADDONS_LISTING_ERROR", err);
      showAddons = false;
      noAddons = "Addons not found";
      parseAndShowErrorMessage(err);
    });
}

function createAddon(payload) {
  recaptchaWrapper("StoreAddonCreate", postToCreateAddon, [payload]);
}
function postToCreateAddon(token, payload) {
  axios
    .post(`${baseApiURL}/admin/store/${store_id}/addons/`, payload, {
      headers: {
        "X-auth-token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("New Addon Created");
      showModal = !showModal;
      getAddonListApi(skip);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updateAddon(payload) {
  recaptchaWrapper("StoreAddonUpdate", updateAddonApi, [payload]);
}

function updateAddonApi(token, payload) {
  axios
    .put(`${baseApiURL}/admin/store/${store_id}/addons/${addon_id}`, payload, {
      headers: {
        "X-auth-token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Addon updated succesfully");
      showModal = !showModal;
      getAddonListApi(skip);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function deleteAddon(addon_id) {
  recaptchaWrapper("StoreAddonDelete", deleteAddonApi, [addon_id]);
}
function deleteAddonApi(token, addon_id) {
  axios
    .delete(`${baseApiURL}/admin/store/${store_id}/addons/${addon_id}`, {
      headers: {
        "X-auth-token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Addon delete successfully");
      getAddonListApi(skip);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  getAddonListApi(0);
});

// Todo: Pagination Left
function paginate(e) {
  if (current === 1) {
    getAddonListApi(0);
  } else if (current > 1) {
    skip = limit * (current - 1);
    getAddonListApi(skip);
  }
}

function visitUpdate(id, addon, index) {
  addon_id = id;
  addonData = addon;
  showModal = !showModal;
  addonCrudCallback = updateAddon;
}
</script>

{#if showModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <StoreAddonCrudModal bind:showModal bind:store_id bind:callback="{addonCrudCallback}" bind:operationType bind:addonData />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg border-b bg-gray-100">
  <div class="rounded-t bg-white mb-0 px-6 py-6 border-b border-gray-100">
    <div class="header flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Addons</h6>
      <div class="flex justify-end">
        <button
          class="z-1 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click="{() => {
            showModal = !showModal;
            addonCrudCallback = createAddon;
            operationType = 'Create';
            addonData = null;
          }}">Add Addon</button>
      </div>
    </div>
  </div>
  <div class="merchant-table block w-full overflow-x-auto">
    {#if showAddons}
      <Datatable settings="{settings}" data="{addonsToShow}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="name"> Addon Name </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="price"> Price </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="update"></th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="delete"></th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">{row.name}</td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">{row.price}</td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4"
                ><button on:click="{() => visitUpdate(row.addon_id, row, index)}">
                  <i class="fas fa-pen text-yellow-500"></i>
                </button></td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                  <button
                    on:click="{() => {
                      confirmThis(deleteAddon, row.addon_id);
                    }}">
                    <i class="fas fa-trash text-red-500"></i>
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold">{row.name}</span>?
                  </span>
                  <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
      <Pagination small bind:current per_page="{limit}" num_items="{totalItems}" on:navigate="{paginate}" />
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noAddons}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
:global(.svelte-tabs li.svelte-tabs__selected) {
  border-bottom: 2px solid #ef4544;
  color: #ef4544;
  font-weight: 600;
}
:global(.svelte-tabs) {
  padding: 10px;
}
.merchant-table {
  height: 65vh;
  background-color: white;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-1 {
  z-index: 1;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
