<script>
import { form, bindClass } from "svelte-forms";
import Select from "svelte-select";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { showErrorMessage } from "../../../utils/toast";
import { onMount } from "svelte";
import Toggle from "svelte-toggle";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";

export let showModal;
export let operationType;
export let couponsData;
export let callback;
let merchantsList = [];
let coupons_type = [
  {
    label: "Percent Discount",
    value: 1,
  },
  {
    label: "Fixed Cart",
    value: 2,
  },
];
let selectedMerchantDetail = null;
let selectedCouponType = null;
let renderTemplate = false;
let validationError;
let couponFormFields = {
  coupon: {
    title: "",
    coupon_type: "",
    discount_price: 0.0,
    coupon_code: "",
    message: "",
    is_active: true,
    minimum_purchase_amount: 0.0,
    instructions: "",
    merchant_id: [],
  },
};

const createCouponForm = form(
  () => ({
    title: {
      value: couponFormFields.coupon.title,
      validators: ["required"],
    },
    coupon_type: {
      value: couponFormFields.coupon.coupon_type,
      validators: ["required"],
    },
    discount_price: {
      value: couponFormFields.coupon.discount_price,
      validators: ["required"],
    },
    coupon_code: {
      value: couponFormFields.coupon.coupon_code,
      validators: ["required"],
    },
    minimum_purchase_amount: {
      value: couponFormFields.coupon.minimum_purchase_amount,
      validators: ["required"],
    },
    instructions: {
      value: couponFormFields.coupon.instructions,
      validators: ["required"],
    },
    merchant_id: {
      value: couponFormFields.coupon.merchant_id,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function buildFormPayload() {
  return {
    title: couponFormFields.coupon.title,
    coupon_type: couponFormFields.coupon.coupon_type,
    is_active: couponFormFields.coupon.is_active,
    discount_price: couponFormFields.coupon.discount_price,
    coupon_code: couponFormFields.coupon.coupon_code,
    message: couponFormFields.coupon.message,
    minimum_purchase_amount: couponFormFields.coupon.minimum_purchase_amount,
    instructions: couponFormFields.coupon.instructions,
    merchant_id: couponFormFields.coupon.merchant_id,
  };
}

function validateCouponData() {
  validationError = null;

  if (!couponFormFields.coupon.title) {
    validationError = "Title is required";
  } else if (!couponFormFields.coupon.coupon_type) {
    validationError = "Coupon Type is required";
  } else if (!couponFormFields.coupon.discount_price) {
    validationError = "Discount Price is required";
  } else if (!couponFormFields.coupon.coupon_code) {
    validationError = "Coupon Code is required";
  } else if (!couponFormFields.coupon.minimum_purchase_amount) {
    validationError = "Minimum Purchase Amount is required";
  } else if (!couponFormFields.coupon.instructions) {
    validationError = "Instructions is required";
  } else if (!couponFormFields.coupon.merchant_id) {
    validationError = "Merchant_id is required";
  }

  if (validationError) {
    showErrorMessage(validationError);
    return;
  }

  let payload = buildFormPayload();
  callback(payload);
}

function handleCouponType(e) {
  couponFormFields.coupon.coupon_type = e.detail.value;
  selectedCouponType = e.detail;
}

function resetCouponType(e) {
  couponFormFields.coupon.coupon_type = "";
}

function handleMerchants(e) {
  let selectedMerchantDetail = e.detail || [];
  couponFormFields.coupon.merchant_id = [];
  selectedMerchantDetail.forEach((selectedCoupon) => {
    couponFormFields.coupon.merchant_id.push(selectedCoupon.value);
  });
}

function resetMerchants(e) {
  couponFormFields.coupon.merchant_id.splice(e.detail.value, 1);
}

function getMerchantListApi() {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  let skip = 0;
  let limit = 100;
  axios

    .get(`${morrDashboard.env.API_URL}/admin/merchants/?skip=${skip}&limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("ALL_MERCHANTS_LISTING", res);
      let allMerchants = res.data.data;
      allMerchants.forEach((merchant) => {
        merchantsList.push({
          label: merchant.name,
          value: merchant.merchant_id,
        });
      });
      merchantsList = merchantsList;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  if (couponsData) {
    couponFormFields.coupon = couponsData;
    selectedCouponType = couponFormFields.coupon.coupon_type;
    selectedMerchantDetail = couponsData.merchants;
    let merchantIds = [];
    selectedMerchantDetail.forEach((merchant) => {
      merchantIds.push(merchant.value);
    });
    couponFormFields.coupon.merchant_id = merchantIds;
  }
  setTimeout(() => {
    getMerchantListApi();
    renderTemplate = true;
  }, 500);
});
</script>

{#if renderTemplate}
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-200 border-0">
    <div class="flex-auto px-4 lg:px-4 py-4 pt-0">
      <form>
        <div class="flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            {operationType === "create" ? "Create" : "Update"} Coupon
          </h6>
          <div></div>
        </div>
        <div>
          <div class="w-full">
            <div>
              <div class="flex mb-3 md:flex-row justify-between">
                <div class="px-4 w-full md:w-6/12">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="title"> Title </label>
                    <input id="title" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter row title" bind:value="{couponFormFields.coupon.title}" use:bindClass="{{ form: createCouponForm, name: 'title' }}" />
                  </div>
                </div>
                {#if operationType === "create"}
                  <div class="px-4 w-full md:w-6/12">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="coupon_code"> Coupon Code </label>
                      <input id="coupon_code" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your Coupon Code" bind:value="{couponFormFields.coupon.coupon_code}" use:bindClass="{{ form: createCouponForm, name: 'coupon_code' }}" />
                    </div>
                  </div>
                {/if}
              </div>
              <div class="flex mb-3 flex-col md:flex-row justify-between">
                <div class="px-4 w-full md:w-6/12">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="coupon-type"> Coupon Type </label>
                    <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{selectedCouponType}" items="{coupons_type}" isMulti="{false}" placeholder="Please select coupon type" noOptionsMessage="No coupons type available" on:select="{handleCouponType}" on:clear="{resetCouponType}" />
                  </div>
                </div>
                <div class="px-4 w-full md:w-6/12">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="message"> Message </label>
                    <input id="message" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your message" bind:value="{couponFormFields.coupon.message}" use:bindClass="{{ form: createCouponForm, name: 'message' }}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant_id"> Merchants </label>
                <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.1rem;" value="{selectedMerchantDetail}" items="{merchantsList}" isMulti="{true}" placeholder="Please select merchants" noOptionsMessage="No merchants available" on:select="{handleMerchants}" on:clear="{resetMerchants}" />
              </div>
            </div>

            <div class="px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="instructions"> Instructions </label>
                <input id="instructions" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your instructions" bind:value="{couponFormFields.coupon.instructions}" use:bindClass="{{ form: createCouponForm, name: 'instructions' }}" />
              </div>
            </div>
            <div class="flex mb-3 flex-col md:flex-row justify-between">
              <div class="px-4 w-1/3">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="discount-price"> Discount Price </label>
                  <input id="discount-price" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter discount price" bind:value="{couponFormFields.coupon.discount_price}" use:bindClass="{{ form: createCouponForm, name: 'discount_price' }}" />
                </div>
              </div>
              <div class="px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="minimum_purchase_amount"> Minimum Purchase Amount </label>
                  <input id="minimum_purchase_amount" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter your minimum purchase amount" bind:value="{couponFormFields.coupon.minimum_purchase_amount}" use:bindClass="{{ form: createCouponForm, name: 'minimum_purchase_amount' }}" />
                </div>
              </div>
              <div class="px-4 items-center">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="is-active"> Is_Active </label>
                  <Toggle switchColor="#828282" toggledColor="#e8ad90" untoggledColor="#fff" hideLabel bind:toggled="{couponFormFields.coupon.is_active}" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <div class="flex items-center justify-center w-full m-4 justify-items-center ">
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault="{() => {
                validateCouponData();
              }}">
              {operationType === "create" ? "Save" : "Update"}
            </button>
            <button
              class="m-2 bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
              on:click|preventDefault="{() => {
                showModal = !showModal;
              }}">
              Close
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
{/if}

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-items-center {
  justify-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-1\/3 {
  width: 33.333333%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@media (min-width: 768px) {
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
