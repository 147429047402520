<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../../store/auth";
import { onMount } from "svelte";
import { parseAndShowErrorMessage } from "../../../utils/errorParser";
import { showSuccessMessage } from "../../../utils/toast";
import CouponsCrudModal from "./CouponsCrudModal.svelte";
import { Confirm } from "svelte-confirm";
import Toggle from "svelte-toggle";
import { recaptchaWrapper } from "../../../utils/google-captcha";

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
};
let baseApiURL = morrDashboard.env.API_V2_URL;
let couponTypeMapping = {
  PERCENT_DISCOUNT: 1,
  FIXED_CART_DISCOUNT: 2,
};
let data;
let currentUpdateIndex = null;
let showModal = false;
let color = "light";
let showCoupons = true;
let couponsLoadingStatus = "Loading..";
let coupon_id;
let coupons = [];
let operationType;
let couponsCrudCallback = () => {};

function createCoupon(payload) {
  recaptchaWrapper("CouponCreate", createCouponApi, [payload]);
}
function createCouponApi(token, payload) {
  axios
    .post(`${baseApiURL}/admin/coupons/`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully coupon added");
      showModal = !showModal;
      getCouponsApi();
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function getCouponsApi() {
  axios
    .get(`${baseApiURL}/admin/coupons/`, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      coupons = response.data.data;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function visitUpdate(id, coupon, index) {
  coupon_id = id;
  data = coupon;
  currentUpdateIndex = index;
  operationType = "update";
  showModal = !showModal;
  couponsCrudCallback = updateCoupon;
}

$: if (data) {
  // After Updating a coupon, update local copy as well without api call, but we need to call coupons api becoz if we update and delete it will not shows up the update list
  if (currentUpdateIndex != null && data != coupons[currentUpdateIndex]) {
    coupons[currentUpdateIndex] = data;
  }
}

function deleteCoupon(counponId, index) {
  recaptchaWrapper("CouponDelete", deleteCouponApi, [counponId, index]);
}

function deleteCouponApi(token, counponId, index) {
  axios
    .delete(`${baseApiURL}/admin/coupons/${counponId}`, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully Deleted.");
      coupons.splice(index, 1);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updateCoupon(payload) {
  recaptchaWrapper("CouponUpdate", updateCouponApi, [payload]);
}
function updateCouponApi(token, couponDetails) {
  let payload = {
    title: couponDetails.title,
    coupon_type: couponTypeMapping[couponDetails.coupon_type],
    is_active: couponDetails.is_active,
    discount_price: couponDetails.discount_price,
    message: couponDetails.message,
    minimum_purchase_amount: couponDetails.minimum_purchase_amount,
    instructions: couponDetails.instructions,
    merchant_ids: couponDetails.merchant_id,
  };
  axios
    .put(`${baseApiURL}/admin/coupons/${coupon_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully coupon updated");
      data = response.data.data;
      showModal = !showModal;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function updateStatusOfCoupon(event, couponData, index) {
  recaptchaWrapper("CouponUpdate", updateStatusOfCouponApi, [event, couponData, index]);
}
function updateStatusOfCouponApi(token, event, couponData, index) {
  if (event.type != "click" || event.target.id.indexOf("toggle") === -1) return;
  let activeStatus = couponData.is_active ? false : true;
  let merchants = couponData.merchants;
  let merchant_ids = [];
  merchants.forEach((merchant) => {
    merchant_ids.push(merchant.value);
  });
  let payload = {
    title: couponData.title,
    coupon_type: couponTypeMapping[couponData.coupon_type],
    is_active: activeStatus,
    discount_price: couponData.discount_price,
    message: couponData.message,
    minimum_purchase_amount: couponData.minimum_purchase_amount,
    instructions: couponData.instructions,
    merchant_ids: merchant_ids,
  };
  axios
    .put(`${baseApiURL}/admin/coupons/${couponData.coupon_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
        "x-google-token": token,
      },
    })
    .then((response) => {
      showSuccessMessage("Successfully update status");
      currentUpdateIndex = index;
      data = response.data.data;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

onMount(() => {
  getCouponsApi();
});
</script>

{#if showModal}
  <div class="background overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-full my-6 mx-auto max-w-3xl">
      <div class="relative p-6 flex-auto">
        <CouponsCrudModal bind:showModal bind:couponsData="{data}" bind:operationType bind:callback="{couponsCrudCallback}" />
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between p-4">
      <h6 class="text-gray-800 text-xl font-bold">Coupons</h6>
      <button
        class="z-10 bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        style="z-index: 1;"
        on:click="{() => {
          showModal = !showModal;
          data = null;
          operationType = 'create';
          coupon_id = null;
          couponsCrudCallback = createCoupon;
        }}">
        Add New
      </button>
    </div>
  </div>
  <div class="block w-full overflow-x-auto h-screen bg-white">
    {#if showCoupons}
      <Datatable settings="{settings}" data="{coupons}" class="items-center w-full bg-transparent border-collapse my-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="title"> Title </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="discount-price"> Discount Price </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="is-active"> Is Active </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="coupon-code"> Coupon code </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="coupon-type"> Coupon Type </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="message"> Message </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="instruction"> Instructions </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="minimum-purchase-amount"> Minimum Purchase Amount </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="edit">
            <i class="fas fa-pen text-yellow-500" title="Edit"></i>
          </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="delete">
            <i class="fas fa-trash text-red-500" title="Delete"></i>
          </th>
        </thead>
        <tbody>
          {#each $rows as row, index}
            <tr>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">{row.title} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">{row.discount_price} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold">
                <Toggle
                  switchColor="#828282"
                  toggledColor="#e8ad90"
                  untoggledColor="#fff"
                  hideLabel
                  toggled="{row.is_active}"
                  on:click="{(e) => {
                    updateStatusOfCoupon(e, row, index);
                  }}" />
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{row.coupon_code} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'text-gray-700' : 'text-white'}">{row.coupon_type} </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {row.message}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {row.instructions}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                {row.minimum_purchase_amount}
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <i class="fas fa-pen text-yellow-500" title="Edit" on:click="{() => visitUpdate(row.coupon_id, row, index)}"></i>
              </td>
              <td class="border-t-0 px-6 text-center border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                <Confirm themeColor="410" confirmTitle="Delete" cancelTitle="Cancel" let:confirm="{confirmThis}">
                  <button on:click="{() => confirmThis(deleteCoupon, row.coupon_id, index)}">
                    <i class="fas fa-trash text-red-500"></i>
                  </button>

                  <span slot="title">
                    Are you sure, you want to delete <span class="font-semibold">{row.title}</span>?
                  </span>
                  <span slot="description" class="text-sm"> You won't be able to revert this. </span>
                </Confirm>
              </td>
            </tr>
          {/each}
        </tbody>
      </Datatable>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{couponsLoadingStatus}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
:global(.confirm-dialog) {
  padding: 30px 30px !important;
  position: fixed !important;
}
:global(.actions) {
  margin: 25px -30px -30px !important;
}
tr:nth-child(2n) {
  background-color: rgb(243, 243, 243);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-screen {
  height: 100vh;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-4 {
  margin: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.min-w-0 {
  min-width: 0px;
}
.opacity-25 {
  opacity: 0.25;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-6 {
  padding: 1.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.z-50 {
  z-index: 50;
}
.z-40 {
  z-index: 40;
}
.z-10 {
  z-index: 10;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>
