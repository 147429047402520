<script>
import Select from "svelte-select";

export let variationInfo;
let variationByList = [
  { label: "Size", value: "size" },
  { label: "Weight", value: "weight" },
  { label: "Volume", value: "volume" },
];

function handleVariationBy(e) {
  let selectedOption = e.detail.value;
  variationInfo.variation_by = selectedOption;
}
</script>

<div class="flex">
  <div class="px-4">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="variation-name"> Title </label>
      <input id="variation-name" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{variationInfo.title}" />
    </div>
  </div>
  <div class="px-4">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="price"> Price </label>
      <input id="price" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{variationInfo.price}" />
    </div>
  </div>
  <div class="px-4 w-3/12">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="variation-by"> Variation By </label>
      <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.1rem 0.1rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 0.5rem;" items="{variationByList}" value="{variationInfo.variation_by}" isMulti="{false}" placeholder="Please select one" noOptionsMessage="No options available" on:select="{handleVariationBy}" />
    </div>
  </div>
  <div class="px-4 w-3/12">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="quantity"> Quantity </label>
      <input id="quantity" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="enter quantity with units" bind:value="{variationInfo.quantity}" />
    </div>
  </div>

  <div class="px-2">
    <div class="relative w-full mb-3  flex flex-col">
      <label class="uppercase text-gray-700 text-xs font-bold mb-2" for="available"> Available? </label>

      <input type="checkbox" id="available" class="form-checkbox text-gray-800 ml-1  w-12 h-12 ease-linear transition-all duration-150" bind:checked="{variationInfo.is_available}" />
    </div>
  </div>
  <div class="px-2">
    <div class="relative w-full mb-3  flex flex-col">
      <label class="uppercase text-gray-700 text-xs font-bold mb-2" for="available"> Default? </label>

      <input type="checkbox" id="available" class="form-checkbox text-gray-800 ml-1  w-12 h-12 ease-linear transition-all duration-150" bind:checked="{variationInfo.is_default}" />
    </div>
  </div>
</div>

<style windi:inject>
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.h-12 {
  height: 3rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.w-full {
  width: 100%;
}
.w-3\/12 {
  width: 25%;
}
.w-12 {
  width: 3rem;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
</style>