<script>
import axios from "axios";
import { authToken } from "../../store/auth";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

let paymentCode = null;
let isVerifying = false;
let validationError = null;
let paymentDetails = null;

function getTokenDetailsAPI(code, googleRecaptchaToken) {
  let payload = {
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
    payment_data: {
      payment_otp: code,
    },
  };

  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .post(`${morrDashboard.env.API_URL}/admin/payments/direct/details`, payload, {
      headers: headers,
    })
    .then((res) => {
      console.log("PAY_WITH_CASH", res);
      validationError = null;
      isVerifying = false;
      paymentDetails = res.data.data;
      console.log("payment data", paymentDetails);
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function verifyPaymentCode(code) {
  isVerifying = true;

  if (!code || code.toString().length < 6) {
    validationError = "Invalid code, please enter a valid code.";
    isVerifying = false;
    showErrorMessage(validationError);
    return;
  }
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "PayDirect",
      })
      .then(function (token) {
        if (token) {
          getTokenDetailsAPI(code, token);
        }
      });
  });
}

function commitTransactionApi(action, code, googleRecaptchaToken) {
  let payload = {
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
    payment_data: {
      payment_otp: code,
    },
  };

  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .post(`${morrDashboard.env.API_URL}/admin/payments/direct/approve?action=${action}`, payload, {
      headers: headers,
    })
    .then((res) => {
      console.log("PAY_WITH_CASH_APPROVAL", res);
      let status = res.data.data.status === 0 ? "Payment successfully approved" : "Payment rejected";

      if (status === "Payment successfully approved") {
        showSuccessMessage(status);
      } else {
        showErrorMessage(status);
      }
      validationError = null;
      paymentDetails = null;
      paymentCode = null;
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
    });
}

function acceptOrRejectPayment(action) {
  if (!action) {
    return;
  }

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "PayDirect",
      })
      .then(function (token) {
        if (token) {
          commitTransactionApi(action, paymentCode, token);
        }
      });
  });
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-gray-200 border-0" style="background-color: rgb(252, 240, 235);">
  <div class="rounded-t mb-0 px-4 py-2">
    <div class="text-center flex justify-center items-center mt-4">
      <h6 class="text-black-800 text-2xl font-bold text-center">Enter Code To Approve Cash Payments</h6>
    </div>
  </div>
  <div class="flex-auto py-4 pt-0 m-4">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative w-full">
          <div class="hero flex flex-col px-0">
            <div class="search-box mx-auto my-auto w-full px-12">
              <div class="flex flex-row">
                <input class="h-16 text-grey-darker text-grey-darkest font-bold w-full border-b border-black py-1 px-2 outline-none text-lg text-gray-600" style="background-color:rgb(252, 240, 235)" type="number" placeholder="Enter Verification Code" minlength="6" maxlength="10" bind:value="{paymentCode}" on:change="{() => verifyPaymentCode(paymentCode)}" />
                {#if isVerifying}
                  <span class="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100">
                    <button class="bg-gredient-dark  hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded">
                      <svg class="loading h-5 w-5 mr-3 bg:dark" viewBox="0 0 24 24"></svg>
                    </button>
                  </span>
                {:else}
                  <span class="flex items-center rounded rounded-l-none border-0 px-3 font-bold text-grey-100" on:click|preventDefault="{() => verifyPaymentCode(paymentCode)}">
                    <button class="hover:bg-gredient-light text-lg text-dark font-bold py-3 px-6 rounded m-2" style="background-color:rgb(252, 240, 235)">
                      <i class="fas fa-search"></i>
                    </button>
                  </span>
                {/if}
              </div>

              {#if validationError}
                <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {validationError}
                </p>
              {/if}

              {#if paymentDetails}
                <div class="flex-auto mt-4">
                  <div class="flex flex-wrap mt-2">
                    <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                      <h5 class="text-gray-500 uppercase font-bold text-xs">
                        {paymentDetails?.currency || "RM"}
                      </h5>
                      <span class="font-semibold text-xl text-gray-800">
                        {paymentDetails?.amount || "0.0"}
                      </span>
                    </div>
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-24 h-12 mb-5 shadow-lg rounded-lg" style="background-color: rgb(252, 195, 170);">
                      <button on:click|preventDefault="{() => acceptOrRejectPayment('accept')}" class="accept border-none outline-none text-bold" style="background-color: rgb(252, 195, 170);">Approve</button>
                    </div>
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-24 h-12 mb-5 shadow-lg rounded-lg ml-2 -mr-1" style="background-color: rgb(252, 195, 170);">
                      <button on:click|preventDefault="{() => acceptOrRejectPayment('reject')}" class="reject border-none outine-none text-bold" style="background-color: rgb(252, 195, 170);">Reject</button>
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
.loading {
  display: inline-block;
  border: 3px solid rgba(132, 202, 243, 0.3);
  border-radius: 50%;
  border-top-color: rgb(56, 143, 201);
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.border-none {
  border-style: none;
}
.border-0 {
  border-width: 0px;
}
.border-b {
  border-bottom-width: 1px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.h-16 {
  height: 4rem;
}
.h-5 {
  height: 1.25rem;
}
.h-12 {
  height: 3rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.-mr-1 {
  margin-right: -0.25rem;
}
.max-w-full {
  max-width: 100%;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-3 {
  padding: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.pt-0 {
  padding-top: 0px;
}
.pr-4 {
  padding-right: 1rem;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-5 {
  width: 1.25rem;
}
.w-24 {
  width: 6rem;
}
</style>
